import { Card, Typography } from 'antd';
import React from 'react';
import { StyleSheet } from '../types';

type ViewBasicLayoutProps = {
  children: React.ReactNode;
  title?: string;
  header?: React.ReactNode | false;
};

const { Title } = Typography;

const ViewBasicLayout = ({
  title,
  header = false,
  children,
}: ViewBasicLayoutProps) => {
  return (
    <div style={styles.root}>
      <Card>
        {(title || header) && (
          <div style={styles.header}>
            {title && <Title level={3}>{title}</Title>}
            {header && header}
          </div>
        )}
        <div style={styles.main}>{children}</div>
      </Card>
    </div>
  );
};

const styles: StyleSheet = {
  root: {
    padding: '.5rem',
  },
  header: {
    marginBottom: '.5rem',
  },
  main: {
    // marginTop: '1rem',
  },
};

export default ViewBasicLayout;
