import { Result } from 'antd';
import React from 'react';

type ErrorResultProps = {
  title: string;
  subTitle?: string;
  extra?: React.ReactNode;
};

const ErrorResult = ({ title, subTitle = '', extra }: ErrorResultProps) => {
  return (
    <Result status="error" title={title} subTitle={subTitle} extra={extra} />
  );
};

export default ErrorResult;
