import { Modal, Form, Button, Input, DatePicker, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  PlusOutlined,
  EditOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import Loading from '../../common/Loading';
import { useLazyQuery, useMutation } from '@apollo/client';
import { ADD_ROUTE, GET_ROUTES } from '../../../GraphQL/queries/route';
import { graphqlErrorMessageAlert, showMessage } from '../../../utils/tools';
import {
  GET_ADDRESSES,
  GET_DEFAULT_ADDRESSES,
} from '../../../GraphQL/queries/address';
import { Address } from '../../../types';
import { useMount } from 'ahooks';
import moment from 'moment';

// type RouteInfoType = {
//   end_point: number;
//   route_name: string;
//   short_name: string;
//   start_point: number;
// };

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 14,
  },
};

const AddNewRoute = () => {
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);
  const [defaultAddressesId, setDefaultAddressesId] = useState({
    start: '-1',
    end: '-1',
  });
  const [manuallyInputRouteName, setManuallyInputRouteName] = useState(false);

  const [form] = Form.useForm();

  //Get default routes
  const [loadDefaultRouteData, { data: defaultAddressData }] = useLazyQuery(
    GET_DEFAULT_ADDRESSES
  );

  //Address Selector
  const [loadAddress, { data: addressList }] = useLazyQuery(GET_ADDRESSES, {
    variables: { isDeleted: false },
  });

  const [addRoute, { loading }] = useMutation(ADD_ROUTE, {
    onCompleted: () => handleAddCompleted(),
    onError: graphqlErrorMessageAlert,
  });

  // Get address list only when modal is open
  useEffect(() => {
    if (showModal) loadAddress();
  }, [showModal, loadAddress]);

  useMount(() => {
    //get local storage info
    const defaultAddressPointsString = localStorage.getItem(
      '@storage_defaultAddressPoints'
    );
    if (defaultAddressPointsString) {
      const defaultAddressPoints = JSON.parse(atob(defaultAddressPointsString));
      if (
        'startPoint' in defaultAddressPoints &&
        'endPoint' in defaultAddressPoints
      ) {
        setDefaultAddressesId({
          start: defaultAddressPoints.startPoint,
          end: defaultAddressPoints.endPoint,
        });
      }
    }
  });

  const handleCancel = () => {
    setShowModal(false);
    setManuallyInputRouteName(false);
    form.resetFields();
  };

  const handleAddCompleted = () => {
    handleCancel();
    showMessage('success', 'Successfully add route.');
  };

  const onFinish = (values: any) => {
    console.log('Received values of form: ', values);
    const date = values.date.format('YYYY-MM-DD');
    addRoute({
      variables: {
        name: values.name,
        short_name: values.short_name,
        shipping_date: date,
        start_point_id: parseInt(values.startPoint),
        end_point_id: parseInt(values.endPoint),
      },
      update: (proxy, addRoute) => {
        const data: any = proxy.readQuery({
          query: GET_ROUTES,
          variables: { date: date },
        });
        proxy.writeQuery({
          query: GET_ROUTES,
          variables: { date: date },
          data: {
            ...data,
            routes: [...data.routes, addRoute],
          },
        });
      },
    });
  };

  const handleAddRoute = () => {
    console.log(form.getFieldsValue(true));
    form.submit();
  };

  const handleRouteNameSelect = (value: string) => {
    const target =
      defaultAddressData &&
      defaultAddressData.defaultAddress &&
      defaultAddressData.defaultAddress.find(
        (routeInfo: any) => routeInfo.route_name === value
      );

    if (target && addressList.addresses) {
      const updateAddressFields: any = {
        startPoint: '',
        endPoint: '',
      };
      if (
        addressList.addresses.findIndex(
          (item: any) => item.id === target.start_point_id.toString()
        ) !== -1
      ) {
        updateAddressFields.startPoint = target.start_point_id.toString();
      }
      if (
        addressList.addresses.findIndex(
          (item: any) => item.id === target.end_point_id.toString()
        ) !== -1
      ) {
        updateAddressFields.endPoint = target.end_point_id.toString();
      }

      form.setFieldsValue(updateAddressFields);
    }

    if (target && target.short_name) {
      form.setFieldsValue({ short_name: target.short_name });
    }
  };

  return (
    <>
      <Button icon={<PlusOutlined />} onClick={() => setShowModal(true)}>
        {t('route.createRoute')}
      </Button>
      <Modal
        title={t('route.createRoute')}
        visible={showModal}
        onCancel={handleCancel}
        maskClosable={false}
        footer={
          <>
            <Form.Item>
              <Button disabled={loading} onClick={handleCancel}>
                {t('common.cancel')}
              </Button>
              <Button
                disabled={loading}
                type="primary"
                onClick={handleAddRoute}
              >
                {t('userList.table.actions.add')}
              </Button>
            </Form.Item>
          </>
        }
      >
        {loading ? (
          <Loading />
        ) : (
          <>
            <Form
              form={form}
              name="create-route"
              onFinish={onFinish}
              scrollToFirstError
              {...formItemLayout}
            >
              <Form.Item
                name="name"
                label={t('route.table.name')}
                rules={[
                  {
                    required: true,
                    message: 'Please input route name',
                  },
                  { min: 3 },
                ]}
                extra={
                  defaultAddressData &&
                  defaultAddressData.defaultAddress &&
                  defaultAddressData.defaultAddress.length > 0 ? (
                    <Button
                      type="link"
                      onClick={() => setManuallyInputRouteName((prev) => !prev)}
                      icon={
                        manuallyInputRouteName ? (
                          <UnorderedListOutlined />
                        ) : (
                          <EditOutlined />
                        )
                      }
                    >
                      {manuallyInputRouteName
                        ? t('route.fromList')
                        : t('route.manuallyInput')}
                    </Button>
                  ) : (
                    <></>
                  )
                }
              >
                {!manuallyInputRouteName ? (
                  <Select
                    showSearch
                    placeholder="Select route name"
                    optionFilterProp="children"
                    filterOption={(input, option: any) =>
                      option.children
                        .toLocaleLowerCase()
                        .includes(input.toLocaleLowerCase())
                    }
                    onChange={handleRouteNameSelect}
                    onFocus={() => loadDefaultRouteData()}
                  >
                    {defaultAddressData &&
                      defaultAddressData.defaultAddress &&
                      defaultAddressData.defaultAddress.length > 0 &&
                      defaultAddressData.defaultAddress.map(
                        (routeInfo: any, index: number) => {
                          return (
                            <Option key={index} value={routeInfo.route_name}>
                              {routeInfo.route_name}
                            </Option>
                          );
                        }
                      )}
                  </Select>
                ) : (
                  <Input placeholder="Input route name by typing" />
                )}
              </Form.Item>
              <Form.Item
                name="short_name"
                label={t('route.table.shortName')}
                rules={[
                  {
                    required: true,
                    message: 'Please input route short name',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="date"
                label={t('route.table.date')}
                rules={[{ required: true }]}
                initialValue={moment()}
              >
                <DatePicker />
              </Form.Item>
              <Form.Item
                name="startPoint"
                label={t('route.table.startPoint')}
                rules={[{ required: true }]}
                initialValue={
                  defaultAddressesId.start !== '-1'
                    ? defaultAddressesId.start
                    : null
                }
              >
                <Select
                  showSearch
                  placeholder="Select an address"
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    option.children
                      .toLocaleLowerCase()
                      .includes(input.toLocaleLowerCase())
                  }
                  onFocus={() => loadAddress()}
                >
                  {addressList &&
                    addressList.addresses.map((item: Address) => {
                      return (
                        <Option key={item.id} value={item.id}>
                          {item.label
                            ? `${item.label} - ${item.name}`
                            : item.name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
              <Form.Item
                name="endPoint"
                label={t('route.table.endPoint')}
                rules={[{ required: true }]}
                initialValue={
                  defaultAddressesId.end !== '-1'
                    ? defaultAddressesId.end
                    : null
                }
              >
                <Select
                  showSearch
                  placeholder="Select an address"
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    option.children
                      .toLocaleLowerCase()
                      .includes(input.toLocaleLowerCase())
                  }
                  onFocus={() => loadAddress()}
                >
                  {addressList &&
                    addressList.addresses.map((item: Address) => {
                      return (
                        <Option key={item.id} value={item.id}>
                          {item.label
                            ? `${item.label} - ${item.name}`
                            : item.name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Form>
          </>
        )}
      </Modal>
    </>
  );
};

export default AddNewRoute;
