import { Button, Form, Input, Modal, Space } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LockOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { graphqlErrorMessageAlert, showMessage } from '../../../utils/tools';
import { UPDATE_USER } from '../../../GraphQL/queries/user';
import { logout } from '../../../utils/createAuthProvider';

const formItemLayout = {
  labelCol: {
    span: 10,
  },
  wrapperCol: {
    span: 14,
  },
};

type PwdChangerModalProps = {
  userId: string;
};

const PwdChangerModal = ({ userId }: PwdChangerModalProps) => {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [updatePassword] = useMutation(UPDATE_USER, {
    onError: graphqlErrorMessageAlert,
    onCompleted: () => {
      showMessage(
        'success',
        'Update password successfully. Please login again'
      );
      setIsModalVisible(false);
      logout();
    },
  });

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onFinish = (values: any) => {
    updatePassword({
      variables: {
        id: userId,
        input: { password: values.newPassword },
      },
    });
  };

  return (
    <>
      <Button
        icon={<LockOutlined />}
        onClick={() => {
          setIsModalVisible(true);
        }}
      >
        {t('profile.changePwd')}
      </Button>
      <Modal
        title={t('profile.changePwd')}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose
        footer={<></>}
      >
        <Form {...formItemLayout} name="password-changer" onFinish={onFinish}>
          <Form.Item
            label={t('profile.pwdModal.newPwd')}
            name="newPassword"
            rules={[
              { required: true, message: 'Please input new password' },
              { min: 6 },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label={t('profile.pwdModal.confirmNewPwd')}
            name="confirmNewPassword"
            dependencies={['newPassword']}
            rules={[
              { required: true, message: 'Please input new password again' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('newPassword') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    'The two passwords that you entered do not match!'
                  );
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Space>
              <Button type="primary" htmlType="submit">
                {t('common.save')}
              </Button>
              <Button onClick={() => setIsModalVisible(false)}>
                {t('common.cancel')}
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default PwdChangerModal;
