import { Divider, Modal, Typography, Image, Space } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

type DriverRemarkModalProps = {
  showDriverModal: boolean;
  setShowDriverModal: React.Dispatch<React.SetStateAction<boolean>>;
  remark: string;
  images: string[];
};

const DriverRemarkModal = ({
  showDriverModal,
  setShowDriverModal,
  remark,
  images,
}: DriverRemarkModalProps) => {
  const { t } = useTranslation();

  const handleCancel = () => {
    setShowDriverModal(false);
  };

  return (
    <Modal
      title={t('order.driverRemark')}
      visible={showDriverModal}
      destroyOnClose
      footer={null}
      onCancel={handleCancel}
    >
      <Text>{remark}</Text>
      {images.length > 0 && (
        <>
          <Divider orientation="left">
            {t('order.driverRemarkLog.photos')}
          </Divider>
          <Space>
            {images.map((url, index) => {
              return <Image key={index} width={100} src={url} />;
            })}
          </Space>
        </>
      )}
    </Modal>
  );
};

export default DriverRemarkModal;
