import { AxiosRequestConfig } from 'axios';
import { useState, useEffect } from 'react';
import { createTokenProvider } from './createTokenProvider';
import { getData } from './service';

const createAuthProvider = () => {
  const tokenProvider = createTokenProvider();

  const login: typeof tokenProvider.setToken = (newTokens) => {
    tokenProvider.setToken(newTokens);
  };
  const logout = () => {
    tokenProvider.setToken(null);
    getData('/auth/logout');
  };

  const useAuth = () => {
    const [isLogged, setIsLogged] = useState(tokenProvider.isLoggedIn());
    useEffect(() => {
      const listener = (newIsLogged: boolean) => {
        setIsLogged(newIsLogged);
      };
      tokenProvider.subscribe(listener);
      return () => {
        tokenProvider.unsubscribe(listener);
      };
    }, []);
    return { isLogged } as { isLogged: typeof isLogged };
  };

  const getDataWithAuthToken = async (
    endpoint: string,
    options?: AxiosRequestConfig
  ) => {
    const token = await tokenProvider.getToken();

    options = options || {};

    options.headers = {
      ...options.headers,
      Authorization: `Bearer ${token}`,
    };

    return getData(endpoint, options);
  };

  const getToken = () => {
    return tokenProvider.getToken();
  };

  return {
    useAuth,
    getDataWithAuthToken,
    getToken,
    login,
    logout,
  };
};

export const {
  useAuth,
  getDataWithAuthToken,
  getToken,
  login,
  logout,
} = createAuthProvider();
