import { useMutation } from '@apollo/client';
import { Button, Popconfirm, Table } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  DELETE_ADDRESS,
  GET_ADDRESSES,
} from '../../../GraphQL/queries/address';
import { Address } from '../../../types';
import { graphqlErrorMessageAlert, showMessage } from '../../../utils/tools';
import AddressLabelEditor from './AddressLabelEditor';
import { QuestionCircleOutlined } from '@ant-design/icons';

const { Column } = Table;

type AddressListProps = {
  list: Address[];
};

const AddressList = ({ list }: AddressListProps) => {
  const { t } = useTranslation();
  const [deleteAddress] = useMutation(DELETE_ADDRESS, {
    onCompleted: () => handleDeleteCompleted(),
    onError: graphqlErrorMessageAlert,
  });

  const handleDeleteCompleted = () => {
    showMessage('success', t('addressBook.deleteSuccess'));
  };

  const handleDeleteConfirm = (id: string) => {
    deleteAddress({
      variables: { id },
      update: (cache, deleteAddress) => {
        const data: any = cache.readQuery({
          query: GET_ADDRESSES,
          variables: { isDeleted: false },
        });
        cache.writeQuery({
          query: GET_ADDRESSES,
          variables: { isDeleted: false },
          data: {
            ...data,
            addresses: data.addresses.filter(
              (address: Address) => address.id !== id
            ),
          },
        });
        const isDeletedData: any = cache.readQuery({
          query: GET_ADDRESSES,
          variables: { isDeleted: true },
        });
        isDeletedData &&
          cache.writeQuery({
            query: GET_ADDRESSES,
            variables: { isDeleted: true },
            data: {
              ...isDeletedData,
              addresses: [...isDeletedData.addresses, deleteAddress],
            },
          });
      },
    });
  };

  return (
    <Table
      dataSource={list}
      rowKey={(record) => record.id}
      sticky
      scroll={{ x: 1300 }}
      pagination={{ defaultPageSize: 10 }}
    >
      <Column title="ID" dataIndex="id" width={60} />
      <Column title={t('addressBook.table.addressName')} dataIndex="name" />
      <Column
        title={t('addressBook.table.label')}
        dataIndex="label"
        render={(label: string, record: Address) => (
          <AddressLabelEditor id={record.id} label={label} />
        )}
      />
      <Column title={t('addressBook.table.lng')} dataIndex="longitude" />
      <Column title={t('addressBook.table.lat')} dataIndex="latitude" />
      <Column
        fixed="right"
        width={100}
        title={t('addressBook.table.actions.title')}
        render={(record) => (
          <Popconfirm
            title={t('addressBook.deletePrompt')}
            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
            okText={t('common.yes')}
            cancelText={t('common.no')}
            onConfirm={() => handleDeleteConfirm(record.id)}
          >
            <Button size="small" danger>
              {t('common.delete')}
            </Button>
          </Popconfirm>
        )}
      />
    </Table>
  );
};

export default AddressList;
