import React, { useEffect, useState } from 'react';
import { Button, DatePicker, Form, Input, Modal, Select, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  GET_ROUTE,
  GET_ROUTES,
  UPDATE_ROUTE_INFO,
} from '../../../../GraphQL/queries/route';
import { graphqlErrorMessageAlert, showMessage } from '../../../../utils/tools';
import { Address, RootState, RouteState } from '../../../../types';
import { GET_ADDRESSES } from '../../../../GraphQL/queries/address';
import { useDispatch, useSelector } from 'react-redux';
import { RouteActionTypes } from '../../../../store/routeReducer';

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 14,
  },
};

type EditRouteDetailModelProps = {
  routeInfo: { [key: string]: any };
};

const EditRouteDetailModal = ({ routeInfo }: EditRouteDetailModelProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newShippingDate, setNewShippingDate] = useState<string>(
    routeInfo.shipping_date
  );

  const routePanes = useSelector<RootState, RouteState['routePanes']>(
    (state) => state.route.routePanes
  );

  const [updateRouteInfo] = useMutation(UPDATE_ROUTE_INFO, {
    onError: (error) => {
      graphqlErrorMessageAlert(error);
    },
    onCompleted: (data) => handleEditCompleted(data),
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: GET_ROUTE, variables: { id: routeInfo.id } },
      {
        query: GET_ROUTES,
        variables: { date: routeInfo.shipping_date, includeGeoJson: false },
      },
      {
        query: GET_ROUTES,
        variables: { date: newShippingDate, includeGeoJson: false },
      },
    ],
  });

  const handleOk = () => {
    form.submit();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleEditCompleted = (data: any) => {
    if (data && data.updateRoute) {
      const updatedRoutePanes = routePanes.map((pane) => {
        if (pane.key === `RouteView@${data.updateRoute.id}`) {
          pane.title = `${data.updateRoute.name} ${data.updateRoute.shipping_date}`;
        }
        return pane;
      });

      dispatch({
        type: RouteActionTypes.UPDATE_ROUTE_PANES,
        payload: updatedRoutePanes,
      });
    }

    setIsModalVisible(false);
    showMessage('success', 'Edit Route Successfully');
  };

  const onFinish = (values: any) => {
    console.log('Received values of form: ', values);
    setNewShippingDate(values.date.format('YYYY-MM-DD'));
    updateRouteInfo({
      variables: {
        id: routeInfo.id,
        input: {
          name: values.name,
          short_name: values.short_name,
          shipping_date: values.date.format('YYYY-MM-DD'),
          start_point_id: parseInt(values.startPoint),
          end_point_id: parseInt(values.endPoint),
        },
      },
    });
  };

  //Address Selector
  const [loadAddress, { data: addressList }] = useLazyQuery(GET_ADDRESSES, {
    variables: { isDeleted: false },
  });

  // Get address list only when modal is open
  useEffect(() => {
    if (isModalVisible) loadAddress();
  }, [isModalVisible, loadAddress]);

  return (
    <>
      <Space>
        <Button onClick={() => setIsModalVisible(true)}>
          {t('common.edit')}
        </Button>
      </Space>
      <Modal
        title={t('route.editRoute')}
        visible={isModalVisible}
        onOk={handleOk}
        okText={t('common.save')}
        onCancel={handleCancel}
        cancelText={t('common.cancel')}
        maskClosable={false}
      >
        <Form
          form={form}
          name={'edit-route'}
          onFinish={onFinish}
          scrollToFirstError
          {...formItemLayout}
        >
          <Form.Item
            name="name"
            label={t('route.table.name')}
            initialValue={routeInfo.name}
            rules={[
              {
                required: true,
                whitespace: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="short_name"
            label={t('route.table.shortName')}
            initialValue={routeInfo.short_name}
            rules={[
              {
                required: true,
                whitespace: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="date"
            label={t('route.table.date')}
            initialValue={moment(routeInfo.shipping_date)}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <DatePicker allowClear={false} />
          </Form.Item>
          <Form.Item
            name="startPoint"
            label={t('route.table.startPoint')}
            initialValue={routeInfo.startAddress.id}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Select an address"
              optionFilterProp="children"
              filterOption={(input, option: any) =>
                option.children
                  .toLocaleLowerCase()
                  .includes(input.toLocaleLowerCase())
              }
            >
              {addressList &&
                addressList.addresses.map((item: Address) => {
                  return (
                    <Option key={item.id} value={item.id}>
                      {item.label ? `${item.label} - ${item.name}` : item.name}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
          <Form.Item
            name="endPoint"
            label={t('route.table.endPoint')}
            initialValue={routeInfo.endAddress.id}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Select an address"
              optionFilterProp="children"
              filterOption={(input, option: any) =>
                option.children
                  .toLocaleLowerCase()
                  .includes(input.toLocaleLowerCase())
              }
            >
              {addressList &&
                addressList.addresses.map((item: Address) => {
                  return (
                    <Option key={item.id} value={item.id}>
                      {item.label ? `${item.label} - ${item.name}` : item.name}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default EditRouteDetailModal;
