import { PlusOutlined, ReloadOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Tooltip } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  generateKey,
  graphqlErrorMessageAlert,
  showMessage,
} from '../../../utils/tools';
import { useMutation } from '@apollo/client';
import { ADD_STORE, GET_STORES } from '../../../GraphQL/queries/store';
import Loading from '../../common/Loading';

const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 14,
  },
};

const AddNewStore = () => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [form] = Form.useForm();

  const [addStore, { loading }] = useMutation(ADD_STORE, {
    onCompleted: () => handleAddCompleted(),
    onError: graphqlErrorMessageAlert,
  });

  const handleCancel = () => {
    form.resetFields();
    setShowModal(false);
  };

  const handleAddCompleted = () => {
    handleCancel();
    showMessage('success', 'Successfully add store.');
  };

  const onFinish = (values: any) => {
    addStore({
      variables: {
        store_name: values.store_name,
        store_code: values.store_code,
        credential_code: values.credential_code,
        api_path: values.api_path,
      },
      update: (proxy, addStore) => {
        const data: any = proxy.readQuery({ query: GET_STORES });
        proxy.writeQuery({
          query: GET_STORES,
          data: {
            ...data,
            stores: [...data.stores, addStore],
          },
        });
      },
    });
  };

  return (
    <>
      <Button icon={<PlusOutlined />} onClick={() => setShowModal(true)}>
        {t('stores.addNewStore')}
      </Button>
      <Modal
        title={t('stores.addNewStore')}
        visible={showModal}
        onCancel={handleCancel}
        maskClosable={false}
        footer={
          <>
            <Form.Item>
              <Button disabled={loading} onClick={handleCancel}>
                {t('common.cancel')}
              </Button>
              <Button
                disabled={loading}
                type="primary"
                onClick={() => {
                  form.submit();
                }}
              >
                {t('stores.table.actions.add')}
              </Button>
            </Form.Item>
          </>
        }
      >
        {loading ? (
          <Loading />
        ) : (
          <Form
            form={form}
            name="add-store"
            onFinish={onFinish}
            scrollToFirstError
            {...formItemLayout}
          >
            <Form.Item
              name="store_name"
              label={t('stores.table.storeName')}
              rules={[
                {
                  required: true,
                  message: 'Please input store name!',
                  whitespace: true,
                },
                { min: 3 },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="store_code"
              label={t('stores.table.storeCode')}
              rules={[{ required: true }]}
            >
              <Input
                suffix={
                  <Tooltip title={t('stores.generateKey')}>
                    <Button
                      shape="circle"
                      size="small"
                      icon={<ReloadOutlined />}
                      onClick={() => {
                        form.setFieldsValue({
                          store_code: generateKey(6),
                        });
                      }}
                    />
                  </Tooltip>
                }
              />
            </Form.Item>
            <Form.Item
              name="credential_code"
              label={t('stores.table.credentialCode')}
              rules={[{ required: true, min: 10, max: 50 }]}
            >
              <Input
                suffix={
                  <Tooltip title={t('stores.generateKey')}>
                    <Button
                      shape="circle"
                      size="small"
                      icon={<ReloadOutlined />}
                      onClick={() => {
                        form.setFieldsValue({
                          credential_code: generateKey(32),
                        });
                      }}
                    />
                  </Tooltip>
                }
              />
            </Form.Item>
            <Form.Item
              name="api_path"
              label={t('stores.table.apiPath')}
              rules={[
                {
                  required: true,
                  message: 'Please input api path!',
                  whitespace: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Form>
        )}
      </Modal>
    </>
  );
};

export default AddNewStore;
