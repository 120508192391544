import { Badge, List, Typography } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  buildingTypeToString,
  orderStatusToString,
  orderTypeToString,
} from '../../../utils/tools';

const { Text } = Typography;

type LogListProps = {
  logType: 'order' | 'route';
  orderLogs?: Array<{
    created_at: string;
    id: string;
    order_id: string;
    remark: string;
    show_customer: 0 | 1;
  }>;
  routeLogs?: Array<{
    created_at: string;
    id: string;
    route_id: string;
    remark: string;
  }>;
};

const LogList = ({ orderLogs = [], routeLogs = [], logType }: LogListProps) => {
  const { t } = useTranslation();

  let logDataSource: Array<any> = [];

  switch (logType) {
    case 'order':
      logDataSource = orderLogs;
      break;
    case 'route':
      logDataSource = routeLogs;
      break;
  }

  return (
    <List
      bordered
      size="small"
      dataSource={logDataSource}
      pagination={{
        pageSize: 10,
        showTotal: (total: number) => (
          <Text>{t('order.log.logTotalCount', { amount: total })}</Text>
        ),
      }}
      renderItem={(item) => {
        const remarkObject = JSON.parse(item.remark);

        const renderText = remarkObject.fields.map(
          (item: any, index: number) => {
            let outputMessage = '';
            let oldValue = item.old;
            let newValue = item.new;

            switch (item.field) {
              //Order log fields
              case 'status':
                oldValue = orderStatusToString(item.old);
                newValue = orderStatusToString(item.new);
                break;
              case 'address':
                outputMessage = `${t(
                  'route.routeDetail.routeOrderTable.address'
                )}: `;
                break;
              case 'latitude':
                outputMessage = `${t(
                  'route.routeDetail.routeOrderTable.latitude'
                )}: `;
                break;
              case 'longitude':
                outputMessage = `${t(
                  'route.routeDetail.routeOrderTable.longitude'
                )}: `;
                break;
              case 'order_sn':
                outputMessage = `${t(
                  'route.routeDetail.routeOrderTable.orderNumber'
                )}: `;
                break;
              case 'receiver':
                outputMessage = `${t(
                  'route.routeDetail.routeOrderTable.receiver'
                )}: `;
                break;
              case 'type':
                outputMessage = `${t(
                  'route.routeDetail.routeOrderTable.type'
                )}: `;
                oldValue = orderTypeToString(item.old);
                newValue = orderTypeToString(item.new);
                break;
              case 'phone':
                outputMessage = `${t(
                  'route.routeDetail.routeOrderTable.phone'
                )}: `;
                break;
              case 'email':
                outputMessage = `${t(
                  'route.routeDetail.routeOrderTable.email'
                )}: `;
                break;
              case 'building_type':
                outputMessage = `${t(
                  'route.routeDetail.routeOrderTable.buildingType.title'
                )}: `;
                oldValue = buildingTypeToString(item.old);
                newValue = buildingTypeToString(item.new);
                break;
              case 'unit':
                outputMessage = `Unit: `;
                break;
              case 'buzz':
                outputMessage = `Buzz: `;
                break;
              case 'remark':
                outputMessage = `${t(
                  'route.routeDetail.routeOrderTable.remark'
                )}: `;
                break;
              case 'route':
                outputMessage = `${t('route.title')}: `;
                break;
              case 'sequence_label':
                outputMessage = `${t(
                  'route.routeDetail.routeOrderTable.label'
                )}: `;
                break;

              //Route log fields
              case 'name':
                outputMessage = `${t('route.table.name')}: `;
                break;
              case 'short_name':
                outputMessage = `${t('route.table.shortName')}: `;
                break;
              case 'shipping_date':
                outputMessage = `${t('route.table.date')}: `;
                break;
              case 'optimize_route':
              case 'optimize_route_0':
                return (
                  <Text key={index}>{t('order.log.optimization.ors')}</Text>
                );
              case 'optimize_route_1':
                return (
                  <Text key={index}>{t('order.log.optimization.geo')}</Text>
                );
              case 'reverse_route':
                return <Text key={index}>{t('order.log.reverse')}</Text>;
              case 'split_route':
                return (
                  <Text key={index}>{`${t('order.log.splitOrders')}: ${newValue}
                  `}</Text>
                );
              case 'change_route':
                return (
                  <Text key={index}>{`${t(
                    'order.log.changeRouteOrders'
                  )}: ${newValue}
                  `}</Text>
                );
              case 'start_point':
                outputMessage = `${t('route.table.startPoint')}: `;
                break;
              case 'end_point':
                outputMessage = `${t('route.table.endPoint')}: `;
                break;
              case 'driver':
                outputMessage = `${t('driver.title')}: `;
                break;
              case 'delete_order':
              case 'delete_orders':
                return (
                  <Text key={index}>{`${t(
                    'order.log.deleteOrders'
                  )}: ${newValue}
                  `}</Text>
                );
              case 'add_order':
              case 'add_orders':
                return (
                  <Text key={index}>{`${t('order.log.addOrders')}: ${newValue}
                  `}</Text>
                );
              case 'start_route':
                return <Text key={index}>{t('order.log.start_route')}</Text>;

              default:
                outputMessage = `${item.field}: `;
                break;
            }

            return (
              <Text key={index}>{`${
                outputMessage +
                t('order.log.fromAtoB', { a: oldValue, b: newValue })
              }`}</Text>
            );
          }
        );

        const time = dayjs(item.created_at).format('YYYY/MM/DD HH:mm');

        return (
          <List.Item>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              {item.show_customer === 1 ? (
                <Badge status="success" text={t('order.log.statusUpdate')} />
              ) : (
                <Badge status="warning" text={t('order.log.infoUpdate')} />
              )}
              {renderText}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                {item.user && <Text type="secondary">By {item.user.name}</Text>}
                {item.driver && (
                  <Text type="secondary">By {item.driver.driver_name}</Text>
                )}
                <Text type="secondary" style={{ alignSelf: 'flex-end' }}>
                  {time}
                </Text>
              </div>
            </div>
          </List.Item>
        );
      }}
    />
  );
};

export default LogList;
