import { ReducerActionType, UserState } from '../types';

const initState: UserState = {
  userInfo: {
    id: -1,
    username: '',
    name: '',
    role: '',
  },
};

export enum UserActionTypes {
  UPDATE_USER_INFO = 'UPDATE_USER_INFO',
}

const userReducer = (state = initState, action: ReducerActionType) => {
  switch (action.type) {
    case UserActionTypes.UPDATE_USER_INFO:
      return {
        ...state,
        userInfo: action.payload,
      };

    default:
      return state;
  }
};

export default userReducer;
