import { Button } from 'antd';
import React from 'react';
import { CSVLink } from 'react-csv';
import { ExportOutlined } from '@ant-design/icons';
import { Order } from '../../../../types';
import { useTranslation } from 'react-i18next';
import { parse } from 'json2csv';

type ExportCsvBtnProps = {
  orders: Order[];
  filename: string;
};

const ExportCsvBtn = ({ orders = [], filename }: ExportCsvBtnProps) => {
  const { t } = useTranslation();

  const sortedOrders = [...orders];

  //Sort orders based on sequence index
  sortedOrders.sort((a, b) => {
    return a.sequence_index - b.sequence_index;
  });

  //Create output object as required format
  const outputData = sortedOrders.map((item) => {
    return {
      'Order ID': `#${item.order_sn}`,
      Receiver: item.receiver ? formatData(item.receiver) : item.receiver,
      Phone: item.phone ? formatData(item.phone) : item.phone,
      Address: item.address ? formatData(item.address) : item.address,
      Remark: item.remark ? formatData(item.remark) : item.remark,
    };
  });

  //convert object to csv format
  const csvData = parse(outputData).replaceAll('"', '');

  return (
    <CSVLink data={csvData} target="blank" filename={filename}>
      <Button icon={<ExportOutlined />}>
        {t('route.routeDetail.routeMapSection.exportCSV')}
      </Button>
    </CSVLink>
  );
};

const formatData = (string: string) => {
  return string
    .replaceAll(',', ' ')
    .replaceAll('  ', ' ')
    .replaceAll('\n', ' ')
    .replaceAll('\r', ' ');
};

export default ExportCsvBtn;
