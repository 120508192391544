import React, { useState } from 'react';
import { Button, Input, Modal, Popconfirm, Space, Table } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  GET_ADDRESSES,
  RESTORE_ADDRESS,
} from '../../../GraphQL/queries/address';
import { graphqlErrorMessageAlert, showMessage } from '../../../utils/tools';
import Loading from '../../common/Loading';
import { useTranslation } from 'react-i18next';
import { Address } from '../../../types';
import { SearchOutlined, QuestionCircleOutlined } from '@ant-design/icons';

const { Column } = Table;

const AddressBin = () => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [getAddresses, { loading, data }] = useLazyQuery(GET_ADDRESSES, {
    variables: { isDeleted: true },
    onError: graphqlErrorMessageAlert,
  });

  const [restoreAddress] = useMutation(RESTORE_ADDRESS, {
    onCompleted: () => handleRestoreCompleted(),
    onError: graphqlErrorMessageAlert,
  });

  const handleRestoreCompleted = () => {
    showMessage('success', t('addressBook.restoreSuccess'));
  };

  const handleRestoreConfirm = (id: string) => {
    restoreAddress({
      variables: { id },
      update: (cache, restoreAddress) => {
        const isDeletedData: any = cache.readQuery({
          query: GET_ADDRESSES,
          variables: { isDeleted: true },
        });
        cache.writeQuery({
          query: GET_ADDRESSES,
          variables: { isDeleted: true },
          data: {
            ...isDeletedData,
            addresses: isDeletedData.addresses.filter(
              (address: Address) => address.id !== id
            ),
          },
        });
        const data: any = cache.readQuery({
          query: GET_ADDRESSES,
          variables: { isDeleted: false },
        });
        cache.writeQuery({
          query: GET_ADDRESSES,
          variables: { isDeleted: false },
          data: {
            ...data,
            addresses: [...data.addresses, restoreAddress],
          },
        });
      },
    });
  };

  const onOpen = () => {
    getAddresses({ variables: { isDeleted: true } });
    setVisible(true);
  };

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  return (
    <>
      <Button icon={<DeleteOutlined />} onClick={onOpen}>
        {t('addressBook.addressBin')}
      </Button>
      <Modal
        title={t('addressBook.addressBin')}
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
        bodyStyle={{ padding: '24px 24px 0 24px' }}
      >
        {loading ? (
          <Loading />
        ) : (
          <Space direction="vertical">
            <Input
              onChange={onSearch}
              prefix={<SearchOutlined />}
              value={searchText}
              placeholder={t('addressBook.searchPlaceholder')}
            />
            <Table<Address>
              dataSource={
                data && data.addresses
                  ? data.addresses.filter(
                      (address: Address) =>
                        address.name
                          .toLowerCase()
                          .includes(searchText.toLowerCase()) ||
                        (address.label !== null &&
                          address.label
                            .toLowerCase()
                            .includes(searchText.toLowerCase()))
                    )
                  : []
              }
              rowKey={(record) => record.id}
              loading={loading}
              sticky
              pagination={{ defaultPageSize: 10 }}
            >
              <Column title="ID" dataIndex="id" width={70} />
              <Column
                title={t('addressBook.table.addressName')}
                dataIndex="name"
                width={200}
              />
              <Column title={t('addressBook.table.label')} dataIndex="label" />
              <Column
                render={(record: Address) => (
                  <Popconfirm
                    title={t('addressBook.restorePrompt')}
                    icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                    okText={t('common.yes')}
                    cancelText={t('common.no')}
                    onConfirm={() => handleRestoreConfirm(record.id)}
                  >
                    <Button size="small">{t('addressBook.restore')}</Button>
                  </Popconfirm>
                )}
              />
            </Table>
          </Space>
        )}
      </Modal>
    </>
  );
};

export default AddressBin;
