import { gql } from '@apollo/client';

export const SIMPLE_DRIVER_LIST = gql`
  query DriverList {
    drivers {
      driver_id
      driver_name
    }
  }
`;

export const GET_DRIVER_STATISTICS = gql`
  query DriverStatistics($input: driverDataInput) {
    driverData(input: $input) {
      driver {
        driver_id
        driver_name
      }
      totalOrders
      validOrderCount
      totalOrderStatus
      totalTips
      totalDistance
    }
  }
`;

export const DRIVER_STATISTICS_DETAIL = gql`
  query DriverDataDetail($input: driverDataDetailInput) {
    driverDataDetail(input: $input) {
      driver {
        driver_id
        driver_name
      }
      orderDetailAnalysis
    }
  }
`;

export const GET_DRIVER_FEE_STATISTICS = gql`
  query DriverFeeStatistics($input: driverFeeDataInput) {
    driverFeeData(input: $input) {
      driver {
        driver_id
        driver_name
      }
      summary {
        validOrders
        companyVehicleOrders
        ownVehicleOrders
        companyVehicleFee
        ownVehicleFee
        vehicleOrders
        vehicleFee
        fuelSubsidy
        tips
        subtotal
      }
      detail {
        storeId
        storeName
        validOrders
        companyVehicleOrders
        ownVehicleOrders
        companyVehicleFee
        ownVehicleFee
        fuelSubsidy
        tips
        subtotal
      }
    }
  }
`;
