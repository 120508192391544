import { Button, Form, Input, Select, Collapse } from 'antd';
import React, { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import Modal from 'antd/lib/modal/Modal';
import { useTranslation } from 'react-i18next';
import AddressAutoComplete from '../routes/AddressAutoComplete';
import { Order } from '../../../types';
import { useMutation, useQuery } from '@apollo/client';
import { ADD_ORDER } from '../../../GraphQL/queries/order';
import { graphqlErrorMessageAlert, showMessage } from '../../../utils/tools';
import { GET_STORES } from '../../../GraphQL/queries/store';

const { Panel } = Collapse;

type AddNewOrderProps = {
  route_id: string;
  setLoadingMap: Function;
  refetch: Function;
};

const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 14,
  },
};

/**
 * Manually input new order
 */
const AddNewOrder = ({
  route_id,
  setLoadingMap,
  refetch,
}: AddNewOrderProps) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [form] = Form.useForm();

  const [createOrder] = useMutation(ADD_ORDER, {
    onCompleted: () => handleAddCompleted(),
    onError: graphqlErrorMessageAlert,
  });

  const { data: storesData } = useQuery(GET_STORES);

  const handleAddCompleted = () => {
    handleCancel();
    showMessage('success', 'Successfully add order.');
    refetch();
  };

  const handleCancel = () => {
    setShowModal(false);
    form.resetFields();
  };

  const onFinish = async (values: any) => {
    const { addressInfo, store_id, ...restFields } = values;
    const newOrder: Order = {
      route_id: route_id,
      address: addressInfo.name,
      latitude: addressInfo.lat,
      longitude: addressInfo.lng,
      ...restFields,
    };

    try {
      setLoadingMap(true);
      await createOrder({
        variables: newOrder,
      });
      setLoadingMap(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Button icon={<PlusOutlined />} onClick={() => setShowModal(true)}>
        {t('route.routeDetail.routeMapSection.addOrder')}
      </Button>
      <Modal
        title={t('route.routeDetail.routeMapSection.addOrder')}
        visible={showModal}
        onCancel={handleCancel}
        maskClosable={false}
        footer={
          <>
            <Form.Item>
              <Button onClick={handleCancel}>{t('common.cancel')}</Button>
              <Button
                type="primary"
                onClick={() => {
                  form.submit();
                }}
              >
                {t('common.save')}
              </Button>
            </Form.Item>
          </>
        }
      >
        <Form
          form={form}
          name={'create-order'}
          onFinish={onFinish}
          scrollToFirstError
          {...formItemLayout}
        >
          <Form.Item
            name="addressInfo"
            label={t('route.routeDetail.routeOrderTable.address')}
            rules={[{ required: true }]}
          >
            <AddressAutoComplete />
          </Form.Item>

          <Form.Item
            name="receiver"
            label={t('route.routeDetail.routeOrderTable.receiver')}
            rules={[
              {
                required: true,
                whitespace: true,
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="phone"
            label={t('route.routeDetail.routeOrderTable.phone')}
            rules={[
              {
                required: true,
                whitespace: false,
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="email"
            label={t('route.routeDetail.routeOrderTable.email')}
            rules={[
              {
                type: 'email',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="order_sn"
            label={t('route.routeDetail.routeOrderTable.orderId')}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="type"
            label={t('route.routeDetail.routeOrderTable.type')}
            initialValue={0}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select>
              <Select.Option value={0}>
                {t('route.routeDetail.routeOrderTable.orderTypes.delivery')}
              </Select.Option>
              <Select.Option value={1}>
                {t('route.routeDetail.routeOrderTable.orderTypes.pickup')}
              </Select.Option>
              <Select.Option value={2}>
                {t('route.routeDetail.routeOrderTable.orderTypes.exchange')}
              </Select.Option>
              <Select.Option value={3}>
                {t('route.routeDetail.routeOrderTable.orderTypes.replenish')}
              </Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="store_id"
            label={t('route.routeDetail.routeOrderTable.store')}
            rules={[{ required: true }]}
          >
            <Select
              showSearch
              placeholder="Select a store"
              optionFilterProp="children"
              filterOption={(input, option: any) =>
                option.children
                  .toLocaleLowerCase()
                  .includes(input.toLocaleLowerCase())
              }
            >
              {storesData &&
                storesData.stores &&
                storesData.stores.length > 0 &&
                storesData.stores.map((store: any) => (
                  <Select.Option key={store.id} value={Number(store.id)}>
                    {store.store_name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>

          <Collapse defaultActiveKey={[]} ghost>
            <Panel header={t('order.otherFields')} key="1">
              <Form.Item
                name="building_type"
                label={t(
                  'route.routeDetail.routeOrderTable.buildingType.title'
                )}
                initialValue={0}
              >
                <Select>
                  <Select.Option value={0}>
                    {t('route.routeDetail.routeOrderTable.buildingType.house')}
                  </Select.Option>
                  <Select.Option value={1}>
                    {t(
                      'route.routeDetail.routeOrderTable.buildingType.townhouse'
                    )}
                  </Select.Option>
                  <Select.Option value={2}>
                    {t(
                      'route.routeDetail.routeOrderTable.buildingType.apartment'
                    )}
                  </Select.Option>
                  <Select.Option value={3}>
                    {t(
                      'route.routeDetail.routeOrderTable.buildingType.basement'
                    )}
                  </Select.Option>
                  <Select.Option value={4}>
                    {t('route.routeDetail.routeOrderTable.buildingType.store')}
                  </Select.Option>
                  <Select.Option value={5}>
                    {t('route.routeDetail.routeOrderTable.buildingType.other')}
                  </Select.Option>
                </Select>
              </Form.Item>
              <Form.Item name="unit" label={'Unit'}>
                <Input />
              </Form.Item>
              <Form.Item name="buzz" label={'Buzz'}>
                <Input />
              </Form.Item>
              <Form.Item
                name="remark"
                label={t('route.routeDetail.routeOrderTable.remark')}
              >
                <Input.TextArea />
              </Form.Item>
            </Panel>
          </Collapse>
        </Form>
      </Modal>
    </>
  );
};

export default AddNewOrder;
