import { useMutation } from '@apollo/client';
import { Button, Modal } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { DELETE_ROUTE, GET_ROUTES } from '../../../../../GraphQL/queries/route';
import { RouteActionTypes } from '../../../../../store/routeReducer';
import { RootState, RouteState } from '../../../../../types';
import { graphqlErrorMessageAlert } from '../../../../../utils/tools';

type DeleteRouteBtnProps = {
  routeId: string;
  shippingDate: string;
};

const DeleteRouteBtn = ({ routeId, shippingDate }: DeleteRouteBtnProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const routePanes = useSelector<RootState, RouteState['routePanes']>(
    (state) => state.route.routePanes
  );

  const [deleteRoute] = useMutation(DELETE_ROUTE, {
    variables: { id: routeId },
    onCompleted: () => {
      const filteredRoutePanes = routePanes.filter((pane) => {
        return pane.key !== `RouteView@${routeId}`;
      });

      dispatch({
        type: RouteActionTypes.UPDATE_ACTIVE_PANE_KEY,
        payload: 'RouteListView',
      });
      dispatch({
        type: RouteActionTypes.UPDATE_ROUTE_PANES,
        payload: filteredRoutePanes,
      });
    },
    onError: (error) => {
      graphqlErrorMessageAlert(error);
    },
    refetchQueries: [
      {
        query: GET_ROUTES,
        variables: { date: shippingDate, includeGeoJson: false },
      },
    ],
  });

  const handleDeleteRoute = () => {
    Modal.confirm({
      title: t('route.routeDetail.header.otherInfo.deletePrompt'),
      onOk: () => {
        deleteRoute();
      },
      okText: t('common.yes'),
      cancelText: t('common.no'),
    });
  };

  return (
    <Button block danger onClick={handleDeleteRoute}>
      {t('route.routeDetail.header.otherInfo.delete')}
    </Button>
  );
};

export default DeleteRouteBtn;
