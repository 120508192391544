import ReactMapboxGl from 'react-mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

/**
 * REMINDER: There is a bug in mapbox-gl@2.0, which cannot show the map in production. So currently, we downgrade the package to 1.13 and the bug is gone.
 * Waiting for new update until the bug is fixed by the mapbox team.
 */

export const Map = ReactMapboxGl({
  accessToken:
    'pk.eyJ1Ijoic3Vubnl4dWUiLCJhIjoiY2trbXkwd3JhMHpjeTJvb2NheWVpcjJvYyJ9.gfFGcaXJmkbiNRn7xRU6Vg',
});
