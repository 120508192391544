import { gql } from '@apollo/client';

export const DRAW_ROUTE_MAP = gql`
  query DrawMap($date: String!) {
    routes(date: $date) {
      id
      name
      geoJson
      start_at
      driver {
        driver_id
        driver_name
        latitude
        longitude
        status
      }
    }
  }
`;

export const DAILY_REPORT = gql`
  query DailyReport($date: String!) {
    todayData(shipping_date: $date) {
      totalRoutes
      totalOrders
      totalOrderStatus
    }
  }
`;

export const PERIOD_REPORT = gql`
  query periodReport($start: String!, $end: String!) {
    periodData(start_date: $start, end_date: $end) {
      totalRoutes
      totalOrders
      totalOrderStatus
    }
  }
`;
