import React from 'react';
import { ResponsiveLine } from '@nivo/line';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from '../../../../types';

const { Title } = Typography;

type OrderAmountChartProps = {
  data: any[];
};

const OrderAmountChart = ({ data }: OrderAmountChartProps) => {
  const { t } = useTranslation();

  const buildChartData = data.map((item) => {
    return {
      x: item.date,
      y: item.amount,
    };
  });

  return (
    <div style={styles.orderStatusBarChartContainer}>
      <Title level={5} style={{ textAlign: 'center', paddingTop: 20 }}>
        {t('home.periodReport.totalOrderStatistics')}
      </Title>
      <ResponsiveLine
        data={[{ id: 'orders', data: buildChartData }]}
        margin={{ top: 10, right: 50, bottom: 50, left: 60 }}
        colors="#42A635"
        xScale={{ type: 'point' }}
        yScale={{
          type: 'linear',
          min: 'auto',
          max: 'auto',
          stacked: true,
          reverse: false,
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          orient: 'bottom',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: t('home.periodReport.date'),
          legendOffset: 36,
          legendPosition: 'middle',
        }}
        axisLeft={{
          orient: 'left',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: t('home.periodReport.orderAmount'),
          legendOffset: -40,
          legendPosition: 'middle',
        }}
        pointSize={10}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabelYOffset={-12}
        useMesh={true}
        enablePointLabel={true}
      />
    </div>
  );
};

const styles: StyleSheet = {
  orderStatusBarChartContainer: {
    height: 400,
    marginBottom: 20,
  },
};

export default OrderAmountChart;
