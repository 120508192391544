import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, DatePicker, List, Modal, Space, Typography } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UPDATE_ORDERS } from '../../../../../GraphQL/queries/order';
import {
  GET_ROUTE,
  GET_ROUTES,
  ROUTE_COMPARE,
} from '../../../../../GraphQL/queries/route';
import {
  graphqlErrorHandler,
  graphqlErrorMessageAlert,
  showMessage,
} from '../../../../../utils/tools';
import ErrorResult from '../../../../common/ErrorResult';
import Loading from '../../../../common/Loading';

const { Text } = Typography;

type RouteSelectorModalProps = {
  ordersId: string[];
  currentRouteId: string;
};

const RouteSelectorModal = ({
  ordersId = [],
  currentRouteId,
}: RouteSelectorModalProps) => {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [shippingDate, setShippingDate] = useState(
    dayjs().format('YYYY-MM-DD')
  );

  const [loadRoutes, { data, loading, error }] = useLazyQuery(GET_ROUTES, {
    onError: graphqlErrorMessageAlert,
    variables: {
      date: shippingDate,
    },
  });

  const [updateOrders] = useMutation(UPDATE_ORDERS, {
    onError: graphqlErrorMessageAlert,
    onCompleted: () => {
      showMessage('success', 'Switch route successfully.');
      handleCancel();
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: GET_ROUTE, variables: { id: currentRouteId } },
      { query: ROUTE_COMPARE, variables: { date: shippingDate } },
    ],
  });

  const changeDate = (_: any, dateString: string) => {
    setShippingDate(dateString);
  };

  const showModal = () => {
    setIsModalVisible(true);
    loadRoutes();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const renderRouteList = () => {
    if (loading) return <Loading />;

    if (error)
      return (
        <ErrorResult
          title={graphqlErrorHandler(error)}
          extra={
            <Button
              type="primary"
              onClick={() => {
                loadRoutes();
              }}
            >
              {t('common.retry')}
            </Button>
          }
        />
      );

    if (data && data.routes) {
      const switchRoute = (routeId: string) => {
        const updateOrdersInputs = ordersId.map((orderId) => {
          return {
            id: orderId,
            input: {
              route_id: parseInt(routeId),
            },
          };
        });

        updateOrders({
          variables: {
            orders: updateOrdersInputs,
          },
          refetchQueries: [{ query: GET_ROUTE, variables: { id: routeId } }],
        });
      };

      return (
        <List
          bordered
          dataSource={data.routes}
          renderItem={(item: any) => (
            <List.Item>
              <Text>{item.name}</Text>
              {currentRouteId === item.id ? (
                <Text type="success">
                  {t('order.actions.routeSelector.currentRoute')}
                </Text>
              ) : (
                <Button onClick={() => switchRoute(item.id)}>
                  {t('order.actions.routeSelector.select')}
                </Button>
              )}
            </List.Item>
          )}
        />
      );
    }

    return <></>;
  };

  return (
    <>
      <Button onClick={showModal}>
        {t('order.actions.routeSelector.buttonText')}
      </Button>
      <Modal
        title={t('order.actions.routeSelector.title')}
        footer={null}
        visible={isModalVisible}
        onCancel={handleCancel}
      >
        <Space
          style={{
            marginBottom: '.5rem',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Text>{t('route.table.date')}</Text>
          <DatePicker
            onChange={changeDate}
            defaultValue={moment(shippingDate)}
            allowClear={false}
          />
        </Space>
        {renderRouteList()}
      </Modal>
    </>
  );
};

export default RouteSelectorModal;
