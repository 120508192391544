import React, { useEffect, useState } from 'react';
import { Divider, DatePicker, Typography } from 'antd';
import { StyleSheet } from '../../../types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useLazyQuery } from '@apollo/client';
import { PERIOD_REPORT } from '../../../GraphQL/queries/home';
import {
  graphqlErrorMessageAlert,
  orderStatusToString,
} from '../../../utils/tools';

import Loading from '../../common/Loading';
import OrderStatusChart from './charts/OrderStatusChart';
import OrderAmountChart from './charts/OrderAmountChart';
import RouteAmountChart from './charts/RouteAmountChart';

type ChartDataType = {
  [key: string]: number[];
};

const { RangePicker } = DatePicker;
const { Title } = Typography;

const PeriodReport = () => {
  const { t } = useTranslation();

  //Order status chart
  const [statusData, setStatusData] = useState<ChartDataType[]>([]);
  //Order amount chart
  const [amountData, setAmountData] = useState<ChartDataType[]>([]);
  //Route amount chart
  const [routeAmountData, setRouteAmountData] = useState<ChartDataType[]>([]);

  const [getReport, { loading }] = useLazyQuery(PERIOD_REPORT, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      //CHART 1: Order Status chart
      const {
        totalOrderStatus = {},
      }: { totalOrderStatus: ChartDataType } = data.periodData;

      if (Object.entries(totalOrderStatus).length > 0) {
        const results: any[] = [];
        for (const [key, value] of Object.entries(totalOrderStatus)) {
          results.push({
            date: key.replace(`${moment().year()}-`, ''),
            [`${orderStatusToString(0)}`]: value[0],
            [`${orderStatusToString(1)}`]: value[1],
            [`${orderStatusToString(2)}`]: value[2],
            [`${orderStatusToString(3)}`]: value[3],
            [`${orderStatusToString(4)}`]: value[4],
          });
        }
        setStatusData(results);
      }

      //CHART 2: Order Total Chart
      const {
        totalOrders = {},
      }: { totalOrders: ChartDataType } = data.periodData;

      if (Object.entries(totalOrders).length > 0) {
        const results: any = [];
        for (const [key, value] of Object.entries(totalOrders)) {
          results.push({
            date: key.replace(`${moment().year()}-`, ''),
            amount: value,
          });
        }
        setAmountData(results);
      }

      //CHART 3: Route Amount Chart
      const {
        totalRoutes = {},
      }: { totalRoutes: ChartDataType } = data.periodData;

      if (Object.entries(totalRoutes).length > 0) {
        const results: any = [];
        for (const [key, value] of Object.entries(totalRoutes)) {
          results.push({
            date: key.replace(`${moment().year()}-`, ''),
            amount: value,
          });
        }
        setRouteAmountData(results);
      }
    },
    onError: graphqlErrorMessageAlert,
  });

  useEffect(() => {
    getReport({
      variables: {
        start: moment().startOf('isoWeek').format('YYYY-MM-DD'),
        end: moment().endOf('isoWeek').format('YYYY-MM-DD'),
      },
    });
  }, [getReport]);

  const onChange = (_: any, dateStrings: [string, string]) => {
    getReport({
      variables: {
        start: dateStrings[0],
        end: dateStrings[1],
      },
    });
  };

  return (
    <div style={styles.root}>
      <Divider orientation="left">
        <Title level={4}>{t('home.periodReport.title')}</Title>
      </Divider>
      <div style={styles.periodSection}></div>
      <RangePicker
        ranges={{
          [`${t('datePicker.period.week')}`]: [
            moment().startOf('isoWeek'),
            moment().endOf('isoWeek'),
          ],
          [`${t('datePicker.period.firstHalfMonth')}`]: [
            moment().startOf('month'),
            moment().startOf('month').add(14, 'd'),
          ],
          [`${t('datePicker.period.secondHalfMonth')}`]: [
            moment().startOf('month').add(15, 'd'),
            moment().endOf('month'),
          ],
          [`${t('datePicker.period.month')}`]: [
            moment().startOf('month'),
            moment().endOf('month'),
          ],
        }}
        onChange={onChange}
        defaultValue={[moment().startOf('isoWeek'), moment().endOf('isoWeek')]}
      />
      {loading && <Loading />}
      {statusData.length > 0 && <OrderStatusChart data={statusData} />}
      {amountData.length > 0 && <OrderAmountChart data={amountData} />}
      {routeAmountData.length > 0 && (
        <RouteAmountChart data={routeAmountData} />
      )}
    </div>
  );
};

const styles: StyleSheet = {
  root: {
    paddingBottom: 60,
  },
};

export default PeriodReport;
