import { useQuery } from '@apollo/client';
import { Space, Table, Button, Typography, Tooltip } from 'antd';
import Column from 'antd/lib/table/Column';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GET_ROUTES } from '../../../GraphQL/queries/route';
import {
  graphqlErrorHandler,
  graphqlErrorMessageAlert,
} from '../../../utils/tools';
import EmptyResult from '../../common/EmptyResult';
import ErrorResult from '../../common/ErrorResult';
import Loading from '../../common/Loading';
import AddNewRoute from './AddNewRoute';
import RouteSearchFilter from './RouteSearchFilter';
import { SyncOutlined } from '@ant-design/icons';
import VehicleType from '../vehicles/VehicleType';

const { Title, Text } = Typography;

type RouteListProps = {
  addTab: Function;
};

const RouteList = ({ addTab }: RouteListProps) => {
  const { t } = useTranslation();

  const [searchText, setSearchText] = useState('');
  const [shippingDate, setShippingDate] = useState(
    dayjs().format('YYYY-MM-DD')
  );
  const [filterRoutes, setFilterRoutes] = useState<any[]>([]);

  const { data, loading, error, refetch } = useQuery(GET_ROUTES, {
    onError: graphqlErrorMessageAlert,
    variables: {
      date: shippingDate,
      includeGeoJson: false,
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (data && data.routes && data.routes.length > 0) {
      setFilterRoutes(data.routes);
      setSearchText('');
    }
  }, [data]);

  useEffect(() => {
    if (data && data.routes) {
      const newFilterRoutes = data.routes.filter((route: any) =>
        route.name.toLowerCase().includes(searchText.toLowerCase())
      );
      setFilterRoutes(newFilterRoutes);
    }
  }, [searchText, data]);

  if (loading) return <Loading />;

  if (error)
    return (
      <ErrorResult
        title={graphqlErrorHandler(error)}
        extra={
          <Button type="primary" onClick={() => {}}>
            {t('common.retry')}
          </Button>
        }
      />
    );

  const createRouteDetailTab = (title: string, routeId: string) => {
    addTab(title, routeId);
  };

  return (
    <>
      <Title level={3}>{t('route.title')}</Title>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <AddNewRoute />
          <Tooltip title={t('common.refresh')}>
            <Button
              type="primary"
              shape="circle"
              icon={<SyncOutlined />}
              onClick={() => refetch()}
              style={{ marginLeft: 10 }}
            />
          </Tooltip>
        </div>

        <RouteSearchFilter
          searchText={searchText}
          setSearchText={setSearchText}
          shippingDate={shippingDate}
          setShippingDate={setShippingDate}
        />
      </div>
      {data && data.routes && data.routes.length > 0 ? (
        <Table
          dataSource={filterRoutes}
          rowKey={(record) => record.id}
          sticky
          pagination={{ pageSize: 50, showSizeChanger: true }}
        >
          <Column title={t('driver.table.id')} dataIndex={'id'} />
          <Column
            title={t('route.table.name')}
            dataIndex={'name'}
            sorter={(a: any, b: any) => {
              var nameA = a.name.toUpperCase(); // ignore upper and lowercase
              var nameB = b.name.toUpperCase(); // ignore upper and lowercase
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
              // names must be equal
              return 0;
            }}
            render={(value, record) => {
              return (
                <Button
                  type="link"
                  onClick={() =>
                    createRouteDetailTab(
                      `${record.name} ${record.shipping_date}`,
                      record.id
                    )
                  }
                >
                  <Text>{value}</Text>
                </Button>
              );
            }}
          />
          <Column title={t('route.table.shortName')} dataIndex={'short_name'} />
          <Column
            title={t('route.table.date')}
            dataIndex="shipping_date"
            render={(date) => {
              return <>{dayjs(date).format('YYYY/MM/DD')}</>;
            }}
          />
          <Column
            title={t('route.table.assignedDriver')}
            dataIndex="driver"
            render={(driver) =>
              driver && driver.driver_name ? <>{driver.driver_name}</> : <></>
            }
          />
          <Column
            title={t('route.table.assignedVehicle')}
            dataIndex="vehicle"
            render={(vehicle) =>
              vehicle && vehicle.vehicle_name ? (
                <Space direction="vertical">
                  <Text>{vehicle.vehicle_name}</Text>
                  <VehicleType type={vehicle.vehicle_type} />
                </Space>
              ) : (
                <></>
              )
            }
          />
          <Column
            title={t('route.routeDetail.header.startAt')}
            dataIndex="start_at"
            render={(date) => {
              return (
                <>
                  {dayjs(date).valueOf() > 0
                    ? dayjs(date).format('YYYY/MM/DD HH:mm')
                    : 'N/A'}
                </>
              );
            }}
            sorter={(a: any, b: any) => {
              if (dayjs(a.start_at).isBefore(dayjs(b.start_at))) {
                return -1;
              } else {
                return 1;
              }
            }}
          />
          <Column
            title={t('route.table.orderAmount')}
            dataIndex="orderCount"
            width={100}
          />
          <Column
            title={t('route.table.ETC')}
            dataIndex="ETC"
            render={(minutes) => (
              <Text>{t('common.minutes', { min: minutes })}</Text>
            )}
          />
          <Column
            title={t('route.table.totalDistance')}
            dataIndex="totalDistance"
          />
          <Column
            title={t('driver.table.actions.actions')}
            render={(record: any, index: number) => {
              return (
                <Space key={index}>
                  <>
                    <Button
                      onClick={() =>
                        createRouteDetailTab(
                          `${record.name} ${record.shipping_date}`,
                          record.id
                        )
                      }
                    >
                      {t('route.table.actions.view')}
                    </Button>
                  </>
                </Space>
              );
            }}
          />
        </Table>
      ) : (
        <EmptyResult
          extra={
            <Button
              type="primary"
              onClick={() => refetch({ date: shippingDate })}
            >
              {t('common.retry')}
            </Button>
          }
        />
      )}
    </>
  );
};

export default RouteList;
