import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { Typography } from 'antd';
import { orderStatusToString } from '../../../../utils/tools';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from '../../../../types';

const { Title } = Typography;

type OrderStatusChartProps = {
  data: any;
};

const OrderStatusChart = ({ data }: OrderStatusChartProps) => {
  const { t } = useTranslation();

  return (
    <div style={styles.orderStatusBarChartContainer}>
      <Title level={5} style={{ textAlign: 'center' }}>
        {t('home.periodReport.orderStatusStatistics')}
      </Title>
      <ResponsiveBar
        data={data}
        keys={[
          orderStatusToString(0).toString(),
          orderStatusToString(1).toString(),
          orderStatusToString(2).toString(),
          orderStatusToString(3).toString(),
          orderStatusToString(4).toString(),
        ]}
        indexBy="date"
        margin={{ top: 10, right: 130, bottom: 50, left: 60 }}
        minValue={0}
        padding={0.3}
        // valueScale={{ type: 'linear' }}
        // indexScale={{ type: 'band', round: true }}
        colors={{ scheme: 'nivo' }}
        borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: t('home.periodReport.date'),
          legendPosition: 'middle',
          legendOffset: 32,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: t('home.periodReport.orderAmount'),
          legendPosition: 'middle',
          legendOffset: -40,
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        legends={[
          {
            dataFrom: 'keys',
            anchor: 'bottom-right',
            direction: 'column',
            justify: false,
            translateX: 120,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: 'left-to-right',
            itemOpacity: 0.85,
            symbolSize: 20,
            effects: [
              {
                on: 'hover',
                style: {
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
        animate={true}
        motionStiffness={90}
        motionDamping={15}
      />
    </div>
  );
};

const styles: StyleSheet = {
  orderStatusBarChartContainer: {
    height: 400,
    marginBottom: 20,
  },
};

export default OrderStatusChart;
