import { ApolloError } from '@apollo/client/errors';
import { notification } from 'antd';
import i18next from 'i18next';
import i18n from 'i18next';
import { customAlphabet } from 'nanoid';
import { BuildingType, OrderStatusType, OrderType } from '../enumType';
import { logout } from './createAuthProvider';

/**
 * A function for handling Apollo GraphQL Error String
 * @param error ApolloError Object
 * @returns String Error Message
 */
export const graphqlErrorHandler = (error: ApolloError): string => {
  let errorMsgs: string | string[] = 'Error';
  if (error.message !== '') errorMsgs = error.message;

  if (error.graphQLErrors.length > 0) {
    errorMsgs = error.graphQLErrors.map((item) => {
      if (item.extensions) {
        if (item.extensions.code === 'UNAUTHENTICATED') {
          logout();
        }
        return `${item.extensions.code}: ${item.message}`;
      } else return item.message;
    });
  }
  return Array.isArray(errorMsgs) ? errorMsgs.join('</br>') : errorMsgs;
};

/**
 * A function for popup ApolloError message
 * @param error ApolloError Object
 */
export const graphqlErrorMessageAlert = (error: ApolloError) => {
  showMessage('error', graphqlErrorHandler(error));
};

type MessageType = {
  type: 'success' | 'error' | 'info' | 'warning';
  content: string;
  onClose?: Function;
};
/**
 * A wrapper function for Ant design message toast
 * @param type Message type
 * @param content The content of the message
 * @param onClose Specify a function that will be called when the message is closed
 */
export const showMessage = (
  type: MessageType['type'],
  content: MessageType['content'],
  onClose: MessageType['onClose'] = () => {}
) => {
  //   message[type](content, 3, () => {
  //     onClose();
  //   });
  notification[type]({
    message: content,
    onClose: () => onClose(),
  });
};

export const changeLanguage = (language: 'en' | 'zh') => {
  i18n.changeLanguage(language);
  localStorage.setItem('@storage_language', language);
};

export const generateDriverActiveCode = () =>
  customAlphabet('123456789ABCDEFGHIJKLMNPQRSTUVWXYZ', 6)();

/**
 * Function for covert status int value to locale string value
 * @param status Order Status 0/1/2/3/4/5
 */
export const orderStatusToString = (status: number) => {
  switch (status) {
    case OrderStatusType.Preparing:
      return i18next.t(
        'route.routeDetail.routeOrderTable.statusTypes.preparing'
      );

    case OrderStatusType.OutForDelivery:
      return i18next.t(
        'route.routeDetail.routeOrderTable.statusTypes.outForDelivery'
      );

    case OrderStatusType.ArrivingSoon:
      return i18next.t(
        'route.routeDetail.routeOrderTable.statusTypes.arrivingSoon'
      );

    case OrderStatusType.Failed:
      return i18next.t('route.routeDetail.routeOrderTable.statusTypes.failed');

    case OrderStatusType.Delivered:
      return i18next.t(
        'route.routeDetail.routeOrderTable.statusTypes.delivered'
      );

    default:
      return status;
  }
};

export const buildingTypeToString = (type: number) => {
  switch (type) {
    case BuildingType.House:
      return i18next.t('route.routeDetail.routeOrderTable.buildingType.house');

    case BuildingType.Townhouse:
      return i18next.t(
        'route.routeDetail.routeOrderTable.buildingType.townhouse'
      );

    case BuildingType.Apartment:
      return i18next.t(
        'route.routeDetail.routeOrderTable.buildingType.apartment'
      );
    case BuildingType.Basement:
      return i18next.t(
        'route.routeDetail.routeOrderTable.buildingType.basement'
      );
    case BuildingType.Store:
      return i18next.t('route.routeDetail.routeOrderTable.buildingType.store');

    case BuildingType.Other:
      return i18next.t('route.routeDetail.routeOrderTable.buildingType.other');

    default:
      return type;
  }
};

export const orderTypeToString = (type: number) => {
  switch (type) {
    case OrderType.Delivery:
      return i18next.t('route.routeDetail.routeOrderTable.orderTypes.delivery');

    case OrderType.Pickup:
      return i18next.t('route.routeDetail.routeOrderTable.orderTypes.pickup');

    case OrderType.Exchange:
      return i18next.t('route.routeDetail.routeOrderTable.orderTypes.exchange');

    case OrderType.Replenish:
      return i18next.t(
        'route.routeDetail.routeOrderTable.orderTypes.replenish'
      );

    default:
      return type;
  }
};

export const orderStatusColor = (status: OrderStatusType) => {
  switch (status) {
    case OrderStatusType.ArrivingSoon:
      return '#f57a4c';

    case OrderStatusType.Delivered:
      return '#2cae35';

    case OrderStatusType.Failed:
      return '#eb6565';

    case OrderStatusType.OutForDelivery:
      return '#4285F4';

    default:
      return '#878484';
  }
};

export const getRandomColor = () => {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const generateKey = (length: number = 6) => {
  const charsPool = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let str = '';
  for (let i = 0; i < length; i++) {
    str += charsPool.charAt(Math.floor(Math.random() * charsPool.length));
  }
  return str;
};
