import { useQuery } from '@apollo/client';
import Modal from 'antd/lib/modal/Modal';
import React from 'react';
import { GET_ROUTE_LOG } from '../../../../GraphQL/queries/route';
import { Typography } from 'antd';
import { graphqlErrorHandler } from '../../../../utils/tools';
import Loading from '../../../common/Loading';
import LogList from '../../orders/LogList';

const { Text } = Typography;

type RouteLogModalProps = {
  showRouteLogModal: boolean;
  setShowRouteLogModal: React.Dispatch<React.SetStateAction<boolean>>;
  routeId: string;
};

const RouteLogModal = ({
  showRouteLogModal,
  setShowRouteLogModal,
  routeId,
}: RouteLogModalProps) => {
  const { data, loading, error, refetch } = useQuery(GET_ROUTE_LOG, {
    variables: {
      id: routeId,
    },
    fetchPolicy: 'network-only',
  });

  const handleCancel = () => {
    setShowRouteLogModal(false);
  };

  const renderLogList = () => {
    if (loading) {
      return <Loading />;
    }

    if (data && data.routeLogs) {
      return <LogList routeLogs={data.routeLogs} logType="route" />;
    }

    if (error) {
      return <Text type="danger">{graphqlErrorHandler(error)}</Text>;
    }

    return <></>;
  };

  return (
    <Modal
      title={'更新记录'}
      visible={showRouteLogModal}
      onCancel={handleCancel}
      footer={null}
      destroyOnClose
      afterClose={() => refetch()}
    >
      {renderLogList()}
    </Modal>
  );
};

export default RouteLogModal;
