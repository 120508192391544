import { Button, Form, InputNumber, Select, Alert } from 'antd';
import { ArrowsAltOutlined } from '@ant-design/icons';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import Modal from 'antd/lib/modal/Modal';
import { useTranslation } from 'react-i18next';
import { Order } from '../../../../../types';
import {
  graphqlErrorMessageAlert,
  showMessage,
} from '../../../../../utils/tools';
import { GET_ROUTES } from '../../../../../GraphQL/queries/route';
import { useLazyQuery, useMutation } from '@apollo/client';
import { SPLIT_ROUTE } from '../../../../../GraphQL/queries/route';
import Loading from '../../../../common/Loading';

type SplitRouteProps = {
  route_id: string;
  setLoadingMap: Function;
  orders: Order[];
  shippingDate: string;
  refetch: Function;
};

const formItemLayout = {
  labelCol: {
    span: 4,
  },
  wrapperCol: {
    span: 28,
  },
};

/**
 * Manually input new order
 */
const SplitRouteBtn = ({
  route_id,
  setLoadingMap,
  orders,
  shippingDate,
  refetch,
}: SplitRouteProps) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [form] = Form.useForm();
  //const [shippingDate] = useState(dayjs().format('YYYY-MM-DD'));
  const [selectedRoutes, setSelectedRoutes] = useState(new Set());
  const [totalCapacity, setTotalCapacity] = useState(0);

  const [loadRouteData, { data: routeData }] = useLazyQuery(GET_ROUTES, {
    onError: graphqlErrorMessageAlert,
    variables: {
      date: shippingDate,
    },
  });

  // Get routes only when modal is open
  useEffect(() => {
    if (showModal) loadRouteData();
  }, [showModal, loadRouteData]);

  const [splitRoute, { loading }] = useMutation(SPLIT_ROUTE, {
    onCompleted: () => handleAddCompleted(),
    onError: graphqlErrorMessageAlert,
  });

  const handleAddCompleted = () => {
    handleCancel();
    showMessage('success', 'Successfully split route.');
    refetch();
  };

  const handleCancel = () => {
    setShowModal(false);
    form.resetFields();
    setTotalCapacity(0);
  };

  const onFinish = async (values: any) => {
    const { input } = values;

    try {
      setLoadingMap(true);
      await splitRoute({
        variables: { id: +route_id, input: input },
      });
      setLoadingMap(false);
    } catch (error) {
      console.log(error);
    }
  };

  const onValuesChange = async (changedValues: any, allValues: any) => {
    setSelectedRoutes(new Set(allValues.input?.map((x: any) => x?.id)));
    setTotalCapacity(
      allValues.input
        ?.map((x: any) => x?.max_order_number ?? 0)
        .reduce((a: any, b: any) => a + b, 0)
    );
  };

  return (
    <>
      <Button icon={<ArrowsAltOutlined />} onClick={() => setShowModal(true)}>
        {t('route.routeDetail.routeMapSection.splitRoute')}
      </Button>
      <Modal
        title={t('route.routeDetail.routeMapSection.splitRoute')}
        visible={showModal}
        onCancel={handleCancel}
        maskClosable={false}
        footer={
          <>
            <Form.Item>
              <Button onClick={handleCancel} disabled={loading}>
                {t('common.cancel')}
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  form.submit();
                }}
                disabled={totalCapacity > orders.length || loading}
              >
                {t('common.save')}
              </Button>
            </Form.Item>
          </>
        }
      >
        {loading ? (
          <Loading></Loading>
        ) : (
          <Form
            form={form}
            name={'split-route'}
            onFinish={onFinish}
            onValuesChange={onValuesChange}
            scrollToFirstError
            {...formItemLayout}
          >
            <Form.List name="input">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ name, fieldKey, key }) => (
                    <div key={key}>
                      <Form.Item
                        label={t('route.title')}
                        style={{ marginBottom: 0 }}
                      >
                        <Form.Item
                          rules={[{ required: true }]}
                          style={{
                            display: 'inline-block',
                            width: 'calc(50% - 16px)',
                          }}
                          fieldKey={[fieldKey, 'id']}
                          name={[name, 'id']}
                        >
                          <Select>
                            {routeData &&
                              routeData.routes &&
                              routeData.routes.length > 0 &&
                              routeData.routes
                                .filter((route: any) => route.id !== route_id)
                                .map((route: any) => (
                                  <Select.Option
                                    key={route.id}
                                    value={+route.id}
                                    disabled={selectedRoutes.has(+route.id)}
                                  >
                                    {route.name}
                                  </Select.Option>
                                ))}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          style={{
                            display: 'inline-block',
                            width: 'calc(50% - 16px)',
                            margin: '0 8px',
                          }}
                          fieldKey={[fieldKey, 'max_order_number']}
                          name={[name, 'max_order_number']}
                        >
                          <InputNumber
                            defaultValue={0}
                            min={0}
                            style={{ width: '100%' }}
                          />
                        </Form.Item>
                        <MinusCircleOutlined
                          style={{
                            display: 'inline-block',
                          }}
                          onClick={() => remove(name)}
                        />
                      </Form.Item>
                    </div>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      {t('route.routeDetail.routeMapSection.addRoute')}
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
            {totalCapacity > orders.length ? (
              <Alert
                message={t('route.routeDetail.routeMapSection.capacityWarning')}
                type="error"
              />
            ) : (
              <Alert
                message={t(
                  'route.routeDetail.routeMapSection.splitRouteZeroInfo'
                )}
                type="info"
              />
            )}
          </Form>
        )}
      </Modal>
    </>
  );
};

export default SplitRouteBtn;
