import React from 'react';
import { buildingTypeToString } from '../../../utils/tools';

type OrderBuildingTypeProps = {
  type: number;
};

const OrderBuildingType = ({ type }: OrderBuildingTypeProps) => {
  return <span>{buildingTypeToString(type)}</span>;
};

export default OrderBuildingType;
