import { useQuery } from '@apollo/client';
import { Button } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import EmptyResult from '../../components/common/EmptyResult';
import ErrorResult from '../../components/common/ErrorResult';
import Loading from '../../components/common/Loading';
import OrderList from '../../components/dashboard/orders/OrderList';
import { GET_ORDERS } from '../../GraphQL/queries/order';
import ViewBasicLayout from '../../layout/ViewBasicLayout';
import { OrderActionTypes } from '../../store/orderReducer';
import { OrderState, RootState } from '../../types';
import { graphqlErrorHandler } from '../../utils/tools';

const INITIAL_PAGE = 1;
const INITIAL_PAGE_SIZE = 50;

const Orders = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation<any>();

  const ordersInfo = useSelector<RootState, OrderState['orders']>(
    (state) => state.order.orders
  );

  const { data, error, loading, refetch } = useQuery<
    any,
    {
      page: number;
      pageSize: number;
      filterConditions?: any;
    }
  >(GET_ORDERS, {
    variables: {
      page: INITIAL_PAGE,
      pageSize: INITIAL_PAGE_SIZE,
      filterConditions: {},
    },
  });

  useEffect(() => {
    if (location.state && location.state.searchConditions) {
      refetch({
        page: 1,
        pageSize: 50,
        filterConditions: location.state.searchConditions,
      });

      delete location.state.searchConditions;
    }
  }, [location, refetch]);

  useEffect(() => {
    if (data && data.orders) {
      dispatch({
        type: OrderActionTypes.UPDATE_ORDERS_TABLE,
        payload: data.orders,
      });
    }
  }, [data, dispatch]);

  const handleFetchOrders = () => {
    refetch({ page: INITIAL_PAGE, pageSize: INITIAL_PAGE_SIZE });
  };

  if (loading) return <Loading />;

  if (error)
    return (
      <ErrorResult
        title={graphqlErrorHandler(error)}
        extra={
          <Button type="primary" onClick={handleFetchOrders}>
            {t('common.retry')}
          </Button>
        }
      />
    );

  return ordersInfo.orders.length > 0 ? (
    <ViewBasicLayout title={t('order.title')}>
      <OrderList
        refetch={refetch}
        initialPage={INITIAL_PAGE}
        initialPageSize={INITIAL_PAGE_SIZE}
      />
    </ViewBasicLayout>
  ) : (
    <EmptyResult
      extra={
        <Button type="primary" onClick={handleFetchOrders}>
          {t('common.retry')}
        </Button>
      }
    />
  );
};

export default Orders;
