import { Badge } from 'antd';
import React from 'react';
import { OrderStatusType } from '../../../enumType';
import { orderStatusToString } from '../../../utils/tools';

type OrderStatusProps = {
  status: number;
};

const OrderStatus = ({ status }: OrderStatusProps) => {
  let badgeStatus:
    | 'default'
    | 'success'
    | 'processing'
    | 'error'
    | 'warning'
    | undefined = 'default';

  switch (status) {
    case OrderStatusType.Preparing:
      badgeStatus = 'default';
      break;

    case OrderStatusType.OutForDelivery:
      badgeStatus = 'processing';
      break;

    case OrderStatusType.ArrivingSoon:
      badgeStatus = 'warning';
      break;

    case OrderStatusType.Failed:
      badgeStatus = 'error';
      break;

    case OrderStatusType.Delivered:
      badgeStatus = 'success';
      break;

    default:
      return <div>{'N/A'}</div>;
  }

  return (
    <Badge
      status={badgeStatus}
      text={orderStatusToString(status)}
      overflowCount={999}
      showZero
    />
  );
};

export default OrderStatus;
