import React from 'react';
import { Layout, Typography, Space, Modal, Menu } from 'antd';
import { StyleSheet, RootState, UserState } from '../../types';
import { useSelector } from 'react-redux';
import logo from '../../assets/images/appicon.png';
import { logout } from '../../utils/createAuthProvider';
import Avatar from 'antd/lib/avatar/avatar';
import { UserOutlined } from '@ant-design/icons';
import SubMenu from 'antd/lib/menu/SubMenu';
import { useTranslation } from 'react-i18next';
import { changeLanguage } from '../../utils/tools';
import { useHistory } from 'react-router-dom';

const { Header: AntDesignHeader } = Layout;
const { Title } = Typography;

const Header = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleLogout = () => {
    console.log('LOG OUT!');
    logout();
  };

  const handleShowLogoutWarning = () => {
    Modal.confirm({
      title: t('header.signOutPromptMessage'),
      onOk: () => handleLogout(),
      okText: t('common.yes'),
      cancelText: t('common.no'),
    });
  };

  const userInfo = useSelector<RootState, UserState['userInfo']>(
    (state) => state.user.userInfo
  );

  const handleLogoClick = () => {
    history.push('home');
  };

  return (
    <AntDesignHeader style={styles.header}>
      <div
        className="left-container"
        style={styles.leftContainer}
        onClick={handleLogoClick}
      >
        <Space>
          <img src={logo} style={styles.logo} alt="Luniu Mall logo" />
          <Title level={3} style={{ marginBottom: 0 }}>
            {t('general.projectName')}
          </Title>
        </Space>
      </div>
      <div className="right-container" style={styles.rightContainer}>
        <Menu
          triggerSubMenuAction={'hover'}
          selectedKeys={[]}
          mode={'horizontal'}
        >
          <SubMenu title={'中文/EN'}>
            <Menu.Item onClick={() => changeLanguage('zh')}>中文</Menu.Item>
            <Menu.Item onClick={() => changeLanguage('en')}>English</Menu.Item>
          </SubMenu>

          <SubMenu
            title={
              <>
                <span style={styles.nameLabel}>
                  <span style={{ color: 'lightgray' }}>
                    {t('header.greeting')}
                  </span>
                  {userInfo.name ? userInfo.name : ''}
                </span>
                <Avatar
                  icon={userInfo.name === '' ? <UserOutlined /> : undefined}
                >
                  {userInfo.name ? userInfo.name.charAt(0) : ''}
                </Avatar>
              </>
            }
          >
            <Menu.Item onClick={handleShowLogoutWarning}>
              {t('header.signOut')}
            </Menu.Item>
          </SubMenu>
        </Menu>
      </div>
    </AntDesignHeader>
  );
};

const styles: StyleSheet = {
  header: {
    zIndex: 10,
    backgroundColor: 'white',
    WebkitBoxShadow: '0 2px 8px #f0f1f2',
    boxShadow: '0 2px 8px #f0f1f2',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  logo: {
    width: 50,
    height: 50,
    borderRadius: 15,
  },
  leftContainer: {
    cursor: 'pointer',
  },
  rightContainer: {},
  nameLabel: {
    marginRight: '0.5em',
  },
};

export default Header;
