import { Modal, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { PrintType, PrintTypeMode } from '../../../types';
import { useEffect, useRef } from 'react';
import GeneralPrintContent from './GeneralPrintContent';

type PrintModalProps<T extends PrintType> = {
  mode: PrintTypeMode;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  data: T | undefined;
  setData?: React.Dispatch<React.SetStateAction<T | undefined>>;
  modalLoading?: boolean;
};

const PrintModal = <T extends PrintType>({
  mode,
  modalLoading,
  visible,
  setVisible,
  data,
  setData,
}: PrintModalProps<T>) => {
  const { t } = useTranslation();
  const isSubscribed = useRef(true);

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    isSubscribed.current = false;
  }, []);

  const onCancel = () => {
    setData && setData(undefined);
    setVisible(false);
  };

  const getPrintModalContent = () => {
    let content = <></>;
    if (data) {
      switch (mode) {
        case 'driverFee':
          content = (
            <GeneralPrintContent<PrintType>
              onCancel={() => onCancel}
              printoutTableData={data}
              mode={mode}
              isLandscape={true}
            />
          );
          break;
      }
    }

    return content;
  };

  return (
    <Modal
      title={t('common.print')}
      visible={visible}
      width={mode === 'driverFee' ? 1200 : 860}
      cancelText={t('common.cancel')}
      onCancel={onCancel}
      footer={null}
    >
      <Spin spinning={modalLoading}>{getPrintModalContent()}</Spin>
    </Modal>
  );
};

export default PrintModal;
