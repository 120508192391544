import React, { useEffect, useState } from 'react';
import { Layer, Source, Marker } from 'react-mapbox-gl';
import { Order } from '../../../../../types';
import { Typography } from 'antd';
import { Map } from '../../../../../utils/map';
import { StyleSheet } from '../../../../../types';
import CompareRoutes from './CompareRoutes';

const { Text } = Typography;

type Address = {
  name: string;
  latitude: number;
  longitude: number;
};

type RouteMapboxMapProps = {
  geoJson?: object[];
  startAddress: Address;
  endAddress: Address;
  orders: Order[];
  handleMarkerClick: Function;
  driverLocationInfo?: {
    latitude: number;
    longitude: number;
    location_update_at: number;
  };
  selectedOrder: Order | undefined;
  routeCompareData: Array<{
    id: string;
    name: string;
    geoJson: object[];
    orders: Order[];
  }>;
};

const RouteMapboxMap = ({
  geoJson,
  startAddress,
  endAddress,
  orders,
  handleMarkerClick,
  driverLocationInfo,
  selectedOrder,
  routeCompareData = [],
}: RouteMapboxMapProps) => {
  const [mapRef, setMapRef] = useState(null);
  const [mapCenter, setMapCenter] = useState<[number, number]>([
    -123.087718, 49.129331,
  ]);
  const [mapLegend, setMapLegend] = useState<
    Array<{
      name: string;
      color: string;
    }>
  >([]);

  //For highlight the order marker
  const [clickedOrder, setClickedOrder] = useState<string | null>(null);

  useEffect(() => {
    if (selectedOrder !== undefined) {
      setMapCenter([selectedOrder.longitude, selectedOrder.latitude]);
      if (mapRef !== null) {
        setTimeout(() => {
          /** @ts-ignore */
          mapRef.flyTo({
            center: [selectedOrder.longitude, selectedOrder.latitude],
            zoom: 13,
          });
        }, 1);
      }

      const selectedOrderIndex = orders.findIndex(
        (order) => selectedOrder.id === order.id
      );

      //For highlight the order marker
      if (selectedOrderIndex !== -1)
        setClickedOrder(`route@current-${selectedOrderIndex}`);
    } else {
      setClickedOrder(null);
    }
  }, [selectedOrder, handleMarkerClick, mapRef, orders]);

  useEffect(() => {
    if (orders.length > 0) {
      //re-center map
      let locations = orders.map((order: Order) => ({
        lat: order.latitude,
        lng: order.longitude,
      }));

      const { lat = 49.129331, lng = -123.087718 } =
        getAverageLocation(locations);
      if (mapRef !== null) {
        setTimeout(() => {
          /** @ts-ignore */
          mapRef.flyTo({
            center: [lng, lat],
            zoom: 12,
          });
        }, 1);
      }
    }
  }, [orders, mapRef]);

  const renderOrderMarkers = orders.map((order: Order, index: number) => {
    const isActiveDot = clickedOrder === `route@current-${index}`;

    const dotStyle = isActiveDot
      ? {
          backgroundColor: 'yellow',
          width: 32,
          height: 32,
          borderRadius: '50%',
        }
      : {};

    return (
      <Marker
        key={`route@current-${index}`}
        coordinates={[order.longitude, order.latitude]}
        onClick={() => {
          if (mapRef !== null) {
            setTimeout(() => {
              /** @ts-ignore */
              mapRef.flyTo({
                center: [order.longitude, order.latitude],
                zoom: 13,
              });
            }, 1);
          }
          setClickedOrder(`route@current-${index}`);
          handleMarkerClick(order);
        }}
      >
        <div
          style={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            ...dotStyle,
          }}
        >
          <Text
            style={{ color: isActiveDot ? '#F57A4C' : 'white', zIndex: 99 }}
          >
            {order.sequence_label}
          </Text>
          {!isActiveDot && (
            <img
              src={`https://driversystem.s3-us-west-2.amazonaws.com/default/status_${order.status}.png`}
              alt="order label"
              style={{ position: 'absolute', zIndex: 98 }}
            />
          )}
        </div>
      </Marker>
    );
  });

  return (
    <>
      {/** @ts-ignore */}
      <Map
        style={`mapbox://styles/mapbox/streets-v9`}
        containerStyle={{
          height: '100%',
          width: '100%',
          position: 'absolute',
        }}
        center={mapCenter}
        onStyleLoad={(map) => {
          setMapRef(map);
        }}
      >
        {geoJson &&
          geoJson.map((routePart, partIndex) => (
            <React.Fragment key={`current_${partIndex}`}>
              <Source
                id={`current_route_source@${partIndex}`}
                geoJsonSource={{
                  type: 'geojson',
                  data: routePart,
                }}
              />
              <Layer
                id={`current_route@${partIndex}`}
                type="line"
                layout={{ 'line-join': 'round', 'line-cap': 'round' }}
                paint={{ 'line-color': '#2CAE35', 'line-width': 5 }}
                sourceId={`current_route_source@${partIndex}`}
              ></Layer>
            </React.Fragment>
          ))}
        {startAddress && (
          <Marker
            coordinates={[startAddress.longitude, startAddress.latitude]}
            anchor="bottom"
          >
            <img
              src="https://driversystem.s3-us-west-2.amazonaws.com/default/origin.png"
              alt="start-icon"
            />
          </Marker>
        )}
        {endAddress && (
          <Marker
            coordinates={[endAddress.longitude, endAddress.latitude]}
            anchor="bottom"
          >
            <img
              src="https://driversystem.s3-us-west-2.amazonaws.com/default/destination.png"
              alt="finish-icon"
            />
          </Marker>
        )}
        {driverLocationInfo && (
          <Marker
            coordinates={[
              driverLocationInfo.longitude,
              driverLocationInfo.latitude,
            ]}
            anchor="bottom"
            style={{ zIndex: 999 }}
          >
            <img
              src="https://driversystem.s3-us-west-2.amazonaws.com/default/driver.png"
              alt="finish-icon"
            />
          </Marker>
        )}
        {renderOrderMarkers}
        {/* {renderOtherRoutes()} */}
        <CompareRoutes
          routeCompareData={routeCompareData}
          setMapLegend={setMapLegend}
          mapRef={mapRef}
          clickedOrder={clickedOrder}
          handleMarkerClick={handleMarkerClick}
          setClickedOrder={setClickedOrder}
        />
      </Map>
      {mapLegend.length > 0 && (
        <div style={styles.legend}>
          {mapLegend.map((item, index) => {
            return (
              <div style={styles.legendItem} key={index}>
                <Text>{item.name}</Text>
                <span
                  style={{
                    ...styles.legendItemBar,
                    backgroundColor: item.color,
                  }}
                ></span>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default RouteMapboxMap;

const styles: StyleSheet = {
  legend: {
    position: 'absolute',
    backgroundColor: '#ffffffd1',
    top: 50,
    right: 10,
    width: 160,
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '100%',
    overflowY: 'auto',
    zIndex: 99,
  },
  legendItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  legendItemBar: {
    width: 60,
    height: 5,
    borderRadius: 10,
  },
};

const getAverageLocation = (locations: Array<{ lat: number; lng: number }>) => {
  let latSum = 0,
    lngSum = 0;

  locations.forEach(({ lat, lng }: { lat: number; lng: number }) => {
    latSum += lat;
    lngSum += lng;
  });

  return { lat: latSum / locations.length, lng: lngSum / locations.length };
};
