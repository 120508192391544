import { Badge, Button, Space, Table, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { Order } from '../../../../../types';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import { ColumnsType } from 'antd/lib/table';
import { MenuOutlined } from '@ant-design/icons';
import arrayMove from 'array-move';
import { useMutation } from '@apollo/client';
import { UPDATE_ORDERS } from '../../../../../GraphQL/queries/order';
import {
  graphqlErrorMessageAlert,
  orderStatusColor,
  showMessage,
} from '../../../../../utils/tools';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

type DragRouteAddressListProps = {
  orders: Order[];
  route_id: string;
  setLoadingMap: Function;
  setSelectedOrder: Function;
  refetch: Function;
};

const DragHandle = SortableHandle(() => (
  <MenuOutlined style={{ cursor: 'pointer', color: '#999' }} />
));

const SortableItem = SortableElement((props: any) => <tr {...props} />);
const SortableWrapper = SortableContainer((props: any) => <tbody {...props} />);

const DragRouteAddressList = ({
  orders,
  route_id,
  setLoadingMap,
  setSelectedOrder,
  refetch,
}: DragRouteAddressListProps) => {
  const { t } = useTranslation();
  const [addressList, setAddressList] = useState<Order[]>([]);
  const [editingAddressList, setEditingAddressList] = useState(false);

  useEffect(() => {
    setAddressList(orders);
  }, [orders]);

  const [updateOrders] = useMutation(UPDATE_ORDERS, {
    onError: graphqlErrorMessageAlert,
    onCompleted: () => handleAddCompleted(),
  });

  const handleAddCompleted = () => {
    handleCancel();
    setLoadingMap(false);
    showMessage('success', 'Updated Orders successfully.');
    refetch();
  };

  const handleCancel = () => {
    setAddressList(orders);
    setEditingAddressList(false);
  };

  const handleSave = () => {
    const updateOrdersInputs = addressList.map((order: Order, index) => ({
      id: order.id,
      input: {
        sequence_index: ++index,
      },
    }));
    setLoadingMap(true);
    updateOrders({
      variables: {
        orders: updateOrdersInputs,
      },
    });
  };

  const columns: ColumnsType<Order> = [
    {
      title: '',
      dataIndex: 'sort',
      width: 30,
      render: () => <DragHandle />,
    },
    {
      title: editingAddressList ? (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Space wrap>
            <Button onClick={handleCancel}>{t('common.cancel')}</Button>
            <Button onClick={handleSave}>{t('common.save')}</Button>
          </Space>
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          {t('route.routeDetail.routeOrderTable.address')}
          {orders.length > 1 && (
            <Button onClick={() => setEditingAddressList(true)}>
              {t('route.routeDetail.routeMapSection.editOrderSequence')}
            </Button>
          )}
        </div>
      ),
      dataIndex: 'address',
      onCell: (record) => {
        return {
          onClick: () => setSelectedOrder(record),
        };
      },
      render: (address, record) => (
        <Space align={'start'}>
          <Badge
            count={record.sequence_label}
            style={{ backgroundColor: orderStatusColor(record.status) }}
            showZero
            overflowCount={999}
          />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Text strong>{record.receiver}</Text>
            {address}
          </div>
        </Space>
      ),
    },
  ];

  const onSortEnd = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMove([...addressList], oldIndex, newIndex).filter(
        (el: any) => !!el
      );
      setAddressList(newData);
    }
  };

  const DraggableBodyRow = ({ ...restProps }) => {
    // function findIndex base on Table rowKey props and should always be a right array index

    const index = addressList.findIndex(
      (x) => x.sequence_index === restProps['data-row-key']
    );
    return <SortableItem index={index} {...restProps} />;
  };

  const DraggableContainer = (props: any) => (
    <SortableWrapper
      useDragHandle
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  return (
    <Table
      sticky
      dataSource={addressList}
      rowKey={'sequence_index'}
      pagination={false}
      style={{ width: '20%', overflowY: 'scroll' }}
      columns={
        editingAddressList ? columns : columns.filter((col, i) => i !== 0)
      }
      components={{
        body: {
          wrapper: DraggableContainer,
          row: DraggableBodyRow,
        },
      }}
    />
  );
};

export default DragRouteAddressList;
