import { Table, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { PrintType, StyleSheet } from '../../../types';

type FeeTableSectionProps = {
} & PrintType['driverFeeData'];

const { Text } = Typography;

const FeeTableSection = ({
  shippingDate,
  wholesaleFuelBase,
  driverFeeData
}: FeeTableSectionProps) => {
  const { t } = useTranslation();

  const summaryData = driverFeeData.summary;

  const columns = [
    { title: t("feeStatistics.table.store"), dataIndex: 'storeName', key: 'storeName' },
    {
      title: t("feeStatistics.table.validOrders"),
      dataIndex: 'validOrders',
      key: 'validOrders',
    },
    {
      title: t("feeStatistics.table.vehicleOrders"),
      children: [
        {
          title: t("feeStatistics.table.companyVehicle"),
          dataIndex: 'companyVehicleOrders',
          key: 'companyVehicleOrders',
        },
        {
          title: t("feeStatistics.table.ownVehicle"),
          dataIndex: 'ownVehicleOrders',
          key: 'ownVehicleOrders',
        },
      ],
    },
    {
      title: t("feeStatistics.table.fuelBase"),
      children: [
        {
          title: t("feeStatistics.table.companyVehicle"),
          dataIndex: 'companyVehicleFee',
          key: 'companyVehicleFee',
          render: (value: any) => priceFormat(value),
        },
        {
          title: t("feeStatistics.table.ownVehicle"),
          dataIndex: 'ownVehicleFee',
          key: 'ownVehicleFee',
          render: (value: any) => priceFormat(value),
        },
      ],
    },
    {
      title: t("feeStatistics.table.fuelSubsidy"),
      dataIndex: 'fuelSubsidy',
      key: 'fuelSubsidy',
      render: (value: any) => priceFormat(value),
    },
    {
      title: t("feeStatistics.table.tips"),
      dataIndex: 'tips',
      key: 'tips',
      render: (value: any) => priceFormat(value),
    },
    {
      title: t("feeStatistics.table.subtotal"),
      dataIndex: 'subtotal',
      className: 'highlightColumn1',
      key: 'subtotal',
      render: (value: any) => priceFormat(value),
    },
  ];

  return (
    <section style={styles.mainSection}>
      <Table
        size="small"
        bordered
        columns={columns}
        rowKey={(record) => record.storeId}
        dataSource={driverFeeData.detail}
        title={() => <Text style={{fontWeight: 600}}>{`${driverFeeData.driver.driver_name}  (${shippingDate.start} - ${shippingDate.end})`}</Text>}
        summary={() => {
          return (
            <Table.Summary.Row style={{ backgroundColor: 'lightgray' }}>
              <Table.Summary.Cell index={0}>{t('feeStatistics.total')}</Table.Summary.Cell>
              <Table.Summary.Cell index={1}>
                {summaryData.validOrders}
              </Table.Summary.Cell>
              <Table.Summary.Cell
                index={2}
                colSpan={2}
                className="table-summary-center"
              >{`${summaryData.vehicleOrders}  (${summaryData.companyVehicleOrders} + ${summaryData.ownVehicleOrders})`}</Table.Summary.Cell>
              <Table.Summary.Cell
                index={3}
                colSpan={2}
                className="table-summary-center"
              >{`${priceFormat(summaryData.vehicleFee)}  (${priceFormat(
                summaryData.companyVehicleFee
              )} + ${priceFormat(
                summaryData.ownVehicleFee
              )})`}</Table.Summary.Cell>
              <Table.Summary.Cell index={4}>
                {priceFormat(summaryData.fuelSubsidy)}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={5}>
                {priceFormat(summaryData.tips)}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={6}>
                {priceFormat(summaryData.subtotal)}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          );
        }}
      />
    </section>
  );
};

const priceFormat = (value: number) => {
  return `$ ${value.toFixed(2)}`;
};

const styles: StyleSheet = {
  mainSection: {
    paddingTop: 10,
  },
};

export default FeeTableSection;
