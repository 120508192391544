import { useMutation } from '@apollo/client';
import { Badge, Button, Input, Popconfirm, Space, Table } from 'antd';
import Column from 'antd/lib/table/Column';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DELETE_ORDERS } from '../../../GraphQL/queries/order';
import { Order, OrderState, RootState } from '../../../types';
import {
  graphqlErrorMessageAlert,
  orderStatusColor,
  showMessage,
} from '../../../utils/tools';
import OrderStatus from './OrderStatus';
import OrderType from './OrderType';
import { SearchOutlined } from '@ant-design/icons';
import SearchFilter from './SearchFilter';
import OrderBuildingType from './OrderBuildingType';
import OrderLogModal from './OrderLogModal';
import { useHistory } from 'react-router-dom';
import OrderProductModal from './OrderProductModal';
import DriverRemarkModal from './DriverRemarkModal';
import { useSelector } from 'react-redux';
import EditOrderForm from './EditOrderForm';

const OrderList = ({
  refetch,
  initialPage,
  initialPageSize,
}: {
  refetch: Function;
  initialPage: number;
  initialPageSize: number;
}) => {
  const history = useHistory();
  const { t } = useTranslation();

  const ordersInfo = useSelector<RootState, OrderState['orders']>(
    (state) => state.order.orders
  );

  const { total, page, pageSize, orders } = ordersInfo;

  const [selectedOrders, setSelectedOrders] = useState([]);
  const [searchText, setSearchText] = useState('');

  //Log Modal State
  const [showLogModal, setShowLogModal] = useState(false);
  const [chosenOrderId, setChosenOrderId] = useState(-1);

  //Product Modal State
  const [showProductModal, setShowProductModal] = useState(false);
  const [orderBasicInfo, setOrderBasicInfo] = useState({});

  //Driver remark Modal State
  const [showDriverRemarkModal, setShowDriverRemarkModal] = useState(false);
  const [selectedDriverRemark, setSelectedDriverRemark] = useState<{
    remark: string;
    images: string[];
  }>({ remark: '', images: [] });

  //Order Edit Modal State
  const [showEditModal, setShowEditModal] = useState(false);
  const [editingOrder, setEditingOrder] = useState<Order | null>(null);

  const [deleteOrders] = useMutation(DELETE_ORDERS, {
    onCompleted: () => handleDeleteCompleted(),
    onError: graphqlErrorMessageAlert,
  });

  const handleDeleteCompleted = () => {
    refetch({
      page,
      pageSize,
    });
    showMessage('success', 'Successfully deleted drivers.');
    setSelectedOrders([]);
  };

  const handleDeleteConfirm = async () => {
    await deleteOrders({
      variables: {
        orders: selectedOrders,
      },
    });
  };

  const onSelectChange = (selectedRowKeys: any) => {
    setSelectedOrders(selectedRowKeys);
  };

  const rowSelection = {
    selectedOrders,
    onChange: onSelectChange,
  };

  const onPageNumberChange = (pageNumber: number, pageSize?: number) => {
    refetch({
      page: pageNumber,
      pageSize,
    });
  };

  const handleSearchOrdersBySN = () => {
    refetch({
      page: 1,
      pageSize,
      orderSN: searchText,
    });
  };

  const resetSearchCondition = () => {
    setSearchText('');
    refetch({
      page: 1,
      pageSize,
      orderSN: '',
      filterConditions: { phone: '', receiver: '' },
    });
  };

  const handleNavigateToRouteDetail = (routeId: number, title: string) => {
    history.push('routes', { routeId: routeId, title: title });
  };

  const handleViewProducts = (
    orderId: number,
    basicInfo: {
      receiver: string;
      phone: number;
      latitude: string;
      longitude: string;
      buzz: string;
      unit: string;
      address: string;
    }
  ) => {
    setChosenOrderId(orderId);
    setOrderBasicInfo(basicInfo);
    setShowProductModal(true);
  };

  const handleShowDriverRemark = (remark = '', images: string[]) => {
    //Init driver remark state
    setSelectedDriverRemark({ remark: '', images: [] });

    //Open modal
    setShowDriverRemarkModal(true);

    setSelectedDriverRemark({ remark, images });
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '.5rem',
        }}
      >
        <Popconfirm
          title={`Are you sure you want to delete ${selectedOrders.length} orders?`}
          onConfirm={() => handleDeleteConfirm()}
          onCancel={() => {}}
          okText={t('common.yes')}
          cancelText={t('common.no')}
          disabled={selectedOrders.length < 1}
        >
          <Button
            style={{
              opacity: selectedOrders.length > 0 ? 1 : 0,
              cursor: selectedOrders.length > 0 ? 'pointer' : 'default',
            }}
          >
            {t('common.delete')}
          </Button>
        </Popconfirm>
        <Space
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: '.5rem',
          }}
        >
          <Input
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setSearchText(event.target.value)
            }
            prefix={<SearchOutlined />}
            value={searchText}
            placeholder={t('order.searchPlaceholder')}
            allowClear
          />
          <Button type={'primary'} onClick={handleSearchOrdersBySN}>
            {t('common.search')}
          </Button>
          <SearchFilter refetch={refetch} pageSize={pageSize} />
          <Button onClick={resetSearchCondition}>{t('common.reset')}</Button>
        </Space>
      </div>

      <Table
        rowSelection={rowSelection}
        dataSource={orders}
        sticky
        rowKey={(record: Order) => record.id}
        scroll={{ x: 1300 }}
        pagination={{
          defaultCurrent: initialPage,
          defaultPageSize: initialPageSize,
          current: page,
          total: total,
          showSizeChanger: true,
          onChange: onPageNumberChange,
          onShowSizeChange: onPageNumberChange,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
        }}
      >
        <Column
          title={t('route.routeDetail.routeOrderTable.type')}
          dataIndex={'type'}
          render={(type) => <OrderType type={type} />}
          fixed="left"
          width={80}
        />
        <Column
          title={t('route.routeDetail.routeOrderTable.label')}
          dataIndex={'sequence_label'}
          render={(sequence_label: string, record: Order) => (
            <Badge
              count={sequence_label}
              style={{ backgroundColor: orderStatusColor(record.status) }}
              showZero
              overflowCount={999}
            />
          )}
          fixed="left"
          width={80}
        />
        <Column
          title={t('route.routeDetail.routeOrderTable.orderNumber')}
          dataIndex={'order_sn'}
          fixed="left"
          width={180}
        />
        <Column
          title={t('route.routeDetail.routeOrderTable.store')}
          dataIndex={'store'}
          render={(store) => <>{store.store_name}</>}
          width={120}
        />
        <Column
          title={t('route.routeDetail.routeOrderTable.status')}
          dataIndex={'status'}
          render={(status) => <OrderStatus status={status} />}
          width={120}
        />
        <Column
          title={t('route.routeDetail.routeOrderTable.receiver')}
          dataIndex={'receiver'}
          width={120}
        />
        <Column
          title={t('route.routeDetail.routeOrderTable.address')}
          dataIndex={'address'}
          width={200}
        />
        <Column
          title={t('route.routeDetail.routeOrderTable.phone')}
          dataIndex={'phone'}
          width={120}
        />
        <Column
          title={t('route.title')}
          dataIndex={'route'}
          render={(route) => <>{`${route.name}(${route.shipping_date})`}</>}
          width={180}
        />
        <Column
          title={t('route.routeDetail.routeOrderTable.remark')}
          dataIndex={'remark'}
          width={200}
        />
        <Column
          title={t('route.routeDetail.routeOrderTable.email')}
          dataIndex={'email'}
          width={180}
        />
        <Column
          title={t('route.routeDetail.routeOrderTable.buildingType.title')}
          dataIndex={'building_type'}
          render={(buildingType) => <OrderBuildingType type={buildingType} />}
          width={120}
        />

        <Column title="Unit" dataIndex={'unit'} width={80} />
        <Column title="Buzz" dataIndex={'buzz'} width={80} />
        <Column
          title={t('route.routeDetail.routeOrderTable.lastUpdate')}
          dataIndex="update_at"
          render={(date) => {
            return (
              <>
                {dayjs(date).valueOf() > 0
                  ? dayjs(date).format('YYYY/MM/DD HH:mm')
                  : 'N/A'}
              </>
            );
          }}
          width={120}
        />
        <Column
          title={t('route.routeDetail.routeOrderTable.createdAt')}
          dataIndex={'created_at'}
          render={(date) => {
            return <>{dayjs(date).format('YYYY/MM/DD HH:mm')} </>;
          }}
          width={120}
        />
        <Column
          title={t('driver.table.actions.actions')}
          fixed="right"
          width={180}
          render={(record: any, index: number) => {
            return (
              <Space direction="vertical">
                <Space key={index}>
                  <Button
                    size="small"
                    onClick={() => {
                      setChosenOrderId(record.id);
                      setTimeout(() => {
                        setShowLogModal(true);
                      }, 1);
                    }}
                  >
                    {t('order.log.title')}
                  </Button>
                  <Button
                    size="small"
                    onClick={() => {
                      handleNavigateToRouteDetail(
                        record.route_id,
                        `${record.route.name} ${record.route.shipping_date}`
                      );
                    }}
                  >
                    {t('route.title')}
                  </Button>
                </Space>
                <Space>
                  <Button
                    size="small"
                    onClick={() => {
                      handleViewProducts(record.id, {
                        receiver: record.receiver,
                        phone: record.phone,
                        latitude: record.latitude,
                        longitude: record.longitude,
                        buzz: record.buzz,
                        unit: record.unit,
                        address: record.address,
                      });
                    }}
                  >
                    {t('order.productModal.product')}
                  </Button>
                  <Button
                    size="small"
                    onClick={() => {
                      setEditingOrder(record);
                      setTimeout(() => {
                        setShowEditModal(true);
                      }, 1);
                    }}
                  >
                    {t('common.edit')}
                  </Button>
                </Space>

                {(record.driver_remark ||
                  (Array.isArray(record.order_images) &&
                    record.order_images.length > 0)) && (
                  <Space>
                    <Badge dot>
                      <Button
                        size="small"
                        onClick={() => {
                          handleShowDriverRemark(
                            record.driver_remark,
                            record.order_images.map((img: any) => img.img_url)
                          );
                        }}
                      >
                        {t('order.driverRemark')}
                      </Button>
                    </Badge>
                  </Space>
                )}
              </Space>
            );
          }}
        />
      </Table>
      {showLogModal && chosenOrderId !== -1 && (
        <OrderLogModal
          showLogModal={showLogModal}
          setShowLogModal={setShowLogModal}
          chosenOrderId={chosenOrderId}
        />
      )}
      {showProductModal && chosenOrderId !== -1 && (
        <OrderProductModal
          showProductModal={showProductModal}
          setShowProductModal={setShowProductModal}
          chosenOrderId={chosenOrderId}
          orderBasicInfo={orderBasicInfo}
        />
      )}
      {showDriverRemarkModal && (
        <DriverRemarkModal
          setShowDriverModal={setShowDriverRemarkModal}
          showDriverModal={showDriverRemarkModal}
          remark={selectedDriverRemark.remark}
          images={selectedDriverRemark.images}
        />
      )}
      {showEditModal && editingOrder !== null && (
        <EditOrderForm
          showModal={showEditModal}
          setShowModal={setShowEditModal}
          editingOrder={editingOrder}
          route_id={editingOrder.route_id}
        />
      )}
    </>
  );
};

export default OrderList;
