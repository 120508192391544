import { DatePicker, Divider, Typography } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DayReport from '../../components/dashboard/home/DayReport';
import HomeMapSection from '../../components/dashboard/home/HomeMapSection';
import PeriodReport from '../../components/dashboard/home/PeriodReport';
import ViewBasicLayout from '../../layout/ViewBasicLayout';
import { StyleSheet } from '../../types';

const { Title } = Typography;

const Home = () => {
  const { t } = useTranslation();

  //State for show day report and map route, default is today
  const [shippingDate, setShippingDate] = useState(
    dayjs().format('YYYY-MM-DD')
  );

  const changeDate = (_: any, dateString: string) => {
    setShippingDate(dateString);
  };
  return (
    <ViewBasicLayout>
      <Divider orientation="left">
        <Title level={4}>{t('home.report.title')}</Title>
      </Divider>
      <DatePicker
        onChange={changeDate}
        defaultValue={moment(shippingDate)}
        allowClear={false}
      />
      <div style={styles.todaySection}>
        <div style={{ flex: 0.5, paddingRight: 10 }}>
          <DayReport date={shippingDate} />
        </div>
        <div style={{ flex: 1 }}>
          <HomeMapSection date={shippingDate} />
        </div>
      </div>
      <PeriodReport />
    </ViewBasicLayout>
  );
};

const styles: StyleSheet = {
  todaySection: {
    display: 'flex',
    marginTop: 10,
  },
  periodSection: {},
};

export default Home;
