import { Button, Modal } from 'antd';
import { RetweetOutlined } from '@ant-design/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { REVERSE_ROUTE } from '../../../../../GraphQL/queries/route';
import { useMutation } from '@apollo/client';
import {
  graphqlErrorMessageAlert,
  showMessage,
} from '../../../../../utils/tools';

type ReverseRouteBtnProps = {
  route_id: string;
  refetch: Function;
};

const ReverseRouteBtn = ({ route_id, refetch }: ReverseRouteBtnProps) => {
  const { t } = useTranslation();

  const [reverseRoute] = useMutation(REVERSE_ROUTE, {
    variables: {
      id: route_id,
    },
    onCompleted: (data) => {
      if (data.reverseRoute.status) {
        showMessage('success', 'Successfully reverse route.');
      } else {
        showMessage('error', 'Failed to reverse route.');
      }
      refetch();
    },
    onError: graphqlErrorMessageAlert,
  });

  const handlePress = () => {
    Modal.confirm({
      title: t('route.routeDetail.routeMapSection.calculateRouteWarning'),
      onOk: handleConfirmPress,
      okText: t('common.yes'),
      cancelText: t('common.no'),
    });
  };

  const handleConfirmPress = () => {
    reverseRoute();
  };

  return (
    <Button icon={<RetweetOutlined />} onClick={handlePress}>
      {t('route.routeDetail.routeMapSection.reverseRoute')}
    </Button>
  );
};

export default ReverseRouteBtn;
