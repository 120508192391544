import { gql } from '@apollo/client';

export const GET_STORES = gql`
  query GetStores {
    stores {
      id
      store_name
      store_code
      credential_code
      api_path
      created_at
      update_at
    }
  }
`;

export const ADD_STORE = gql`
  mutation AddStore(
    $store_name: String!
    $store_code: String!
    $credential_code: String
    $api_path: String
  ) {
    addStore(
      store_name: $store_name
      store_code: $store_code
      credential_code: $credential_code
      api_path: $api_path
    ) {
      id
      store_name
      store_code
      credential_code
      api_path
      created_at
      update_at
    }
  }
`;

export const UPDATE_STORE = gql`
  mutation UpdateStore($id: ID!, $input: updateStoreInput!) {
    updateStore(id: $id, input: $input) {
      id
      store_name
      store_code
      credential_code
      api_path
      created_at
      update_at
    }
  }
`;

export const DELETE_STORE = gql`
  mutation DeleteStore($id: ID!) {
    deleteStore(id: $id) {
      id
      store_name
      store_code
      credential_code
      api_path
      created_at
      update_at
    }
  }
`;
