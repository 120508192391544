import { gql } from '@apollo/client';

export const ADD_DRIVER = gql`
  mutation AddDriver(
    $driverName: String!
    $phone: String!
    $code: String!
    $email: String
    $remark: String
  ) {
    addDriver(
      driver_name: $driverName
      phone: $phone
      active_code: $code
      email: $email
      remark: $remark
    ) {
      driver_id
      driver_name
      phone
      email
      status
      active_code
      remark
      update_at
      created_at
      expo_token
    }
  }
`;

export const DRIVER_LIST = gql`
  query DriverList($isDeleted: Boolean!) {
    drivers(is_delete: $isDeleted) {
      driver_id
      driver_name
      phone
      email
      status
      active_code
      remark
      update_at
      created_at
      expo_token
    }
  }
`;

export const DELETE_DRIVER = gql`
  mutation DeleteDriver($driver_id: ID!) {
    deleteDriver(driver_id: $driver_id)
  }
`;

export const UPDATE_DRIVER = gql`
  mutation UpdateDriver($driver_id: ID!, $input: updateDriverInput!) {
    updateDriver(driver_id: $driver_id, input: $input) {
      driver_id
      driver_name
      phone
      email
      status
      active_code
      latitude
      longitude
      remark
      update_at
      created_at
      expo_token
    }
  }
`;

export const ASSIGNED_DRIVER_LIST = gql`
  query DriverList {
    drivers {
      driver_id
      driver_name
      phone
      status
    }
  }
`;

export const RESTORE_DRIVER = gql`
  mutation RestoreDriver($driver_id: ID!) {
    restoreDriver(driver_id: $driver_id)
  }
`;
