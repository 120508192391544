import { ReducerActionType, OrderState } from '../types';

const initState: OrderState = {
  orders: {
    total: 0,
    page: 0,
    pageSize: 0,
    orders: [],
  },
};

export enum OrderActionTypes {
  UPDATE_ORDERS_TABLE = 'UPDATE_ORDERS_TABLE',
}

const orderReducer = (state = initState, action: ReducerActionType) => {
  switch (action.type) {
    case OrderActionTypes.UPDATE_ORDERS_TABLE:
      return {
        ...state,
        orders: action.payload,
      };

    default:
      return state;
  }
};

export default orderReducer;
