import { useMutation } from '@apollo/client';
import { Button, Popconfirm, Space, Table } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Address } from '../../../types';
import { graphqlErrorMessageAlert, showMessage } from '../../../utils/tools';
import { QuestionCircleOutlined } from '@ant-design/icons';
import {
  DELETE_DEFAULT_ADDRESS,
  GET_DEFAULT_ADDRESSES,
} from '../../../GraphQL/queries/address';
import EditDefaultAddress from './EditDefaultAddress';

const { Column } = Table;

type AddressListProps = {
  list: Address[];
};

const DefaultAddressList = ({ list }: AddressListProps) => {
  const { t } = useTranslation();
  const [deleteDefaultAddress] = useMutation(DELETE_DEFAULT_ADDRESS, {
    onCompleted: () => handleDeleteCompleted(),
    onError: graphqlErrorMessageAlert,
  });

  const handleDeleteCompleted = () => {
    showMessage('success', t('addressBook.deleteSuccess'));
  };

  const handleDeleteConfirm = (id: string) => {
    deleteDefaultAddress({
      variables: { id },
      refetchQueries: [{ query: GET_DEFAULT_ADDRESSES }],
    });
  };

  return (
    <Table
      dataSource={list}
      rowKey={(record) => record.id}
      sticky
      scroll={{ x: 1300 }}
      pagination={{ defaultPageSize: 10 }}
    >
      <Column title="ID" dataIndex="id" width={60} />
      <Column title={t('route.table.name')} dataIndex="route_name" />
      <Column title={t('route.table.shortName')} dataIndex="short_name" />
      <Column title={t('route.table.startPoint')} dataIndex="start_point_id" />
      <Column title={t('route.table.endPoint')} dataIndex="end_point_id" />
      <Column title={t('route.table.sortOrder')} dataIndex="sort_order" />
      <Column
        fixed="right"
        width={130}
        title={t('route.table.actions.title')}
        render={(record) => (
          <Space>
            <EditDefaultAddress routeInfo={record} />
            <Popconfirm
              title={t('addressBook.deletePrompt')}
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              okText={t('common.yes')}
              cancelText={t('common.no')}
              onConfirm={() => handleDeleteConfirm(record.id)}
            >
              <Button size="small" danger>
                {t('common.delete')}
              </Button>
            </Popconfirm>
          </Space>
        )}
      />
    </Table>
  );
};

export default DefaultAddressList;
