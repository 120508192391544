import React, { useEffect } from 'react';
import { Layer, Marker, Source } from 'react-mapbox-gl';
import { useLazyQuery } from '@apollo/client';
import { DRAW_ROUTE_MAP } from '../../../GraphQL/queries/home';
import Loading from '../../common/Loading';
import { getRandomColor, graphqlErrorHandler } from '../../../utils/tools';
import { Typography } from 'antd';
import { StyleSheet } from '../../../types';
import { Map } from '../../../utils/map';
import { DriverStatusType } from '../../../enumType';

const { Text } = Typography;

const DEFAULT_CENTER: [number, number] = [-123.087718, 49.129331];

type HomeMapSectionProps = {
  date: string;
};

const routeColorSet = [
  '#2CAE35',
  '#f57a4c',
  '#b0a3d4',
  '#ffa9e7',
  '#e85f5c',
  '#4285F4',
  '#f5b700',
  '#d90368',
];

const HomeMapSection = ({ date }: HomeMapSectionProps) => {
  const mapLegend: Array<{
    name: string;
    color: string;
  }> = [];

  let renderRoutes: JSX.Element | JSX.Element[] = <></>;

  const [drawRoutes, { data, error, loading }] = useLazyQuery(DRAW_ROUTE_MAP, {
    variables: { date },
    fetchPolicy: 'network-only',
    context: {
      headers: {
        'x-timeout': 1000, // Set custom timeout 5 seconds for this request
      },
    },
  });

  useEffect(() => {
    drawRoutes();
  }, [drawRoutes]);

  if (error) {
    return <Text type="danger">{graphqlErrorHandler(error)}</Text>;
  }

  if (loading) return <Loading />;

  if (data) {
    const routes: Array<{
      name: string;
      geoJson: object[];
      id: string;
      start_at: string | null;
      driver: {
        driver_id: string;
        latitude: number;
        longitude: number;
        status: DriverStatusType;
        driver_name: string;
      } | null;
    }> = data.routes;

    if (routes.length > 0) {
      renderRoutes = routes.map((route, index: number) => {
        // line color depends on index, if outside of color scope, generate random color
        const lineColor =
          routeColorSet[index] !== undefined
            ? routeColorSet[index]
            : getRandomColor();

        //Apply map legend
        mapLegend.push({ name: route.name, color: lineColor });

        const renderDriverVan = () => {
          //Apply driver icon
          if (route.driver !== null) {
            if (
              route.driver.status === DriverStatusType.OnDuty &&
              route.start_at !== null &&
              route.driver.latitude !== 0 &&
              route.driver.longitude !== 0
            ) {
              return (
                <>
                  <Marker
                    coordinates={[
                      route.driver.longitude,
                      route.driver.latitude,
                    ]}
                    anchor="bottom"
                    style={{ zIndex: 999 }}
                  >
                    <div style={styles.driverIconContainer}>
                      <Text
                        style={{
                          backgroundColor: 'white',
                          padding: 3,
                          textAlign: 'center',
                          borderRadius: 5,
                        }}
                      >
                        {route.driver.driver_name}
                      </Text>
                      <img
                        src="https://driversystem.s3-us-west-2.amazonaws.com/default/driver.png"
                        alt="finish-icon"
                        style={{ width: 32 }}
                      />
                    </div>
                  </Marker>
                </>
              );
            }
          }

          return <></>;
        };

        return (
          <React.Fragment key={index}>
            {route.geoJson &&
              route.geoJson.map((routePart, partIndex) => {
                return (
                  <React.Fragment key={partIndex}>
                    <Source
                      id={`route_source@${index}-${partIndex}`}
                      geoJsonSource={{
                        type: 'geojson',
                        data: routePart,
                      }}
                    />
                    <Layer
                      id={`route@${index}-${partIndex}`}
                      type="line"
                      layout={{ 'line-join': 'round', 'line-cap': 'round' }}
                      paint={{
                        'line-color': lineColor,
                        'line-width': 5,
                      }}
                      sourceId={`route_source@${index}-${partIndex}`}
                    ></Layer>
                  </React.Fragment>
                );
              })}
            {renderDriverVan()}
          </React.Fragment>
        );
      });
    }
  }

  return (
    <div style={{ width: '100%', height: '60vh', position: 'relative' }}>
      <Map
        style={`mapbox://styles/mapbox/streets-v9`}
        containerStyle={{
          height: '100%',
          width: '100%',
          position: 'absolute',
        }}
        center={DEFAULT_CENTER}
      >
        {renderRoutes}
      </Map>
      {mapLegend.length > 0 && (
        <div style={styles.legend}>
          {mapLegend.map((item, index) => {
            return (
              <div style={styles.legendItem} key={index}>
                <Text>{item.name}</Text>
                <span
                  style={{
                    ...styles.legendItemBar,
                    backgroundColor: item.color,
                  }}
                ></span>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default HomeMapSection;

const styles: StyleSheet = {
  legend: {
    position: 'absolute',
    backgroundColor: '#ffffffd1',
    top: 10,
    left: 10,
    width: 160,
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '100%',
    overflowY: 'auto',
    zIndex: 1000,
  },
  legendItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  legendItemBar: {
    width: 60,
    height: 5,
    borderRadius: 10,
  },
  driverIconContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
};
