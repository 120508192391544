import { SearchOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import {
  Button,
  Input,
  Modal,
  Select,
  Space,
  Spin,
  Table,
  Typography,
} from 'antd';
import Column from 'antd/lib/table/Column';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  GET_ROUTE,
  UPDATE_ROUTE_INFO,
} from '../../../../GraphQL/queries/route';
import { GET_VEHICLES } from '../../../../GraphQL/queries/vehicle';
import { graphqlErrorMessageAlert, showMessage } from '../../../../utils/tools';
import Loading from '../../../common/Loading';
import VehicleType from '../../vehicles/VehicleType';

const { Title } = Typography;
const { Option } = Select;

type AssignVehicleModelProps = {
  setShowVehicleModal: Function;
  routeId: string;
};

const AssignVehicleModal = ({
  setShowVehicleModal,
  routeId,
}: AssignVehicleModelProps) => {
  const [isModalVisible, setIsModalVisible] = useState(true);

  const { data, loading } = useQuery(GET_VEHICLES, {
    onError: graphqlErrorMessageAlert,
  });

  const handleClose = () => {
    setShowVehicleModal(false);
    setIsModalVisible(false);
  };

  return (
    <Modal
      width={'50vw'}
      visible={isModalVisible}
      onCancel={handleClose}
      footer={<></>}
    >
      {loading ? (
        <Loading />
      ) : data && data.vehicles ? (
        <VehicleList
          vehicles={data.vehicles}
          routeId={routeId}
          handleClose={handleClose}
        />
      ) : (
        <></>
      )}
    </Modal>
  );
};

type VehicleListProps = {
  vehicles: Array<{
    id: string;
    vehicle_name: string;
    vehicle_plate: string;
    vehicle_type: number;
    fuel_base: number;
    fuel_subsidy: number;
  }>;
  routeId: string;
  handleClose: Function;
};

const VehicleList = ({ vehicles, routeId, handleClose }: VehicleListProps) => {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState('');
  const [displayVehicles, setDisplayVehicles] = useState(vehicles);
  const [searchVehicleType, setSearchVehicleType] = useState(-1);
  const [showSubmitLoading, setShowSubmitLoading] = useState(false);

  const [assignDriver] = useMutation(UPDATE_ROUTE_INFO, {
    onError: (error) => {
      graphqlErrorMessageAlert(error);
      setShowSubmitLoading(false);
    },
    onCompleted: () => handleAssignCompleted(),
    awaitRefetchQueries: true,
    refetchQueries: [{ query: GET_ROUTE, variables: { id: routeId } }],
  });

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  useEffect(() => {
    const filteredDrivers = vehicles.filter((vehicle) => {
      const textCheck =
       vehicle.vehicle_name.toLowerCase().includes(searchText.toLowerCase()) ||
       vehicle.vehicle_plate.includes(searchText);

      const statusCheck = searchVehicleType === -1 || vehicle.vehicle_type === searchVehicleType;
      return textCheck && statusCheck;
    });

    setDisplayVehicles(filteredDrivers);
  }, [searchText, searchVehicleType, vehicles]);

  const handleAssignVehicle = (vehicleId: string) => {
    setShowSubmitLoading(true);
    assignDriver({
      variables: {
        id: routeId,
        input: {
          vehicle_id: vehicleId,
        },
      },
    });
  };

  const handleAssignCompleted = () => {
    showMessage(
      'success',
      t('route.routeDetail.header.vehicleInfo.messages.assignSuccess')
    );
    handleClose();
    setShowSubmitLoading(false);
  };

  const resetList = () => {
    setSearchText('');
    setSearchVehicleType(-1);
    setDisplayVehicles(vehicles);
  };

  return (
    <Spin spinning={showSubmitLoading} size="large">
      <Title level={3}>
        {t('route.routeDetail.header.vehicleInfo.assignVehicle')}
      </Title>
      <Space style={{ marginBottom: '1rem' }}>
        <Input
          onChange={onSearch}
          prefix={<SearchOutlined />}
          value={searchText}
          placeholder={t('driver.searchPlaceholder')}
          allowClear
        />
        <Select
          defaultValue={-1}
          style={{ width: 120 }}
          onChange={(value) => {
            setSearchVehicleType(value);
          }}
          value={searchVehicleType}
        >
          <Option value={-1}>All</Option>
          <Option value={0}>
            {t('vehicles.vehicleType.companyVehicle')}
          </Option>
          <Option value={1}>
            {t('vehicles.vehicleType.ownVehicle')}
          </Option>
        </Select>
        <Button onClick={resetList}>{t('common.reset')}</Button>
      </Space>
      <Table
        dataSource={displayVehicles}
        pagination={false}
        sticky
        rowKey={(record) => record.id}
      >
        <Column
          title={t('vehicles.table.vehicleName')}
          dataIndex={'vehicle_name'}
          sorter={(a: any, b: any) => {
            var nameA = a.vehicle_name.toUpperCase(); // ignore upper and lowercase
            var nameB = b.vehicle_name.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            // names must be equal
            return 0;
          }}
          defaultSortOrder="ascend"
        />
        <Column title={t('vehicles.table.vehiclePlate')} dataIndex={'vehicle_plate'} />
        <Column
          title={t('vehicles.table.vehicleType')}
          dataIndex={'vehicle_type'}
          render={(vehicle_type) => <VehicleType type={vehicle_type}/>}
        />
        <Column title={t('vehicles.table.fuelBase')} dataIndex={'fuel_base'} />
        <Column title={t('vehicles.table.fuelSubsidy')} dataIndex={'fuel_subsidy'} />
        <Column
          title={t('vehicles.table.actions.title')}
          render={(record: any, index: number) => {
            return (
              <Button onClick={() => handleAssignVehicle(record.id)}>
                {t('route.routeDetail.header.vehicleInfo.assign')}
              </Button>
            );
          }}
        />
      </Table>
    </Spin>
  );
};

export default AssignVehicleModal;
