import { ReducerActionType, RouteState } from '../types';

const initState: RouteState = {
  routePanes: [],
  activePaneKey: 'RouteListView',
};

export enum RouteActionTypes {
  UPDATE_ROUTE_PANES = 'UPDATE_ROUTE_PANES',
  UPDATE_ACTIVE_PANE_KEY = 'UPDATE_ACTIVE_PANE_KEY',
}

const routeReducer = (state = initState, action: ReducerActionType) => {
  switch (action.type) {
    case RouteActionTypes.UPDATE_ROUTE_PANES:
      return {
        ...state,
        routePanes: action.payload,
      };

    case RouteActionTypes.UPDATE_ACTIVE_PANE_KEY:
      return {
        ...state,
        activePaneKey: action.payload,
      };

    default:
      return state;
  }
};

export default routeReducer;
