import React, { useState } from 'react';
import { DashboardRoute } from '../../types';
import { Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';

const { SubMenu } = Menu;

const Sidebar = ({ routes }: { routes: DashboardRoute[] }) => {
  const location = useLocation();

  const [selectedRoute, setSelectedRoute] = useState(location.pathname);

  React.useEffect(() => {
    setSelectedRoute(location.pathname);
  }, [location]);

  const renderSideMenu = routes.map(({ icon, title, path, subMenu = [] }) => {
    if (subMenu.length > 0) {
      return (
        <SubMenu key={path} icon={icon} title={title}>
          {subMenu.map((subMenu) => (
            <Menu.Item key={`${path + subMenu.path}`}>
              <Link to={path + subMenu.path}>{subMenu.title}</Link>
            </Menu.Item>
          ))}
        </SubMenu>
      );
    }

    return (
      <Menu.Item key={path} icon={icon}>
        <Link to={path}>{title}</Link>
      </Menu.Item>
    );
  });

  return (
    <Menu mode="inline" inlineIndent={30} selectedKeys={[selectedRoute]}>
      {renderSideMenu}
    </Menu>
  );
};

export default Sidebar;
