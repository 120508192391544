import { useMutation } from '@apollo/client';
import { Button, Form, Input, Space } from 'antd';
import React, { useState } from 'react';
import { graphqlErrorMessageAlert, showMessage } from '../../../utils/tools';
import { EditOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { UPDATE_USER } from '../../../GraphQL/queries/user';
import PwdChangerModal from './PwdChangerModal';

const layout = {
  wrapperCol: { span: 4 },
};

type UserInfoProps = {
  user: {
    id: string;
    name: string;
    username: string;
    role: string;
  };
};

const UserInfo = ({ user }: UserInfoProps) => {
  const { t } = useTranslation();
  const [isEdit, setIsEdit] = useState(false);

  const [updateUser] = useMutation(UPDATE_USER, {
    onError: graphqlErrorMessageAlert,
    onCompleted: () => {
      showMessage('success', 'Update user profile successfully.');
      setIsEdit(false);
    },
  });

  const onFinish = (values: any) => {
    console.log('Success:', values);
    updateUser({
      variables: { id: user.id, input: { name: values.nickname } },
    });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div>
      {!isEdit ? (
        <Form
          name="userInfo"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          {...layout}
        >
          <Form.Item label={t('profile.form.id')}>
            <span>{user.id}</span>
          </Form.Item>

          <Form.Item label={t('profile.form.username')}>
            <span>{user.username}</span>
          </Form.Item>

          <Form.Item label={t('profile.form.nickname')}>
            <span>{user.name}</span>
          </Form.Item>

          <Form.Item label={t('profile.form.role')}>
            <span>{user.role}</span>
          </Form.Item>
          <Form.Item>
            <Button icon={<EditOutlined />} onClick={() => setIsEdit(true)}>
              {t('profile.editNickname')}
            </Button>
          </Form.Item>
        </Form>
      ) : (
        <Form
          name="userInfo"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          {...layout}
        >
          <Form.Item label={t('profile.form.id')}>
            <span>{user.id}</span>
          </Form.Item>

          <Form.Item label={t('profile.form.username')}>
            <span>{user.username}</span>
          </Form.Item>

          <Form.Item
            label={t('profile.form.nickname')}
            name="nickname"
            initialValue={user.name}
            rules={[
              { required: true },
              { min: 2, message: 'Length must greater than 2' },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item label={t('profile.form.role')}>
            <span>{user.role}</span>
          </Form.Item>

          <Form.Item>
            <Space>
              <Button type="primary" htmlType="submit">
                {t('common.save')}
              </Button>
              <Button onClick={() => setIsEdit(false)}>
                {t('common.cancel')}
              </Button>
            </Space>
          </Form.Item>
        </Form>
      )}
      <PwdChangerModal userId={user.id} />
    </div>
  );
};

export default UserInfo;
