import { Form, Modal, Button, Input, Select, Space, Tooltip } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SyncOutlined } from '@ant-design/icons';
import {
  generateDriverActiveCode,
  graphqlErrorMessageAlert,
  showMessage,
} from '../../../utils/tools';
import { useMutation } from '@apollo/client';
import { UPDATE_DRIVER } from '../../../GraphQL/queries/driver';

type EditDriverFormProps = {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  editingDriver: {
    driver_id: string;
    driver_name: string;
    phone: string;
    email: string;
    active_code: string;
    status: string;
    remark: string;
  };
};

const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 14,
  },
};

const EditDriverForm = ({
  showModal,
  setShowModal,
  editingDriver,
}: EditDriverFormProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [updateUser] = useMutation(UPDATE_DRIVER, {
    onError: graphqlErrorMessageAlert,
    onCompleted: () => {
      showMessage('success', 'Update driver information successfully.');
      handleCancel();
    },
  });

  const handleCancel = () => {
    setShowModal(false);
    form.resetFields();
  };

  const onFinish = (values: any) => {
    updateUser({
      variables: {
        driver_id: editingDriver.driver_id,
        input: values,
      },
    });
  };

  return (
    <Modal
      title={t('driver.editDriver')}
      visible={showModal}
      onCancel={handleCancel}
      afterClose={() => {
        form.resetFields();
      }}
      destroyOnClose={true}
      maskClosable={false}
      footer={
        <>
          <Form.Item>
            <Button onClick={handleCancel}>{t('common.cancel')}</Button>
            <Button
              type="primary"
              onClick={() => {
                form.submit();
              }}
            >
              {t('common.save')}
            </Button>
          </Form.Item>
        </>
      }
    >
      <Form
        form={form}
        name={'edit-driver'}
        onFinish={onFinish}
        scrollToFirstError
        {...formItemLayout}
      >
        <Form.Item
          name="driver_name"
          label={t('driver.table.name')}
          initialValue={editingDriver.driver_name}
          rules={[
            {
              required: true,
              whitespace: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="phone"
          label={t('driver.table.phone')}
          initialValue={editingDriver.phone}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="email"
          label={t('driver.table.email')}
          initialValue={editingDriver.email}
          rules={[
            {
              type: 'email',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t('driver.table.activeCode')}
          rules={[{ required: true }]}
        >
          <Space>
            <Form.Item
              name="active_code"
              noStyle
              initialValue={editingDriver.active_code}
            >
              <Input style={{ width: 90 }} disabled />
            </Form.Item>
            <Tooltip title={t('driver.table.actions.generateCode')}>
              <Button
                shape="circle"
                type="primary"
                icon={<SyncOutlined />}
                onClick={() => {
                  form.setFieldsValue({
                    active_code: generateDriverActiveCode(),
                  });
                }}
              />
            </Tooltip>
          </Space>
        </Form.Item>
        <Form.Item
          name="status"
          label={t('driver.table.status')}
          initialValue={editingDriver.status}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select>
            <Select.Option value={0}>
              {t('driver.table.statusTypes.offDuty')}
            </Select.Option>
            <Select.Option value={1}>
              {t('driver.table.statusTypes.onDuty')}
            </Select.Option>
            <Select.Option value={2}>
              {t('driver.table.statusTypes.forbidden')}
            </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="remark"
          label={t('driver.table.remark')}
          initialValue={editingDriver.remark}
        >
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditDriverForm;
