import axios, { AxiosRequestConfig } from 'axios';
import { ResponseObject } from '../types';

const BASE_URL =
  process.env.REACT_APP_BACKEND_URL || 'http://localhost:3388/api/v1';

axios.defaults.baseURL = BASE_URL;
axios.defaults.withCredentials = true;

export async function getData(endpoint: string, options?: AxiosRequestConfig) {
  let response: ResponseObject = null;
  let error: ResponseObject = null;
  try {
    let { data, status } = await axios.get(endpoint, options);
    response = { data, status };
  } catch (err: any) {
    let { data, status } = err.response;
    error = { data, status };
  }
  return { response, error };
}

export async function postData(
  endpoint: string,
  body: any,
  options?: AxiosRequestConfig
) {
  let response: ResponseObject = null;
  let error: ResponseObject = null;
  try {
    let { data, status } = await axios.post(endpoint, body, options);
    response = { data, status };
  } catch (err: any) {
    let { data, status } = err.response;
    error = { data, status };
  }
  return { response, error };
}

export async function getNewToken() {
  const response = await postData('/auth/refresh_token', { source: 'cookies' });
  return response;
}
