import { gql } from '@apollo/client';

export const GET_USER_INFO = gql`
  query User($id: ID!) {
    user(id: $id) {
      id
      username
      name
      role
    }
  }
`;

export const USER_LIST = gql`
  query UserList {
    users {
      id
      name
      username
      role
      created_at
      last_login
    }
  }
`;

export const ADD_USER = gql`
  mutation AddUser(
    $username: String!
    $password: String!
    $role: String
    $nickname: String
  ) {
    addUser(
      username: $username
      password: $password
      role: $role
      name: $nickname
    )
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser($id: ID!, $input: updateUserInput!) {
    updateUser(id: $id, input: $input) {
      id
      name
      role
    }
  }
`;

export const DELETE_USER = gql`
  mutation DeleteUser($id: ID!) {
    deleteUser(id: $id)
  }
`;
