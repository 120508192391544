import { Badge } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DriverStatusType } from '../../../enumType';

type DriverStatusProps = {
  status: number;
};

const DriverStatus = ({ status }: DriverStatusProps) => {
  const { t } = useTranslation();

  switch (status) {
    default:
    case DriverStatusType.OffDuty:
      return (
        <Badge status="default" text={t('driver.table.statusTypes.offDuty')} />
      );
    case DriverStatusType.OnDuty:
      return (
        <Badge status="success" text={t('driver.table.statusTypes.onDuty')} />
      );
    case DriverStatusType.Forbidden:
      return (
        <Badge status="error" text={t('driver.table.statusTypes.forbidden')} />
      );
  }
};

export default DriverStatus;
