import { combineReducers } from 'redux';
import orderReducer from './orderReducer';
import routeReducer from './routeReducer';
import statisticsReducer from './statisticsReducer';
import userReducer from './userReducer';

const rootReducer = combineReducers({
  user: userReducer,
  route: routeReducer,
  order: orderReducer,
  statistics: statisticsReducer,
});

export default rootReducer;
