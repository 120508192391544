import { Button, Table } from 'antd';
import React, { useState } from 'react';
import { SimpleDriverInfo, StyleSheet } from '../../../types';
import { orderStatusToString } from '../../../utils/tools';
import ExportCSV from './ExportCSV';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import DateDetailModal from './DateDetailModal';

const { Column } = Table;

type DriverTableSectionProps = {
  selectedDrivers: SimpleDriverInfo[];
  shippingDate: { start: string; end: string };
  driverData: any;
};

const DriverTableSection = ({
  selectedDrivers,
  shippingDate,
  driverData,
}: DriverTableSectionProps) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [pickedDriver, setPickedDriver] = useState({ id: -1, name: '' });

  const handleGetDetail = (id: string, name: string) => {
    setIsModalVisible(true);
    setPickedDriver({ id: parseInt(id), name: name });
  };

  const handleJumpToOrder = (
    driverId: number | string,
    status: number,
    start: string,
    end: string
  ) => {
    history.push('/orders', {
      searchConditions: {
        driver_id: driverId,
        status: status,
        shipping_date: start,
        shipping_date_end: end,
      },
    });
  };

  return (
    <>
      {selectedDrivers.length > 0 && (
        <ExportCSV
          data={
            driverData.length > 0
              ? driverData.map((data: any) => {
                  return {
                    ID: data.driver.driver_id,
                    [`${t('statistics.table.name')}`]: data.driver.driver_name,
                    [`${t('statistics.table.total')}`]: data.totalOrders,
                    [`${t('statistics.table.totalDistance')}`]: data.totalDistance,
                    [`${t('statistics.table.valid')}`]: data.validOrderCount,
                    Tips: `$${data.totalTips.toFixed(2)}`,
                    [`${orderStatusToString(4)}`]: data.totalOrderStatus[4],
                    [`${orderStatusToString(3)}`]: data.totalOrderStatus[3],
                    [`${orderStatusToString(2)}`]: data.totalOrderStatus[2],
                    [`${orderStatusToString(1)}`]: data.totalOrderStatus[1],
                    [`${orderStatusToString(0)}`]: data.totalOrderStatus[0],
                  };
                })
              : []
          }
          filename={`Driver Report(${shippingDate.start} - ${shippingDate.end}).csv`}
        />
      )}
      <section style={styles.mainSection}>
        <Table
          dataSource={driverData}
          rowKey={(record: any) => record.driver.driver_id}
          pagination={{ defaultPageSize: 100, hideOnSinglePage: true }}
          sticky
          summary={(pageData) => {
            let total = 0;
            let totalDistance = 0;
            let validTotal = 0;
            let statusTotal = [0, 0, 0, 0, 0];
            let tipsTotal = 0;

            pageData.forEach((data) => {
              total += data.totalOrders;
              totalDistance += data.totalDistance;
              validTotal += data.validOrderCount;
              tipsTotal += data.totalTips;
              data.totalOrderStatus.forEach((item: number, index: number) => {
                statusTotal[index] += item;
              });
            });

            return total !== 0 ? (
              <Table.Summary.Row style={{ backgroundColor: 'lightgray' }}>
                <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                <Table.Summary.Cell index={1}></Table.Summary.Cell>
                <Table.Summary.Cell index={2}>{total}</Table.Summary.Cell>
                <Table.Summary.Cell index={3}>{totalDistance.toFixed(2)}</Table.Summary.Cell>
                <Table.Summary.Cell index={4}>{validTotal}</Table.Summary.Cell>
                <Table.Summary.Cell index={5}>{`$ ${tipsTotal.toFixed(
                  2
                )}`}</Table.Summary.Cell>
                <Table.Summary.Cell index={6}>
                  <span style={{ padding: '4px 15px' }}>{statusTotal[4]}</span>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={7}>
                  <span style={{ padding: '4px 15px' }}>{statusTotal[3]}</span>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={8}>
                  <span style={{ padding: '4px 15px' }}>{statusTotal[2]}</span>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={9}>
                  <span style={{ padding: '4px 15px' }}>{statusTotal[1]}</span>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={10}>
                  <span style={{ padding: '4px 15px' }}>{statusTotal[0]}</span>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            ) : (
              <></>
            );
          }}
        >
          <Column
            title="ID"
            dataIndex="driver"
            key={Math.random()}
            render={(value: any) => value.driver_id}
          />
          <Column
            title={t('statistics.table.name')}
            dataIndex="driver"
            key={Math.random()}
            render={(value: any) => value.driver_name}
          />
          <Column
            title={t('statistics.table.total')}
            dataIndex="totalOrders"
            key={Math.random()}
          />
          <Column
            title={t('statistics.table.totalDistance')}
            dataIndex="totalDistance"
            key={Math.random()}
          />
          <Column
            title={t('statistics.table.valid')}
            dataIndex="validOrderCount"
            className="highlightColumn1"
            key={Math.random()}
          />
          <Column
            title="Tips"
            dataIndex="totalTips"
            key={Math.random()}
            className="highlightColumn2"
            render={(value: any) => `$ ${value.toFixed(2)}`}
          />
          {[4, 3, 2, 1, 0].map((i) => {
            return (
              <Column
                title={orderStatusToString(i)}
                dataIndex="totalOrderStatus"
                key={i}
                render={(value: any, record: any) => (
                  <Button
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      handleJumpToOrder(
                        record.driver.driver_id,
                        i,
                        shippingDate.start,
                        shippingDate.end
                      );
                    }}
                    disabled={value[i] === 0}
                    type="text"
                  >
                    {value[i]}
                  </Button>
                )}
              />
            );
          })}
          <Column
            title={t('statistics.table.view')}
            render={(_, record: any) => {
              return (
                <Button
                  onClick={() =>
                    handleGetDetail(
                      record.driver.driver_id,
                      record.driver.driver_name
                    )
                  }
                >
                  {t('statistics.table.detail')}
                </Button>
              );
            }}
            key={Math.random()}
          />
        </Table>
      </section>
      <DateDetailModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        pickedDriver={pickedDriver}
        shippingDate={shippingDate}
      />
    </>
  );
};

const styles: StyleSheet = {
  mainSection: {
    paddingTop: 10,
  },
};

export default DriverTableSection;
