import { forwardRef } from 'react';
import { PrintType } from '../../../types';
import FeeTableSection from '../../dashboard/statistics/FeeTableSection';

export const ComponentToPrint = forwardRef((props: PrintType, ref) => {
  if (props.driverFeeData) {
    return (
      <FeeTableSection
        {...props.driverFeeData}
      />
    );
  }
  return <></>;
});
