import { getNewToken } from './service';

export const createTokenProvider = () => {
  let _accessToken: string | null =
    JSON.parse(localStorage.getItem('REACT_TOKEN_AUTH') || 'null') || null;

  const getExpirationDate = (jwtToken?: string): number | null => {
    if (!jwtToken) return null;
    const jwt = JSON.parse(atob(jwtToken.split('.')[1]));

    // multiply by 1000 to convert seconds into milliseconds
    return (jwt && jwt.exp && jwt.exp * 1000) || null;
  };

  const isExpired = (exp?: number | null) => {
    if (!exp) return false;
    return Date.now() > exp;
  };

  const getToken = async () => {
    if (!_accessToken) return null;
    if (isExpired(getExpirationDate(_accessToken))) {
      const { response, error } = await getNewToken();
      if (error) {
        console.log(error);
        return null;
      }
      if (response) {
        const { access_token: newToken } = response.data;
        setToken(newToken);
      }
    }

    return _accessToken;
  };

  const isLoggedIn = () => {
    return !!_accessToken;
  };

  let observers: Array<(isLogged: boolean) => void> = [];

  const subscribe = (observer: (isLogged: boolean) => void) => {
    observers.push(observer);
  };

  const unsubscribe = (observer: (isLogged: boolean) => void) => {
    observers = observers.filter((_observer) => _observer !== observer);
  };

  const notify = () => {
    const isLogged = isLoggedIn();
    observers.forEach((observer) => observer(isLogged));
  };

  const setToken = (token: typeof _accessToken | null) => {
    if (token) {
      console.log('setting token');
      localStorage.setItem('REACT_TOKEN_AUTH', JSON.stringify(token));
    } else {
      localStorage.removeItem('REACT_TOKEN_AUTH');
    }
    _accessToken = token;
    notify();
  };

  return {
    getToken,
    isLoggedIn,
    setToken,
    subscribe,
    unsubscribe,
  };
};
