import { gql, useQuery } from '@apollo/client';
import { Button } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import EmptyResult from '../../components/common/EmptyResult';
import ErrorResult from '../../components/common/ErrorResult';
import Loading from '../../components/common/Loading';
import UserInfo from '../../components/dashboard/profile/UserInfo';
import ViewBasicLayout from '../../layout/ViewBasicLayout';
import { RootState, UserState } from '../../types';
import {
  graphqlErrorHandler,
  graphqlErrorMessageAlert,
} from '../../utils/tools';

const GET_USER_INFO = gql`
  query User($id: ID!) {
    user(id: $id) {
      id
      name
      username
      role
    }
  }
`;

const Profile = () => {
  const { t } = useTranslation();

  const userInfo = useSelector<RootState, UserState['userInfo']>(
    (state) => state.user.userInfo
  );

  //Fetch user info by user.id
  const { loading, error, data, refetch } = useQuery(GET_USER_INFO, {
    variables: { id: userInfo.id },
    onError: graphqlErrorMessageAlert,
  });

  const handleReFetchUserInfo = () => {
    refetch({ id: userInfo.id });
  };

  if (loading) return <Loading />;

  if (error)
    return (
      <ErrorResult
        title={graphqlErrorHandler(error)}
        extra={
          <Button type="primary" onClick={handleReFetchUserInfo}>
            {t('common.retry')}
          </Button>
        }
      />
    );

  if (data.user)
    return (
      <ViewBasicLayout title={t('profile.title')}>
        <UserInfo user={data.user} />
      </ViewBasicLayout>
    );

  return (
    <EmptyResult
      extra={
        <Button type="primary" onClick={handleReFetchUserInfo}>
          {t('common.retry')}
        </Button>
      }
    />
  );
};

export default Profile;
