import { Tag } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { OrderType as EnumOrderType } from '../../../enumType';

type OrderTypeProps = {
  type: number;
};

const OrderType = ({ type }: OrderTypeProps) => {
  const { t } = useTranslation();
  switch (type) {
    case EnumOrderType.Delivery:
      return (
        <Tag color={'blue'}>
          {t('route.routeDetail.routeOrderTable.orderTypes.delivery')}
        </Tag>
      );
    case EnumOrderType.Pickup:
      return (
        <Tag color={'volcano'}>
          {t('route.routeDetail.routeOrderTable.orderTypes.pickup')}
        </Tag>
      );
    case EnumOrderType.Exchange:
      return (
        <Tag color={'cyan'}>
          {t('route.routeDetail.routeOrderTable.orderTypes.exchange')}
        </Tag>
      );
    case EnumOrderType.Replenish:
      return (
        <Tag color={'magenta'}>
          {t('route.routeDetail.routeOrderTable.orderTypes.replenish')}
        </Tag>
      );
    default:
      return <div>{'N/A'}</div>;
  }
};

export default OrderType;
