import { useMutation } from '@apollo/client';
import { Button, Popconfirm, Space, Table } from 'antd';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DELETE_VEHICLE, GET_VEHICLES } from '../../../GraphQL/queries/vehicle';
import { graphqlErrorMessageAlert, showMessage } from '../../../utils/tools';
import VehicleType from './VehicleType';
import EditVehicleForm from './EditVehicleForm';

const { Column } = Table;

type VehicleListProps = {
  vehicles: Array<{
    id: string;
    vehicle_name: string;
    vehicle_plate: string;
    vehicle_type: number;
    fuel_base: number;
    fuel_subsidy: number;
    created_at: string;
    update_at: string;
  }>;
};

const VehicleList = ({ vehicles }: VehicleListProps) => {
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);
  const [editingVehicle, setEditingVehicle] = useState({
    id: '',
    vehicle_name: '',
    vehicle_plate: '',
    vehicle_type: 0,
    fuel_base: 0,
    fuel_subsidy: 0,
  });

  const [deleteVehicle] = useMutation(DELETE_VEHICLE, {
    onCompleted: () => handleDeleteCompleted(),
    onError: graphqlErrorMessageAlert,
  });

  const handleDeleteConfirm = (id: string) => {
    deleteVehicle({
      variables: { id: id },
      update: (proxy, deleteVehicle) => {
        const data: any = proxy.readQuery({ query: GET_VEHICLES });
        proxy.writeQuery({
          query: GET_VEHICLES,
          data: { ...data, vehicles: [...data.vehicles, deleteVehicle] },
        });
      },
    });
  };

  const handleDeleteCompleted = () => {
    showMessage('success', 'Delete successfully');
  };

  const handleTriggerEditForm = (currentRowInfo: any) => {
    setEditingVehicle(currentRowInfo);
    setShowModal(true);
  };

  return (
    <>
      <Table
        sticky
        dataSource={vehicles}
        rowKey={(record) => record.id}
        scroll={{ x: 1300 }}
      >
        <Column title="ID" dataIndex="id" width={60} />
        <Column
          title={t('vehicles.table.vehicleName')}
          dataIndex="vehicle_name"
        />
        <Column
          title={t('vehicles.table.vehiclePlate')}
          dataIndex="vehicle_plate"
        />
        <Column
          title={t('vehicles.table.vehicleType')}
          dataIndex="vehicle_type"
          render={(type) => {
            return <VehicleType type={type} />;
          }}
        />
        <Column
          title={t('vehicles.table.fuelBase')}
          dataIndex="fuel_base"
        />
        <Column
          title={t('vehicles.table.fuelSubsidy')}
          dataIndex="fuel_subsidy"
        />
        <Column
          title={t('vehicles.table.updateAt')}
          dataIndex="update_at"
          width={160}
          render={(date) => {
            return (
              <>
                {dayjs(date).valueOf() > 0
                  ? dayjs(date).format('YYYY/MM/DD HH:mm')
                  : 'N/A'}
              </>
            );
          }}
        />
        <Column
          title={t('vehicles.table.createdAt')}
          dataIndex="created_at"
          width={160}
          render={(date) => {
            return <>{dayjs(date).format('YYYY/MM/DD HH:mm')}</>;
          }}
        />
        <Column
          title={t('vehicles.table.actions.title')}
          fixed="right"
          width={140}
          render={(record: any, index: number) => {
            return (
              <Space key={index}>
                <Button
                  onClick={() => handleTriggerEditForm(record)}
                  size="small"
                >
                  {t('vehicles.table.actions.edit')}
                </Button>
                <Popconfirm
                  title={t('vehicles.table.deletePromptMessage')}
                  onConfirm={() => handleDeleteConfirm(record.id)}
                  onCancel={() => {}}
                  okText={t('common.yes')}
                  cancelText={t('common.no')}
                >
                  <Button danger size="small">
                    {t('vehicles.table.actions.delete')}
                  </Button>
                </Popconfirm>
              </Space>
            );
          }}
        />
      </Table>
      <EditVehicleForm
        showModal={showModal}
        setShowModal={setShowModal}
        editingVehicle={editingVehicle}
      />
    </>
  );
};

export default VehicleList;
