import { PlusOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Form, Input, InputNumber, Modal, Select } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ADD_VEHICLE, GET_VEHICLES } from '../../../GraphQL/queries/vehicle';
import {
    graphqlErrorMessageAlert,
    showMessage
} from '../../../utils/tools';
import Loading from '../../common/Loading';

const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 14,
  },
};

const AddNewVehicle = () => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [form] = Form.useForm();

  const [addVehicle, { loading }] = useMutation(ADD_VEHICLE, {
    onCompleted: () => handleAddCompleted(),
    onError: graphqlErrorMessageAlert,
  });

  const handleCancel = () => {
    form.resetFields();
    setShowModal(false);
  };

  const handleAddCompleted = () => {
    handleCancel();
    showMessage('success', 'Successfully add vehicle.');
  };

  const onFinish = (values: any) => {
    addVehicle({
      variables: {
        vehicle_name: values.vehicle_name,
        vehicle_plate: values.vehicle_plate,
        vehicle_type: values.vehicle_type,
        fuel_base: values.fuel_base,
        fuel_subsidy: values.fuel_subsidy,
      },
      update: (proxy, addVehicle) => {
        const data: any = proxy.readQuery({ query: GET_VEHICLES });
        proxy.writeQuery({
          query: GET_VEHICLES,
          data: {
            ...data,
            vehicles: [...data.vehicles, addVehicle],
          },
        });
      },
    });
  };

  return (
    <>
      <Button icon={<PlusOutlined />} onClick={() => setShowModal(true)}>
        {t('vehicles.addNewVehicle')}
      </Button>
      <Modal
        title={t('vehicles.addNewVehicle')}
        visible={showModal}
        onCancel={handleCancel}
        maskClosable={false}
        footer={
          <>
            <Form.Item>
              <Button disabled={loading} onClick={handleCancel}>
                {t('common.cancel')}
              </Button>
              <Button
                disabled={loading}
                type="primary"
                onClick={() => {
                  form.submit();
                }}
              >
                {t('vehicles.table.actions.add')}
              </Button>
            </Form.Item>
          </>
        }
      >
        {loading ? (
          <Loading />
        ) : (
          <Form
            form={form}
            name="add-vehicle"
            onFinish={onFinish}
            scrollToFirstError
            {...formItemLayout}
          >
            <Form.Item
              name="vehicle_name"
              label={t('vehicles.table.vehicleName')}
              rules={[
                {
                  required: true,
                  message: 'Please input vehicle name!',
                  whitespace: true,
                },
                { min: 1 },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="vehicle_plate"
              label={t('vehicles.table.vehiclePlate')}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="vehicle_type"
              label={t('vehicles.table.vehicleType')}
              rules={[{ required: true}]}
            >
                <Select>
                    <Select.Option value={0}>
                        {t('vehicles.vehicleType.companyVehicle')}
                    </Select.Option>
                    <Select.Option value={1}>
                        {t('vehicles.vehicleType.ownVehicle')}
                    </Select.Option>
                </Select>
            </Form.Item>
            <Form.Item
              name="fuel_base"
              label={t('vehicles.table.fuelBase')}
              rules={[
                {
                  required: true,
                  message: 'Please input fuel base!',
                },
              ]}
            >
              <InputNumber />
            </Form.Item>
            <Form.Item
              name="fuel_subsidy"
              label={t('vehicles.table.fuelSubsidy')}
              rules={[
                {
                  required: true,
                  message: 'Please input fuel subsidy!',
                },
              ]}
            >
              <InputNumber />
            </Form.Item>
          </Form>
        )}
      </Modal>
    </>
  );
};

export default AddNewVehicle;
