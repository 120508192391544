import { Button, Form, Input, Modal, Select, Tooltip } from 'antd';
import React, { useState } from 'react';
import { PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { graphqlErrorMessageAlert, showMessage } from '../../../utils/tools';
import Loading from '../../common/Loading';
import { ADD_USER, USER_LIST } from '../../../GraphQL/queries/user';
import { useTranslation } from 'react-i18next';

const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 14,
  },
};

const AddNewUser = () => {
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);

  const [form] = Form.useForm();

  const [addUser, { loading }] = useMutation(ADD_USER, {
    onCompleted: () => handleAddCompleted(),
    onError: graphqlErrorMessageAlert,
  });

  const handleCancel = () => {
    setShowModal(false);
    form.resetFields();
  };

  const handleAddCompleted = () => {
    handleCancel();
    showMessage('success', 'Successfully add user.');
  };

  const onFinish = (values: any) => {
    addUser({
      variables: {
        username: values.username,
        password: values.password,
        nickname: values.nickname,
        role: values.role,
      },
      update: (proxy, addUser) => {
        const data: any = proxy.readQuery({ query: USER_LIST });
        proxy.writeQuery({
          query: USER_LIST,
          data: {
            ...data,
            users: [...data.users, addUser],
          },
        });
      },
    });
  };

  const handleAddUser = () => {
    form.submit();
  };

  return (
    <>
      <Button icon={<PlusOutlined />} onClick={() => setShowModal(true)}>
        {t('userList.addNew')}
      </Button>
      <Modal
        title={t('userList.addNew')}
        visible={showModal}
        onCancel={handleCancel}
        maskClosable={false}
        footer={
          <>
            <Form.Item>
              <Button disabled={loading} onClick={handleCancel}>
                {t('common.cancel')}
              </Button>
              <Button disabled={loading} type="primary" onClick={handleAddUser}>
                {t('userList.table.actions.add')}
              </Button>
            </Form.Item>
          </>
        }
      >
        {loading ? (
          <Loading />
        ) : (
          <Form
            form={form}
            name="register"
            onFinish={onFinish}
            scrollToFirstError
            {...formItemLayout}
          >
            <Form.Item
              name="username"
              label={t('userList.table.username')}
              rules={[
                {
                  required: true,
                  message: 'Please input your Username',
                },
                { min: 3 },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="password"
              label={t('userList.table.password')}
              rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                },
                { min: 6 },
              ]}
              hasFeedback
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              name="confirm"
              label={t('userList.table.confirmPassword')}
              dependencies={['password']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Please confirm your password!',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      'The two passwords that you entered do not match!'
                    );
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              name="nickname"
              label={
                <span>
                  {t('userList.table.nickname')}&nbsp;
                  <Tooltip title="What do you want others to call you?">
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              }
              rules={[
                {
                  required: true,
                  message: 'Please input your nickname!',
                  whitespace: true,
                },
                { min: 3 },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="role"
              initialValue="admin"
              label={t('userList.table.role')}
              rules={[{ required: true }]}
            >
              <Select>
                <Select.Option value="admin">Admin</Select.Option>
                <Select.Option value="route-planner">
                  Route Planner
                </Select.Option>
              </Select>
            </Form.Item>
          </Form>
        )}
      </Modal>
    </>
  );
};

export default AddNewUser;
