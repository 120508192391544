import { useQuery } from '@apollo/client';
import { Button } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import EmptyResult from '../../components/common/EmptyResult';
import ErrorResult from '../../components/common/ErrorResult';
import Loading from '../../components/common/Loading';
import DriverList from '../../components/dashboard/drivers/DriverList';
import { DRIVER_LIST } from '../../GraphQL/queries/driver';
import ViewBasicLayout from '../../layout/ViewBasicLayout';
import { graphqlErrorHandler } from '../../utils/tools';

const Drivers = () => {
  const { t } = useTranslation();
  //Get driver list
  const { data, error, loading, refetch } = useQuery(DRIVER_LIST, {
    variables: { isDeleted: false },
  });

  const handleFetchDriverList = () => {
    refetch({ isDeleted: false });
  };

  if (loading) return <Loading />;

  if (error)
    return (
      <ErrorResult
        title={graphqlErrorHandler(error)}
        extra={
          <Button type="primary" onClick={handleFetchDriverList}>
            {t('common.retry')}
          </Button>
        }
      />
    );

  if (data.drivers)
    return (
      <ViewBasicLayout title={t('driver.title')}>
        <DriverList drivers={data && data.drivers ? data.drivers : []} />
      </ViewBasicLayout>
    );

  return (
    <EmptyResult
      extra={
        <Button type="primary" onClick={handleFetchDriverList}>
          {t('common.retry')}
        </Button>
      }
    />
  );
};

export default Drivers;
