import React from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from '@apollo/client';
import Auth from './pages/Auth';
import Dashboard from './pages/Dashboard';
import { useAuth, getToken } from './utils/createAuthProvider';
import { setContext } from '@apollo/client/link/context';
import { Provider as ReduxProvider } from 'react-redux';
import { createStore } from 'redux';
import rootReducer from './store/rootReducer';

const BASE_GRAPHQL_URL = process.env.REACT_APP_BACKEND_URL
  ? process.env.REACT_APP_BACKEND_URL + '/graphql'
  : 'http://localhost:3388/api/v1/graphql';

const store = createStore(rootReducer);

const httpLink = createHttpLink({
  uri: BASE_GRAPHQL_URL,
  credentials: 'include',
});
const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = await getToken(); // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});
const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      Driver: {
        keyFields: ['driver_id'],
      },
    },
  }),
});

function App() {
  //Show dashboard layout when user is log in, or show login screen
  const { isLogged } = useAuth();

  return (
    <ApolloProvider client={client}>
      <ReduxProvider store={store}>
        <div style={{ width: '100vw', height: '100vh' }}>
          <Router>
            <Switch>
              <Route path="/" component={isLogged ? Dashboard : Auth} />
              <Redirect to={'/'} />           
            </Switch>
          </Router>
        </div>
      </ReduxProvider>
    </ApolloProvider>
  );
}

export default App;
