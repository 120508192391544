import { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import { PrintType, PrintTypeMode } from '../../../types';
import { Button, Spin } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { useTranslation } from 'react-i18next';
import ReactToPrint from 'react-to-print';
import { ComponentToPrint } from './ComponentToPrint';

type GeneralPrintContentProps<T extends PrintType> = {
  mode: PrintTypeMode;
  printoutTableData: T;
  onCancel: Function;
  isLandscape?: boolean;
  isZoom?: boolean;
  beforePrint?: {
    function: Function;
    isLoading: boolean;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
  };
};

const GeneralPrintContent = <T extends PrintType>({
  mode,
  printoutTableData,
  onCancel,
  isLandscape = false,
  isZoom = false,
  beforePrint,
}: GeneralPrintContentProps<T>) => {
  //General Components
  const screens = useBreakpoint();
  const { t } = useTranslation();
  const ref = useRef<any>();
  const isSubscribed = useRef(true);

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    isSubscribed.current = false;
  }, []);

  const reactPrint = () => {
    return (
      <ReactToPrint
        key={1}
        pageStyle={pageStyle(isLandscape)}
        documentTitle={mode}
        content={() => ref.current}
        onBeforePrint={() => (beforePrint ? beforePrint.function() : '')}
        onPrintError={() => {t('common.retry')}}
        trigger={() => (
          <Button type="primary" key={1}>
            {t('common.print')}
          </Button>
        )}
      ></ReactToPrint>
    );
  };

  const componentPrint = () => {
    switch (mode) {
      case 'driverFee':
        return (
          <ComponentToPrint driverFeeData={printoutTableData.driverFeeData} />
        );

      default:
        return <></>;
    }
  };

  const renderContent = () => {
    return (
      <>
        <div
          style={{
            width: '100%',
            flexDirection: 'row',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          {reactPrint()}
        </div>

        <div
          style={{
            zoom: isZoom ? (screens.sm ? 0.8 : 0.6) : 1,
            padding: 0,
            width: '100%',
          }}
          ref={ref}
        >
          <div style={{ margin: 0 }}>{componentPrint()}</div>
        </div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row-reverse',
          }}
        >
          {reactPrint()}
          <Button key={0} onClick={onCancel()} type="link">
            {t('common.cancel')}
          </Button>
        </div>
      </>
    );
  };

  return beforePrint ? (
    <Spin spinning={beforePrint.isLoading}>{renderContent()}</Spin>
  ) : (
    renderContent()
  );
};

export default GeneralPrintContent;

const pageStyle = (isLandscape: boolean) => {
  const size = isLandscape ? 'landscape' : 'portrait';
  return `@page { size: ${size}; margin: 5mm;}`;
};
