import { Button, Form, Input, Popover, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import FilterOutlined from '@ant-design/icons/lib/icons/FilterOutlined';
import { useLazyQuery } from '@apollo/client';
import { GET_ORDERS_BY_GOODS_KEYWORD } from '../../../GraphQL/queries/order';
import { Order } from '../../../types';
import { useEffect, useState } from 'react';
import { graphqlErrorMessageAlert } from '../../../utils/tools';

type RouteAdvancedSearchFilterProps = {
  orders: Order[];
  setDisplayOrders: React.Dispatch<React.SetStateAction<Order[]>>;
};

const RouteAdvancedSearchFilter = ({
  orders,
  setDisplayOrders,
}: RouteAdvancedSearchFilterProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [orderSNs, setOrderSNs] = useState<string[]>();
  const [getOrders] = useLazyQuery(GET_ORDERS_BY_GOODS_KEYWORD, {
    onError: graphqlErrorMessageAlert,
    onCompleted: (data) => {
      const filteredOrders = orders.filter((order) =>
        data.ordersByGoodsKeyword.order_sns.includes(order.order_sn)
      );
      setDisplayOrders(filteredOrders);
    },
  });

  const onReset = () => {
    form.resetFields();
    setDisplayOrders(orders);
  };

  useEffect(() => {
    let orderSNs = [];
    for (let order of orders) {
      orderSNs.push(order.order_sn);
    }
    setOrderSNs(orderSNs);
  }, [orders]);

  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        getOrders({
          variables: {
            orderSNs: orderSNs,
            keyword: values.keyword,
          },
        });
      })
      .catch((err) => console.log(err));
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Popover
      title={t('order.conditionSearch.title')}
      trigger={['click']}
      getPopupContainer={(triggerNode) => triggerNode.parentNode as HTMLElement}
      content={
        <Form
          name="routesAdvancedSearch"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item name="keyword" label={t('order.productModal.product')}>
            <Input />
          </Form.Item>
          <Form.Item>
            <Space>
              <Button type="primary" htmlType="submit">
                {t('order.conditionSearch.submitBtn')}
              </Button>
              <Button htmlType="button" onClick={onReset}>
                {t('common.reset')}
              </Button>
            </Space>
          </Form.Item>
        </Form>
      }
    >
      <Button icon={<FilterOutlined />}>
        {t('order.conditionSearch.title')}
      </Button>
    </Popover>
  );
};

export default RouteAdvancedSearchFilter;
