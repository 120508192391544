import { Button, Popconfirm, Space, Table } from 'antd';
import React, { useState } from 'react';
import dayjs from 'dayjs';
import { graphqlErrorMessageAlert, showMessage } from '../../../utils/tools';
import { useMutation } from '@apollo/client';
import { DELETE_STORE, GET_STORES } from '../../../GraphQL/queries/store';
import { useTranslation } from 'react-i18next';
import EditStoreForm from './EditStoreForm';

const { Column } = Table;

type StoreListProps = {
  stores: Array<{
    id: string;
    store_name: string;
    store_code: string;
    credential_code: string;
    api_path: string;
    created_at: string;
    update_at: string;
  }>;
};

const StoreList = ({ stores }: StoreListProps) => {
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);
  const [editingStore, setEditingStore] = useState({
    id: '',
    store_name: '',
    store_code: '',
    credential_code: '',
    api_path: '',
  });

  const [deleteStore] = useMutation(DELETE_STORE, {
    onCompleted: () => handleDeleteCompleted(),
    onError: graphqlErrorMessageAlert,
  });

  const handleDeleteConfirm = (id: string) => {
    deleteStore({
      variables: { id: id },
      update: (proxy, deleteStore) => {
        const data: any = proxy.readQuery({ query: GET_STORES });
        proxy.writeQuery({
          query: GET_STORES,
          data: { ...data, stores: [...data.stores, deleteStore] },
        });
      },
    });
  };

  const handleDeleteCompleted = () => {
    showMessage('success', 'Delete successfully');
  };

  const handleTriggerEditForm = (currentRowInfo: any) => {
    setEditingStore(currentRowInfo);
    setShowModal(true);
  };

  return (
    <>
      <Table
        sticky
        dataSource={stores}
        rowKey={(record) => record.id}
        scroll={{ x: 1300 }}
      >
        <Column title="ID" dataIndex="id" width={60} />
        <Column
          title={t('stores.table.storeName')}
          dataIndex="store_name"
          width={160}
        />
        <Column
          title={t('stores.table.storeCode')}
          dataIndex="store_code"
          width={160}
        />
        <Column
          title={t('stores.table.credentialCode')}
          dataIndex="credential_code"
        />
        <Column
          title={t('stores.table.apiPath')}
          dataIndex="api_path"
          width={250}
        />
        <Column
          title={t('stores.table.updateAt')}
          dataIndex="update_at"
          width={160}
          render={(date) => {
            return (
              <>
                {dayjs(date).valueOf() > 0
                  ? dayjs(date).format('YYYY/MM/DD HH:mm')
                  : 'N/A'}
              </>
            );
          }}
        />
        <Column
          title={t('stores.table.createdAt')}
          dataIndex="created_at"
          width={160}
          render={(date) => {
            return <>{dayjs(date).format('YYYY/MM/DD HH:mm')}</>;
          }}
        />
        <Column
          title={t('stores.table.actions.title')}
          fixed="right"
          width={140}
          render={(record: any, index: number) => {
            return (
              <Space key={index}>
                <Button
                  onClick={() => handleTriggerEditForm(record)}
                  size="small"
                >
                  {t('stores.table.actions.edit')}
                </Button>
                <Popconfirm
                  title={t('stores.table.deletePromptMessage')}
                  onConfirm={() => handleDeleteConfirm(record.id)}
                  onCancel={() => {}}
                  okText={t('common.yes')}
                  cancelText={t('common.no')}
                >
                  <Button danger size="small">
                    {t('stores.table.actions.delete')}
                  </Button>
                </Popconfirm>
              </Space>
            );
          }}
        />
      </Table>
      <EditStoreForm
        showModal={showModal}
        setShowModal={setShowModal}
        editingStore={editingStore}
      />
    </>
  );
};

export default StoreList;
