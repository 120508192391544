import { useMutation, useQuery } from '@apollo/client';
import { Alert, Button, Card, Modal, Popconfirm, Result, Space } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  GET_ORDER_LOG,
  GET_ORDER_PRODUCTS_INFO,
  UPDATE_ORDER,
} from '../../../GraphQL/queries/order';
import {
  graphqlErrorHandler,
  graphqlErrorMessageAlert,
  showMessage,
} from '../../../utils/tools';
import Loading from '../../common/Loading';
import _ from 'lodash';
import OrderProductList from './OrderProductList';
import ErrorResult from '../../common/ErrorResult';
import OrderFieldCompareTable from './OrderFieldCompareTable';

type orderBasicInfoType = {
  receiver: string;
  phone: number;
  latitude: string;
  longitude: string;
  buzz: string;
  unit: string;
  address: string;
};

type OrderProductModalProps = {
  showProductModal: boolean;
  setShowProductModal: React.Dispatch<React.SetStateAction<boolean>>;
  chosenOrderId: string | number;
  orderBasicInfo?: orderBasicInfoType | {};
};

const OrderProductModal = ({
  chosenOrderId,
  showProductModal,
  setShowProductModal,
}: OrderProductModalProps) => {
  const { t } = useTranslation();

  const { loading, error, data, refetch } = useQuery(GET_ORDER_PRODUCTS_INFO, {
    variables: { id: chosenOrderId },
    fetchPolicy: 'network-only',
  });

  const [updateOrder] = useMutation(UPDATE_ORDER, {
    onError: graphqlErrorMessageAlert,
    onCompleted: () => {
      showMessage('success', 'Update Order Information successfully.');
      handleCancel();
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: GET_ORDER_LOG, variables: { id: chosenOrderId } },
      { query: GET_ORDER_PRODUCTS_INFO, variables: { id: chosenOrderId } },
    ],
  });

  const renderResult = () => {
    if (loading) return <Loading />;

    if (error) {
      return (
        <ErrorResult
          title={graphqlErrorHandler(error)}
          extra={
            <Button type="primary" onClick={() => refetch()}>
              {t('common.retry')}
            </Button>
          }
        />
      );
    }

    if (data) {
      const orderInfoFromLuniu = data.order.partner_order_detail;

      if (!_.isEmpty(orderInfoFromLuniu)) {
        const compareResult: {
          isSame: boolean;
          fields: Array<{
            field: string;
            old: number | string;
            new: number | string;
          }>;
        } = {
          fields: orderInfoFromLuniu.order_diff.fields,
          isSame: orderInfoFromLuniu.order_diff.fields.length === 0,
        };

        const renderWarningResult = () => {
          if (compareResult !== null && !compareResult.isSame) {
            const handleOverwriteValue = () => {
              if (compareResult !== null && compareResult?.fields.length > 0) {
                const updateInfo: { [key: string]: any } = {};
                compareResult.fields.forEach((field) => {
                  if (
                    field.field !== 'address' &&
                    field.field !== 'longitude' &&
                    field.field !== 'latitude'
                  ) {
                    updateInfo[field.field] = field.new;
                  }
                  updateInfo[field.field] = field.new;
                });

                updateOrder({
                  variables: {
                    id: chosenOrderId,
                    input: updateInfo,
                  },
                });
              }
            };

            return (
              <>
                <Alert
                  message={t('order.productModal.valueInconsistentMsg')}
                  type="warning"
                  showIcon
                />
                <Card>
                  <OrderFieldCompareTable fields={compareResult.fields} />
                  <Space
                    direction="vertical"
                    align="end"
                    style={{ width: '100%' }}
                  >
                    <Popconfirm
                      title={t('order.productModal.overwritePrompt')}
                      onConfirm={handleOverwriteValue}
                      okText={t('common.yes')}
                      cancelText={t('common.no')}
                    >
                      <Button>{t('order.productModal.overwrite')}</Button>
                    </Popconfirm>
                    {compareResult.fields.length > 0 && (
                      <Alert
                        message={t('order.productModal.overwriteTip')}
                        type="info"
                        showIcon
                      />
                    )}
                  </Space>
                </Card>
              </>
            );
          } else {
            return <></>;
          }
        };

        return (
          <Space direction="vertical" style={{ width: '100%' }}>
            {renderWarningResult()}
            <OrderProductList data={orderInfoFromLuniu.goods_list.goods} />
          </Space>
        );
      } else {
        return (
          <Result
            status="404"
            title={t('order.productModal.orderDataNotFound')}
          />
        );
      }
    }

    return <></>;
  };

  const handleCancel = () => {
    setShowProductModal(false);
  };

  return (
    <Modal
      title={t('order.productModal.title')}
      visible={showProductModal}
      destroyOnClose
      footer={null}
      onCancel={handleCancel}
      style={{ position: 'relative' }}
    >
      {renderResult()}
    </Modal>
  );
};

export default OrderProductModal;
