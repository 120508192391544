import { Button, Divider, Space } from 'antd'
import React from 'react'
import { StyleSheet } from '../../types'
import { changeLanguage } from '../../utils/tools'

const LanguagePicker = () => {

    return(
        <div style={styles.wrapper}>
            <Space>
                <Button type={'text'}onClick={()=>changeLanguage('en')}>
                    English
                </Button>
                <Divider type="vertical" />
                <Button type={'text'} onClick={()=>changeLanguage('zh')}>
                    中文
                </Button>
            </Space>
        </div>
    )
}

const styles: StyleSheet = {
    wrapper: {
        display: 'flex',
        justifyContent: 'center'
    }
}

export default LanguagePicker