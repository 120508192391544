import { Button, Form, Input, Modal, Space, Tooltip } from 'antd';
import React, { useState } from 'react';
import { PlusOutlined, SyncOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import {
  generateDriverActiveCode,
  graphqlErrorMessageAlert,
  showMessage,
} from '../../../utils/tools';
import Loading from '../../common/Loading';
import { useTranslation } from 'react-i18next';
import { ADD_DRIVER, DRIVER_LIST } from '../../../GraphQL/queries/driver';

const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 14,
  },
};

const AddNewDriver = () => {
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);

  const [form] = Form.useForm();

  const [addDriver, { loading }] = useMutation(ADD_DRIVER, {
    onCompleted: () => handleAddCompleted(),
    onError: graphqlErrorMessageAlert,
  });

  const handleCancel = () => {
    setShowModal(false);
    form.resetFields();
  };

  const handleAddCompleted = () => {
    handleCancel();
    showMessage('success', 'Successfully add driver.');
  };

  const onFinish = (values: any) => {
    console.log('Received values of form: ', values);
    addDriver({
      variables: {
        driverName: values.name,
        phone: values.phone,
        code: values.code,
        email: values.email,
        remark: values.remark,
      },
      update: (proxy, addDriver) => {
        const data: any = proxy.readQuery({
          query: DRIVER_LIST,
          variables: { isDeleted: false },
        });
        proxy.writeQuery({
          query: DRIVER_LIST,
          variables: { isDeleted: false },
          data: {
            ...data,
            drivers: [...data.drivers, addDriver],
          },
        });
      },
    });
  };

  const handleAddUser = () => {
    form.submit();
  };

  return (
    <>
      <Button icon={<PlusOutlined />} onClick={() => setShowModal(true)}>
        {t('driver.addNew')}
      </Button>
      <Modal
        title={t('driver.addNew')}
        visible={showModal}
        onCancel={handleCancel}
        maskClosable={false}
        footer={
          <>
            <Form.Item>
              <Button disabled={loading} onClick={handleCancel}>
                {t('common.cancel')}
              </Button>
              <Button disabled={loading} type="primary" onClick={handleAddUser}>
                {t('userList.table.actions.add')}
              </Button>
            </Form.Item>
          </>
        }
      >
        {loading ? (
          <Loading />
        ) : (
          <Form
            form={form}
            name="add-driver"
            onFinish={onFinish}
            scrollToFirstError
            {...formItemLayout}
          >
            <Form.Item
              name="name"
              label={t('driver.table.name')}
              rules={[
                {
                  required: true,
                  message: 'Please input driver name',
                },
                { min: 1 },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="phone"
              label={t('driver.table.phone')}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="email"
              label={t('driver.table.email')}
              rules={[
                {
                  type: 'email',
                  message: 'Invalid Email Format',
                },
              ]}
            >
              <Input type="email" />
            </Form.Item>
            <Form.Item
              label={t('driver.table.activeCode')}
              rules={[{ required: true }]}
            >
              <Space>
                <Form.Item
                  name="code"
                  noStyle
                  initialValue={generateDriverActiveCode()}
                >
                  <Input style={{ width: 90 }} disabled />
                </Form.Item>
                <Tooltip title={t('driver.table.actions.generateCode')}>
                  <Button
                    shape="circle"
                    type="primary"
                    icon={<SyncOutlined />}
                    onClick={() => {
                      form.setFieldsValue({ code: generateDriverActiveCode() });
                    }}
                  />
                </Tooltip>
              </Space>
            </Form.Item>
            <Form.Item
              name="remark"
              label={t('driver.table.remark')}
              initialValue=""
            >
              <Input.TextArea
                maxLength={255}
                autoSize
                placeholder="Max Length: 255"
              />
            </Form.Item>
          </Form>
        )}
      </Modal>
    </>
  );
};

export default AddNewDriver;
