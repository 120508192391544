import React from 'react';
import { Layout, Typography } from 'antd';

const { Footer: AntDesignFooter } = Layout;
const { Text } = Typography;

const Footer = () => {
  return (
    <AntDesignFooter
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Text>
        Copyright © {new Date().getFullYear()} Luniu Mall | Ecological Park
        Agriculture Inc
      </Text>
      <Text>Version: 0.0.29</Text>
    </AntDesignFooter>
  );
};

export default Footer;
