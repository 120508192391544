import { Empty } from 'antd';
import React from 'react';
import { StyleSheet } from '../../types';

type EmptyResultProps = {
  extra?: React.ReactNode;
};

const EmptyResult = ({ extra }: EmptyResultProps) => {
  return (
    <div style={styles.container}>
      <Empty>{extra}</Empty>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

export default EmptyResult;
