import { Button, Form, Input, Modal, Typography } from 'antd';
import React, { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { useForm } from 'antd/lib/form/Form';

import { useTranslation } from 'react-i18next';
import AddressAutoComplete from '../routes/AddressAutoComplete';
import { useMutation } from '@apollo/client';
import { ADD_ADDRESS, GET_ADDRESSES } from '../../../GraphQL/queries/address';
import { graphqlErrorMessageAlert, showMessage } from '../../../utils/tools';
import Loading from '../../common/Loading';

const { Text } = Typography;

const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 14,
  },
};

const AddNewAddress = () => {
  const [form] = useForm();
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [location, setLocation] = useState({ lng: 0, lat: 0 });

  const [addAddress, { loading }] = useMutation(ADD_ADDRESS, {
    onCompleted: () => handleAddCompleted(),
    onError: graphqlErrorMessageAlert,
    refetchQueries: [{ query: GET_ADDRESSES, variables: { isDeleted: false } }],
  });

  const handleOk = () => {
    form.submit();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleAddCompleted = () => {
    handleCancel();
    showMessage('success', 'Successfully add address.');
  };

  const onFinish = (values: any) => {
    console.log('Received values of form: ', values);
    addAddress({
      variables: {
        name: values.addressInfo.name,
        longitude: values.addressInfo.lng,
        latitude: values.addressInfo.lat,
        label: values.label ? values.label : null,
      },
    });
  };

  const handleFormValueOnChange = (changedValues: any, values: any) => {
    if (changedValues && changedValues.addressInfo !== undefined) {
      setLocation({
        lat: changedValues.addressInfo.lat,
        lng: changedValues.addressInfo.lng,
      });
    }
  };

  const handleOpenModal = () => {
    setIsModalVisible(true);
    form.setFieldsValue({
      label: '',
    });
  };

  return (
    <>
      <Button icon={<PlusOutlined />} onClick={handleOpenModal}>
        {t('addressBook.addNew')}
      </Button>
      <Modal
        title={t('addressBook.addNew')}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={t('addressBook.addNew')}
        cancelText={t('common.cancel')}
      >
        {loading ? (
          <Loading />
        ) : (
          <Form
            form={form}
            name="create-address"
            onFinish={onFinish}
            scrollToFirstError
            {...formItemLayout}
            onValuesChange={handleFormValueOnChange}
          >
            <Form.Item
              name="addressInfo"
              label={t('addressBook.table.addressName')}
              rules={[{ required: true }]}
            >
              <AddressAutoComplete />
            </Form.Item>

            <Form.Item name="label" label={t('addressBook.table.label')}>
              <Input />
            </Form.Item>
            <Form.Item
              // name="address-info"
              label={t('addressBook.table.location')}
            >
              <Text>{`${location.lng}, ${location.lat}`}</Text>
            </Form.Item>
          </Form>
        )}
      </Modal>
    </>
  );
};

export default AddNewAddress;
