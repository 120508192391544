import { useMutation } from '@apollo/client';
import { Button, Form, Input, InputNumber, Modal, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { UPDATE_VEHICLE } from '../../../GraphQL/queries/vehicle';
import {
    graphqlErrorMessageAlert,
    showMessage
} from '../../../utils/tools';

type EditVehicleFormProps = {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  editingVehicle: {
    id: string;
    vehicle_name: string;
    vehicle_plate: string;
    vehicle_type: number;
    fuel_base: number;
    fuel_subsidy: number;
  };
};

const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 14,
  },
};

const EditVehicleForm = ({
  showModal,
  setShowModal,
  editingVehicle,
}: EditVehicleFormProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [updateVehicle] = useMutation(UPDATE_VEHICLE, {
    onError: graphqlErrorMessageAlert,
    onCompleted: () => {
      handleCancel();
      showMessage('success', 'Update vehicle information successfully.');
    },
  });

  const handleCancel = () => {
    form.resetFields();
    setShowModal(false);
  };

  const onFinish = (values: any) => {
    updateVehicle({
      variables: {
        id: editingVehicle.id,
        input: {
            vehicle_name: values.vehicle_name,
            vehicle_plate: values.vehicle_plate,
            vehicle_type: values.vehicle_type,
            fuel_base: values.fuel_base,
            fuel_subsidy: values.fuel_subsidy,
        },
      },
    });
  };

  return (
    <Modal
      title={t('vehicles.editVehicle')}
      visible={showModal}
      onCancel={handleCancel}
      afterClose={() => {
        form.resetFields();
      }}
      destroyOnClose={true}
      maskClosable={false}
      footer={
        <Form.Item>
          <Button onClick={handleCancel}>{t('common.cancel')}</Button>
          <Button
            type="primary"
            onClick={() => {
              form.submit();
            }}
          >
            {t('common.save')}
          </Button>
        </Form.Item>
      }
    >
      <Form
        form={form}
        name="edit-vehicle"
        onFinish={onFinish}
        scrollToFirstError
        {...formItemLayout}
      >
        <Form.Item
          name="vehicle_name"
          label={t('vehicles.table.vehicleName')}
          initialValue={editingVehicle.vehicle_name}
          rules={[
            {
              required: true,
              message: 'Please input vehicle name!',
              whitespace: true,
            },
            { min: 1 },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="vehicle_plate"
          label={t('vehicles.table.vehiclePlate')}
          initialValue={editingVehicle.vehicle_plate}
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="vehicle_type"
          label={t('vehicles.table.vehicleType')}
          initialValue={editingVehicle.vehicle_type}
          rules={[{ required: true}]}
        >
            <Select>
                <Select.Option value={0}>
                    {t('vehicles.vehicleType.companyVehicle')}
                </Select.Option>
                <Select.Option value={1}>
                    {t('vehicles.vehicleType.ownVehicle')}
                </Select.Option>
            </Select>
        </Form.Item>
        <Form.Item
            name="fuel_base"
            label={t('vehicles.table.fuelBase')}
            initialValue={editingVehicle.fuel_base}
            rules={[
            {
                required: true,
                message: 'Please input fuel base!',
            },
            ]}
        >
          <InputNumber  />
        </Form.Item>
        <Form.Item
            name="fuel_subsidy"
            label={t('vehicles.table.fuelSubsidy')}
            initialValue={editingVehicle.fuel_subsidy}
            rules={[
            {
                required: true,
                message: 'Please input fuel subsidy!',
            },
            ]}
        >
          <InputNumber  />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditVehicleForm;
