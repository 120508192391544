import { useMutation } from '@apollo/client';
import { Badge, Button, Collapse, Input, Popconfirm, Space, Table } from 'antd';
import Column from 'antd/lib/table/Column';
import dayjs from 'dayjs';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DELETE_ORDERS } from '../../../../../GraphQL/queries/order';
import { GET_ROUTE } from '../../../../../GraphQL/queries/route';
import { Order } from '../../../../../types';
import {
  graphqlErrorMessageAlert,
  orderStatusColor,
  showMessage,
} from '../../../../../utils/tools';
import OrderStatus from '../../../orders/OrderStatus';
import OrderType from '../../../orders/OrderType';
import { SearchOutlined } from '@ant-design/icons';
import OrderBuildingType from '../../../orders/OrderBuildingType';
import OrderLogModal from '../../../orders/OrderLogModal';
import OrderProductModal from '../../../orders/OrderProductModal';
import RouteSelectorModal from './RouteSelectorModal';
import DriverRemarkModal from '../../../orders/DriverRemarkModal';
import EditOrderForm from '../../../orders/EditOrderForm';
import RouteAdvancedSearchFilter from '../../RouteAdvancedSearchFilter';

const RouteOrderTable = ({
  orders,
  route_id,
  setLoadingMap,
}: {
  orders: Order[];
  route_id: string;
  setLoadingMap: Function;
}) => {
  const { t } = useTranslation();

  const [selectedOrders, setSelectedOrders] = useState<string[]>([]);
  const [displayOrders, setDisplayOrders] = useState(orders);
  const [searchText, setSearchText] = useState('');

  //Log Modal State
  const [showLogModal, setShowLogModal] = useState(false);
  const [chosenOrderId, setChosenOrderId] = useState(-1);

  //Product Modal State
  const [showProductModal, setShowProductModal] = useState(false);
  const [orderBasicInfo, setOrderBasicInfo] = useState({});

  //Driver remark Modal State
  const [showDriverRemarkModal, setShowDriverRemarkModal] = useState(false);
  const [selectedDriverRemark, setSelectedDriverRemark] = useState<{
    remark: string;
    images: string[];
  }>({ remark: '', images: [] });

  //Order Edit Modal State
  const [showEditModal, setShowEditModal] = useState(false);
  const [editingOrder, setEditingOrder] = useState<Order | null>(null);

  const [deleteOrders] = useMutation(DELETE_ORDERS, {
    onCompleted: () => handleDeleteCompleted(),
    onError: graphqlErrorMessageAlert,
    awaitRefetchQueries: true,
    refetchQueries: [{ query: GET_ROUTE, variables: { id: route_id } }],
  });

  const handleDeleteCompleted = () => {
    showMessage('success', 'Successfully deleted drivers.');
    setSelectedOrders([]);
  };

  const handleDeleteConfirm = async () => {
    setLoadingMap(true);
    await deleteOrders({
      variables: {
        orders: selectedOrders,
      },
    });
    setLoadingMap(false);
  };

  const onSelectChange = (selectedRowKeys: any) => {
    setSelectedOrders(selectedRowKeys);
  };

  const rowSelection = {
    selectedOrders,
    onChange: onSelectChange,
  };

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  useEffect(() => {
    const filteredOrders = orders.filter(
      (order) =>
        order.order_sn.includes(searchText) ||
        order.receiver.toLowerCase().includes(searchText.toLowerCase()) ||
        order.phone.includes(searchText) ||
        order.address.toLowerCase().includes(searchText.toLowerCase())
    );
    setDisplayOrders(filteredOrders);
  }, [searchText, orders]);

  const resetList = () => {
    setSearchText('');
    setDisplayOrders(orders);
  };

  const handleViewProducts = (
    orderId: number,
    basicInfo: {
      receiver: string;
      phone: number;
      latitude: string;
      longitude: string;
      buzz: string;
      unit: string;
      address: string;
    }
  ) => {
    setChosenOrderId(orderId);
    setOrderBasicInfo(basicInfo);
    setShowProductModal(true);
  };

  const handleShowDriverRemark = (remark = '', images: string[]) => {
    //Init driver remark state
    setSelectedDriverRemark({ remark: '', images: [] });

    //Open modal
    setShowDriverRemarkModal(true);

    setSelectedDriverRemark({ remark, images });
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '.5rem',
        }}
      >
        <Space>
          {selectedOrders.length > 0 && (
            <>
              <Popconfirm
                title={t('order.actions.deleteOrdersPromptMsg', {
                  amount: selectedOrders.length,
                })}
                onConfirm={() => handleDeleteConfirm()}
                onCancel={() => {}}
                okText={t('common.yes')}
                cancelText={t('common.no')}
                disabled={selectedOrders.length < 1}
              >
                <Button>{t('common.delete')}</Button>
              </Popconfirm>
              <RouteSelectorModal
                ordersId={selectedOrders}
                currentRouteId={route_id}
              />
            </>
          )}
        </Space>

        <Space
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: '.5rem',
          }}
        >
          <Input
            onChange={onSearch}
            prefix={<SearchOutlined />}
            value={searchText}
            placeholder={t('order.searchBySnNamePhone')}
          />
          <RouteAdvancedSearchFilter
            orders={orders}
            setDisplayOrders={setDisplayOrders}
          />
          <Button onClick={resetList}>{t('common.reset')}</Button>
        </Space>
      </div>

      <Table
        rowSelection={rowSelection}
        sticky
        dataSource={displayOrders}
        rowKey={(record: Order) => record.id}
        scroll={{ x: 1300 }}
        pagination={{ showSizeChanger: true, defaultPageSize: 50 }}
      >
        <Column
          dataIndex={'sequence_label'}
          render={(sequence_label: string, record: Order) => (
            <Badge
              count={sequence_label}
              style={{ backgroundColor: orderStatusColor(record.status) }}
              showZero
              overflowCount={999}
            />
          )}
          fixed="left"
          width={50}
        />
        <Column
          title={t('route.routeDetail.routeOrderTable.type')}
          dataIndex={'type'}
          render={(type) => <OrderType type={type} />}
          fixed="left"
          width={80}
        />
        <Column
          title={t('route.routeDetail.routeOrderTable.orderNumber')}
          dataIndex={'order_sn'}
          fixed="left"
          width={180}
        />
        <Column
          title={t('route.routeDetail.routeOrderTable.store')}
          dataIndex={'store'}
          render={(store) => <>{store.store_name}</>}
          width={120}
        />
        <Column
          title={t('route.routeDetail.routeOrderTable.receiver')}
          dataIndex={'receiver'}
          width={120}
        />
        <Column
          title={t('route.routeDetail.routeOrderTable.address')}
          dataIndex={'address'}
          width={200}
        />
        <Column
          title={t('route.routeDetail.routeOrderTable.phone')}
          dataIndex={'phone'}
          width={120}
        />
        <Column
          title={t('route.routeDetail.routeOrderTable.status')}
          dataIndex={'status'}
          render={(status) => <OrderStatus status={status} />}
          width={120}
        />

        <Column
          title={t('route.routeDetail.routeOrderTable.arrival')}
          dataIndex={'arrival'}
          render={(arrival) => arrival + 'm'}
          width={100}
        />
        <Column
          title={t('route.routeDetail.routeOrderTable.distance')}
          dataIndex={'distance'}
          width={100}
        />
        <Column
          title={t('route.routeDetail.routeOrderTable.remark')}
          dataIndex={'remark'}
          width={200}
          render={(remark) => {
            return remark ? (
              <Collapse ghost>
                <Collapse.Panel header={'Remark'} key="1">
                  <div style={{ width: '8vw' }}>{remark}</div>
                </Collapse.Panel>
              </Collapse>
            ) : (
              <div style={{ width: '10vw' }}>{}</div>
            );
          }}
        />
        <Column
          title={t('route.routeDetail.routeOrderTable.buildingType.title')}
          dataIndex={'building_type'}
          render={(buildingType) => <OrderBuildingType type={buildingType} />}
          width={120}
        />
        <Column title="Unit" dataIndex={'unit'} width={80} />
        <Column title="Buzz" dataIndex={'buzz'} width={80} />
        <Column
          title={t('route.routeDetail.routeOrderTable.lastUpdate')}
          dataIndex="update_at"
          render={(date) => {
            return (
              <>
                {dayjs(date).valueOf() > 0
                  ? dayjs(date).format('YYYY/MM/DD HH:mm')
                  : 'N/A'}
              </>
            );
          }}
          width={120}
        />
        <Column
          title={t('route.routeDetail.routeOrderTable.createdAt')}
          dataIndex={'created_at'}
          render={(date) => {
            return <>{dayjs(date).format('YYYY/MM/DD HH:mm')} </>;
          }}
          width={120}
        />
        <Column
          title={t('driver.table.actions.actions')}
          fixed="right"
          width={180}
          render={(record: any, index: number) => {
            return (
              <Space key={index} direction="vertical">
                <Space>
                  <Button
                    size="small"
                    onClick={() => {
                      setChosenOrderId(record.id);
                      setTimeout(() => {
                        setShowLogModal(true);
                      }, 1);
                    }}
                  >
                    {t('order.log.title')}
                  </Button>
                  <Button
                    size="small"
                    onClick={() => {
                      handleViewProducts(record.id, {
                        receiver: record.receiver,
                        phone: record.phone,
                        latitude: record.latitude,
                        longitude: record.longitude,
                        buzz: record.buzz,
                        unit: record.unit,
                        address: record.address,
                      });
                    }}
                  >
                    {t('order.productModal.product')}
                  </Button>
                </Space>

                <Space>
                  <Button
                    size="small"
                    onClick={() => {
                      setEditingOrder(record);
                      setTimeout(() => {
                        setShowEditModal(true);
                      }, 1);
                    }}
                  >
                    {t('common.edit')}
                  </Button>
                  {(record.driver_remark ||
                    (Array.isArray(record.order_images) &&
                      record.order_images.length > 0)) && (
                    <Badge dot>
                      <Button
                        size="small"
                        onClick={() => {
                          handleShowDriverRemark(
                            record.driver_remark,
                            record.order_images.map((img: any) => img.img_url)
                          );
                        }}
                      >
                        {t('order.driverRemark')}
                      </Button>
                    </Badge>
                  )}
                </Space>
              </Space>
            );
          }}
        />
      </Table>
      {showLogModal && chosenOrderId !== -1 && (
        <OrderLogModal
          showLogModal={showLogModal}
          setShowLogModal={setShowLogModal}
          chosenOrderId={chosenOrderId}
        />
      )}
      {showProductModal && chosenOrderId !== -1 && (
        <OrderProductModal
          showProductModal={showProductModal}
          setShowProductModal={setShowProductModal}
          chosenOrderId={chosenOrderId}
          orderBasicInfo={orderBasicInfo}
        />
      )}
      {showDriverRemarkModal && (
        <DriverRemarkModal
          setShowDriverModal={setShowDriverRemarkModal}
          showDriverModal={showDriverRemarkModal}
          remark={selectedDriverRemark.remark}
          images={selectedDriverRemark.images}
        />
      )}
      {showEditModal && editingOrder !== null && (
        <EditOrderForm
          showModal={showEditModal}
          setShowModal={setShowEditModal}
          setLoadingMap={setLoadingMap}
          editingOrder={editingOrder}
          route_id={route_id}
        />
      )}
    </>
  );
};

export default RouteOrderTable;
