import { useQuery } from '@apollo/client';
import { Typography } from 'antd';
import Modal from 'antd/lib/modal';
import React from 'react';
import { GET_ORDER_LOG } from '../../../GraphQL/queries/order';
import Loading from '../../common/Loading';
import { useTranslation } from 'react-i18next';
import { graphqlErrorHandler } from '../../../utils/tools';
import LogList from './LogList';

const { Text } = Typography;

type OrderLogModelProps = {
  showLogModal: boolean;
  setShowLogModal: React.Dispatch<React.SetStateAction<boolean>>;
  chosenOrderId: number | string;
};

const OrderLogModal = ({
  showLogModal,
  setShowLogModal,
  chosenOrderId,
}: OrderLogModelProps) => {
  const { t } = useTranslation();
  const { loading, data, error, refetch } = useQuery(GET_ORDER_LOG, {
    variables: { id: chosenOrderId, onlyCustomerLog: false },
    fetchPolicy: 'no-cache',
  });

  const handleCancel = () => {
    setShowLogModal(false);
    // refetch();
  };

  const renderLogList = () => {
    if (loading) {
      return <Loading />;
    }

    if (data && data.orderLogs) {
      return <LogList orderLogs={data.orderLogs} logType="order" />;
    }

    if (error) {
      return <Text type="danger">{graphqlErrorHandler(error)}</Text>;
    }

    return <></>;
  };

  return (
    <Modal
      title={t('order.log.title')}
      visible={showLogModal}
      destroyOnClose
      footer={null}
      onCancel={handleCancel}
      afterClose={() => refetch()}
    >
      {renderLogList()}
    </Modal>
  );
};

export default OrderLogModal;
