import { useQuery } from '@apollo/client';
import { Button } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import EmptyResult from '../../components/common/EmptyResult';
import ErrorResult from '../../components/common/ErrorResult';
import Loading from '../../components/common/Loading';
import AddNewUser from '../../components/dashboard/users/AddNewUser';
import UserList from '../../components/dashboard/users/UserList';
import { USER_LIST } from '../../GraphQL/queries/user';
import ViewBasicLayout from '../../layout/ViewBasicLayout';
import { graphqlErrorHandler } from '../../utils/tools';

const Users = () => {
  const { t } = useTranslation();
  const { data, error, loading, refetch } = useQuery(USER_LIST);

  const handleFetchUserList = () => {
    refetch();
  };

  if (loading) return <Loading />;

  if (error)
    return (
      <ErrorResult
        title={graphqlErrorHandler(error)}
        extra={
          <Button type="primary" onClick={handleFetchUserList}>
            {t('common.retry')}
          </Button>
        }
      />
    );

  if (data.users)
    return (
      <ViewBasicLayout title={t('userList.title')} header={<AddNewUser />}>
        <UserList users={data.users} />
      </ViewBasicLayout>
    );

  return (
    <EmptyResult
      extra={
        <Button type="primary" onClick={handleFetchUserList}>
          {t('common.retry')}
        </Button>
      }
    />
  );
};

export default Users;
