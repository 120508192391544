import { Button, Input, Space } from 'antd';
import React, { useState } from 'react';
import { EditOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { UPDATE_ADDRESS } from '../../../GraphQL/queries/address';
import { graphqlErrorMessageAlert, showMessage } from '../../../utils/tools';

type AddressLabelEditorProps = {
  label: string;
  id: string;
};

const AddressLabelEditor = ({ label, id }: AddressLabelEditorProps) => {
  const { t } = useTranslation();

  const [value, setValue] = useState(label);
  const [IsEdit, setIsEdit] = useState(false);

  const [updateLabel] = useMutation(UPDATE_ADDRESS, {
    onCompleted: () => handleAddCompleted(),
    onError: graphqlErrorMessageAlert,
  });

  const triggerEditMode = () => {
    setIsEdit((prev) => !prev);
  };

  const handleInputText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const handleAddCompleted = () => {
    triggerEditMode();
    showMessage('success', 'Successfully edit address.');
  };

  const handleEditSave = () => {
    updateLabel({
      variables: {
        id: id,
        input: {
          label: value,
        },
      },
    });
  };

  return !IsEdit ? (
    <>
      {label}
      <Button type="text" onClick={triggerEditMode}>
        <EditOutlined />
      </Button>
    </>
  ) : (
    <Space>
      <Input value={value} onChange={handleInputText} allowClear />
      <Button onClick={handleEditSave} type="primary">
        {t('common.save')}
      </Button>
      <Button onClick={triggerEditMode}>{t('common.cancel')}</Button>
    </Space>
  );
};

export default AddressLabelEditor;
