import { useUpdateEffect } from 'ahooks';
import React, { useState } from 'react';
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from 'react-google-places-autocomplete';

type AddressValue = {
  name?: string;
  lat?: number;
  lng?: number;
};

type AddressAutoCompleteProps = {
  value?: AddressValue;
  onChange?: (value: AddressValue) => void;
};

const AddressAutoComplete = ({ value, onChange }: AddressAutoCompleteProps) => {
  const [addressValue, setAddressValue] = useState<{ [key: string]: any }>({});

  useUpdateEffect(() => {
    let isSubscribe = true;

    if (Object.keys(addressValue).length > 0) {
      if (addressValue.label && addressValue.value.place_id) {
        (async () => {
          const result = await geocodeByPlaceId(addressValue.value.place_id);
          if (result.length > 0 && onChange && isSubscribe) {
            onChange({
              name: addressValue.label,
              lat: result[0].geometry.location.lat() || 0,
              lng: result[0].geometry.location.lng() || 0,
            });
          }
        })();
      }
    }

    return () => {
      isSubscribe = false;
    };
  }, [addressValue]);

  return (
    <GooglePlacesAutocomplete
      apiKey={`AIzaSyBCP-_JxqF5BbtHgxtnSxftjzn38-hjs5Y&language=en`}
      minLengthAutocomplete={3}
      autocompletionRequest={{
        componentRestrictions: {
          country: ['ca'],
        },
      }}
      onLoadFailed={(error) => console.log('Error about auto complete', error)}
      selectProps={{
        addressValue,
        onChange: setAddressValue,
      }}
    />
  );
};

export default AddressAutoComplete;
