import { useQuery } from '@apollo/client';
import {
  Alert,
  Button,
  Card,
  Divider,
  Form,
  Select,
  Space,
  Typography,
} from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GET_ADDRESSES } from '../../../../GraphQL/queries/address';
import { Address } from '../../../../types';
import { graphqlErrorHandler, showMessage } from '../../../../utils/tools';
import ErrorResult from '../../../common/ErrorResult';
import Loading from '../../../common/Loading';

const { Option } = Select;
const { Text } = Typography;

const formItemLayout = {
  wrapperCol: {
    span: 20,
  },
};

const DefaultRouteAddress = () => {
  const { t } = useTranslation();
  const [form] = useForm();

  const [defaultAddressesName, setDefaultAddressName] = useState({
    start: 'None',
    end: 'None',
  });

  const displayCurrentDefaultPoints = (data: any) => {
    //get local storage info
    const defaultAddressPointsString = localStorage.getItem(
      '@storage_defaultAddressPoints'
    );
    if (defaultAddressPointsString) {
      const defaultAddressPoints = JSON.parse(atob(defaultAddressPointsString));
      if (
        'startPoint' in defaultAddressPoints &&
        'endPoint' in defaultAddressPoints
      )
        data.addresses.forEach((address: Address) => {
          if (defaultAddressPoints.startPoint === address.id) {
            setDefaultAddressName((prev) => {
              return {
                ...prev,
                start: address.label
                  ? `${address.label} - ${address.name}`
                  : address.name,
              };
            });
          }
          if (defaultAddressPoints.endPoint === address.id) {
            setDefaultAddressName((prev) => {
              return {
                ...prev,
                end: address.label
                  ? `${address.label} - ${address.name}`
                  : address.name,
              };
            });
          }
        });
    }
  };

  //Address Selector
  const {
    data: addressList,
    error,
    loading,
    refetch,
  } = useQuery(GET_ADDRESSES, {
    variables: { isDeleted: false },
    onCompleted: displayCurrentDefaultPoints,
  });

  const onFinish = (values: any) => {
    console.log('Received values of form: ', values);
    localStorage.setItem(
      '@storage_defaultAddressPoints',
      btoa(JSON.stringify(values))
    );
    showMessage('success', 'Save successfully');

    if (addressList && addressList.addresses) {
      displayCurrentDefaultPoints(addressList);
    }
  };

  const renderForm = () => {
    if (loading) return <Loading />;

    if (error)
      return (
        <ErrorResult
          title={graphqlErrorHandler(error)}
          extra={
            <Button type="primary" onClick={() => refetch()}>
              {t('common.retry')}
            </Button>
          }
        />
      );

    if (addressList && addressList.addresses) {
      return (
        <>
          <Form
            form={form}
            name="default-address-setting"
            onFinish={onFinish}
            scrollToFirstError
            {...formItemLayout}
          >
            <Form.Item
              name="startPoint"
              label={t(
                'settings.preference.route.defaultPoints.setDefaultStartPoint'
              )}
              rules={[{ required: true }]}
            >
              <Select
                showSearch
                placeholder="Select an address"
                optionFilterProp="children"
                filterOption={(input, option: any) =>
                  option.children
                    .toLocaleLowerCase()
                    .includes(input.toLocaleLowerCase())
                }
              >
                {addressList &&
                  addressList.addresses.map((item: Address) => {
                    return (
                      <Option key={item.id} value={item.id}>
                        {item.label
                          ? `${item.label} - ${item.name}`
                          : item.name}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
            <Form.Item
              name="endPoint"
              label={t(
                'settings.preference.route.defaultPoints.setDefaultEndPoint'
              )}
              rules={[{ required: true }]}
            >
              <Select
                showSearch
                placeholder="Select an address"
                optionFilterProp="children"
                filterOption={(input, option: any) =>
                  option.children
                    .toLocaleLowerCase()
                    .includes(input.toLocaleLowerCase())
                }
              >
                {addressList &&
                  addressList.addresses.map((item: Address) => {
                    return (
                      <Option key={item.id} value={item.id}>
                        {item.label
                          ? `${item.label} - ${item.name}`
                          : item.name}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                {t('common.save')}
              </Button>
            </Form.Item>
          </Form>
          <Alert
            message={t('settings.preference.route.defaultPoints.cacheTip')}
            type="warning"
            showIcon
          />
        </>
      );
    }

    return <p>Something went wrong here</p>;
  };

  return (
    <Card title={t('settings.preference.route.title')} style={{ width: '40%' }}>
      <Space direction="vertical">
        <Text>
          {t('settings.preference.route.defaultPoints.defaultStartPoint')}:{' '}
          {defaultAddressesName.start}
        </Text>
        <Text>
          {t('settings.preference.route.defaultPoints.defaultEndPoint')}:{' '}
          {defaultAddressesName.end}
        </Text>
      </Space>
      <Divider />
      {renderForm()}
    </Card>
  );
};

export default DefaultRouteAddress;
