import { useTranslation } from 'react-i18next';
import ViewBasicLayout from '../../layout/ViewBasicLayout';
import { useQuery } from '@apollo/client';
import { GET_STORES } from '../../GraphQL/queries/store';
import EmptyResult from '../../components/common/EmptyResult';
import { Button } from 'antd';
import Loading from '../../components/common/Loading';
import ErrorResult from '../../components/common/ErrorResult';
import { graphqlErrorHandler } from '../../utils/tools';
import StoreList from '../../components/dashboard/stores/StoreList';
import AddNewStore from '../../components/dashboard/stores/AddNewStore';

const Stores = () => {
  const { t } = useTranslation();
  //Get store list
  const { data, error, loading, refetch } = useQuery(GET_STORES);

  const handleFetchStoreList = () => {
    refetch();
  };

  if (loading) return <Loading />;

  if (error) {
    return (
      <ErrorResult
        title={graphqlErrorHandler(error)}
        extra={
          <Button type="primary" onClick={handleFetchStoreList}>
            {t('common.retry')}
          </Button>
        }
      />
    );
  }

  if (data.stores) {
    return (
      <ViewBasicLayout title={t('stores.title')} header={<AddNewStore />}>
        <StoreList stores={data.stores} />
      </ViewBasicLayout>
    );
  }

  return (
    <EmptyResult
      extra={
        <Button type="primary" onClick={handleFetchStoreList}>
          {t('common.retry')}
        </Button>
      }
    />
  );
};

export default Stores;
