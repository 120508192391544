import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Sidebar from '../../components/dashboard/Sidebar';
import { DashboardRoute, StyleSheet } from '../../types';
import { Layout } from 'antd';
import Footer from './Footer';
import Header from './Header';

const { Sider, Content } = Layout;

const DashboardLayout = ({ routes }: { routes: DashboardRoute[] }) => {
  return (
    <Layout style={styles.wrapper}>
      <Header />
      <Layout>
        <Sider style={styles.sider} collapsible={false} theme="light">
          <Sidebar routes={routes} />
        </Sider>
        <Layout>
          <Content style={styles.content}>
            <Switch>
              {routes.map(({ path, component, subMenu = [] }, i) => {
                if (subMenu.length > 0) {
                  return (
                    <Route key={i} path={path}>
                      <Switch>
                        {subMenu.map((menu, index) => (
                          <Route
                            exact
                            key={index}
                            path={path + menu.path}
                            component={menu.component}
                          />
                        ))}
                      </Switch>
                    </Route>
                  );
                }

                return (
                  <Route exact key={i} path={path} component={component} />
                );
              })}
              <Redirect to={routes[0].path} />
            </Switch>
            <Footer />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

const styles: StyleSheet = {
  wrapper: {
    width: '100%',
    height: '100%',
  },
  header: {
    zIndex: 10,
    backgroundColor: 'white',
    WebkitBoxShadow: '0 2px 8px #f0f1f2',
    boxShadow: '0 2px 8px #f0f1f2',
  },
  sider: {
    backgroundColor: 'white',
    padding: '24px 0',
  },
  content: {
    overflow: 'auto',
  },
  footer: {
    backgroundColor: 'white',
  },
};

export default DashboardLayout;
