import React, { useState, useEffect } from 'react';
import { Order, StyleSheet } from '../../../../../types';
import { Spin } from 'antd';
import RouteMapboxMap from './RouteMapboxMap';
import OrderDetailCard from './OrderDetailCard';
import DragRouteAddressList from './DragRouteAddressList';
import { useTranslation } from 'react-i18next';
import RouteCompareTool from './RouteCompareTool';

type Address = {
  name: string;
  latitude: number;
  longitude: number;
};

type RouteMapSectionProps = {
  route_id: string;
  orders: Order[];
  loadingMap: boolean;
  setLoadingMap: Function;
  startAddress: Address;
  endAddress: Address;
  geoJson?: object[];
  driverLocationInfo?: {
    latitude: number;
    longitude: number;
    location_update_at: number;
  };
  routeCompareData: Array<{
    id: string;
    name: string;
    geoJson: object[];
    orders: Order[];
  }>;
  setRouteCompareData: React.Dispatch<
    React.SetStateAction<
      {
        id: string;
        name: string;
        geoJson: object[];
        orders: Order[];
      }[]
    >
  >;
  shippingDate: string;
  refetch: Function;
};

const RouteMapSection = ({
  route_id,
  orders,
  loadingMap,
  setLoadingMap,
  startAddress,
  endAddress,
  geoJson,
  driverLocationInfo,
  routeCompareData,
  setRouteCompareData,
  shippingDate,
  refetch,
}: RouteMapSectionProps) => {
  const { t } = useTranslation();
  const [selectedOrder, setSelectedOrder] = useState<Order>();

  const handleMarkerClick = (order: Order) => {
    setSelectedOrder(order);
  };

  useEffect(() => {
    if (orders.length === 0) {
      setSelectedOrder(undefined);
    }
  }, [orders]);

  return (
    <Spin spinning={loadingMap} size={'large'} tip={t('common.updating')}>
      <div style={styles.containerStyle}>
        <DragRouteAddressList
          orders={orders}
          route_id={route_id}
          setLoadingMap={setLoadingMap}
          setSelectedOrder={setSelectedOrder}
          refetch={refetch}
        />
        <div style={{ width: '80%', position: 'relative' }}>
          <RouteMapboxMap
            geoJson={geoJson}
            startAddress={startAddress}
            endAddress={endAddress}
            orders={orders}
            handleMarkerClick={handleMarkerClick}
            driverLocationInfo={driverLocationInfo}
            selectedOrder={selectedOrder}
            routeCompareData={routeCompareData}
          />
          {orders.length > 0 && selectedOrder && (
            <OrderDetailCard
              route_id={route_id}
              selectedOrder={selectedOrder}
              setSelectedOrder={setSelectedOrder}
              setLoadingMap={setLoadingMap}
            />
          )}
          <RouteCompareTool
            currentRouteId={route_id}
            setRouteCompareData={setRouteCompareData}
            shippingDate={shippingDate}
          />
        </div>
      </div>
    </Spin>
  );
};

const styles: StyleSheet = {
  containerStyle: {
    position: 'relative',
    width: '100%',
    height: '75vh',
    display: 'flex',
    flexDirection: 'row',
  },
};

export default RouteMapSection;
