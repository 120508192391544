import { useLazyQuery, useQuery } from '@apollo/client';
import { Button, Modal, Select } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GET_ROUTE, GET_ROUTES } from '../../../../../GraphQL/queries/route';
import { Order, StyleSheet } from '../../../../../types';
import { graphqlErrorMessageAlert } from '../../../../../utils/tools';
import Loading from '../../../../common/Loading';

type RouteCompareToolProps = {
  currentRouteId: string;
  setRouteCompareData: React.Dispatch<
    React.SetStateAction<
      {
        id: string;
        name: string;
        geoJson: object[];
        orders: Order[];
      }[]
    >
  >;
  shippingDate: string;
};

const { Option } = Select;

type RouteType = {
  id: string;
  name: string;
  geoJson: string;
  orders: Order[];
};

const RouteCompareTool = ({
  currentRouteId,
  setRouteCompareData,
  shippingDate,
}: RouteCompareToolProps) => {
  const { t } = useTranslation();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRouteIds, setSelectedRouteIds] = useState<string[]>([]);
  const [loadedRoute, setLoadedRoute] = useState<{
    [key: string]: RouteType[];
  }>({});

  const [loadRouteList, { loading, data }] = useLazyQuery(GET_ROUTES, {
    variables: {
      date: dayjs(shippingDate).format('YYYY-MM-DD'),
    },
    onError: graphqlErrorMessageAlert,
  });

  const { refetch } = useQuery(GET_ROUTE, {
    skip: true,
    onError: graphqlErrorMessageAlert,
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (data && Array.isArray(data.routes) && selectedRouteIds.length > 0) {
      (async () => {
        let selectedRoutes: any[] = [];
        let newRoute: { [key: string]: RouteType[] } = {};
        for (let id of selectedRouteIds) {
          // Skip route that fetched already
          if (loadedRoute[id]) {
            selectedRoutes.push(loadedRoute[id]);
            continue;
          }
          // Get route detail
          const { data } = await refetch({ id: id });
          if (data && data.route) {
            selectedRoutes.push(data.route);
            newRoute[id] = data.route;
          }
        }
        // Store the route
        JSON.stringify(newRoute) !== '{}' &&
          setLoadedRoute((prev) => ({ ...prev, ...newRoute }));
        setRouteCompareData(selectedRoutes);
      })();
    } else {
      setRouteCompareData([]);
    }
  }, [data, selectedRouteIds, setRouteCompareData, refetch, loadedRoute]);

  const showModal = () => {
    setIsModalVisible(true);
    loadRouteList();
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Button onClick={showModal} style={styles.modalShowBtn}>
        {t('route.routeDetail.routeCompare.title')}
      </Button>
      <Modal
        title={t('route.routeDetail.routeCompare.selectRoutes')}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={<></>}
      >
        {loading && <Loading />}
        {data && data.routes && (
          <Select
            mode="multiple"
            allowClear
            style={{ width: '100%' }}
            placeholder={t('route.routeDetail.routeCompare.selectRoutes')}
            onChange={setSelectedRouteIds}
            value={selectedRouteIds}
          >
            {data.routes.map((route: RouteType) => {
              return (
                <Option
                  key={route.id}
                  value={route.id}
                  disabled={currentRouteId === route.id}
                >
                  {route.name}
                </Option>
              );
            })}
          </Select>
        )}
      </Modal>
    </>
  );
};

const styles: StyleSheet = {
  modalShowBtn: {
    position: 'absolute',
    right: 10,
    top: 10,
    zIndex: 999,
  },
};

export default RouteCompareTool;
