import { Button, DatePicker, Select, Space, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import ViewBasicLayout from '../../../layout/ViewBasicLayout';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { PlusCircleOutlined } from '@ant-design/icons';
import { RootState, SimpleDriverInfo, StatisticsState, StyleSheet } from '../../../types';
import DriversPicker from '../../../components/dashboard/statistics/DriversPicker';
import DriverTableSection from '../../../components/dashboard/statistics/DriverTableSection';
import { useLazyQuery, useQuery } from '@apollo/client';
import { GET_DRIVER_STATISTICS } from '../../../GraphQL/queries/statistics';
import { graphqlErrorMessageAlert } from '../../../utils/tools';
import Loading from '../../../components/common/Loading';
import { useDispatch, useSelector } from 'react-redux';
import { StatisticsActionTypes } from '../../../store/statisticsReducer';
import { GET_STORES } from '../../../GraphQL/queries/store';

const { Text } = Typography;

const { RangePicker } = DatePicker;

const OrderStatistics = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const shippingDate = useSelector<RootState, StatisticsState['shippingDate']>(
    (state) => state.statistics.shippingDate
  );

  const storeId = useSelector<RootState, StatisticsState['storeId']>(
    (state) => state.statistics.storeId
  );

  const selectedDriversInfo = useSelector<
    RootState,
    StatisticsState['selectedDrivers']
  >((state) => state.statistics.selectedDrivers);

  const driverReportData = useSelector<
    RootState,
    StatisticsState['driverReportData']
  >((state) => state.statistics.driverReportData);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedDrivers, setSelectedDrivers] =
    useState<SimpleDriverInfo[]>(selectedDriversInfo);
  const [driverData, setDriverData] = useState(driverReportData);

  const { data: storesData } = useQuery(GET_STORES);

  const [getReport, { loading }] = useLazyQuery(GET_DRIVER_STATISTICS, {
    variables: {
      input: {
        start_date: shippingDate.start,
        end_date: shippingDate.end,
        driver_id: selectedDrivers.map((driver) => driver.driverId),
        store_id: storeId,
      },
    },
    onCompleted: (data) => {
      if (data && data.driverData) {
        setDriverData(data.driverData);
        dispatch({
          type: StatisticsActionTypes.UPDATE_REPORT_DATA,
          payload: data.driverData,
        });
      }
    },
    onError: (err) => graphqlErrorMessageAlert(err),
    fetchPolicy: 'cache-and-network',
  });

  const onChange = (_: any, dateStrings: [string, string]) => {
    dispatch({
      type: StatisticsActionTypes.UPDATE_SHIPPING_DATE,
      payload: {
        start: dateStrings[0]
          ? moment(dateStrings[0]).format('YYYY-MM-DD')
          : undefined,
        end: dateStrings[1]
          ? moment(dateStrings[1]).format('YYYY-MM-DD')
          : undefined,
      },
    });
  };

  const onSelect = (value?: number) => {
    dispatch({
      type: StatisticsActionTypes.UPDATE_STORE_ID,
      payload: value,
    });
  };

  useEffect(() => {
    if (selectedDrivers.length > 0) {
      dispatch({
        type: StatisticsActionTypes.UPDATE_DRIVERS_ID_ARRAY,
        payload: selectedDrivers,
      });
    }
  }, [selectedDrivers, dispatch]);

  useEffect(() => {
    getReport();
  }, [getReport]);

  return (
    <ViewBasicLayout title={t('statistics.title')}>
      <div style={styles.headerContainer}>
        <Space>
          <RangePicker
            ranges={{
              [`${t('datePicker.period.week')}`]: [
                moment().startOf('isoWeek'),
                moment().endOf('isoWeek'),
              ],
              [`${t('datePicker.period.firstHalfMonth')}`]: [
                moment().startOf('month'),
                moment().startOf('month').add(14, 'd'),
              ],
              [`${t('datePicker.period.secondHalfMonth')}`]: [
                moment().startOf('month').add(15, 'd'),
                moment().endOf('month'),
              ],
              [`${t('datePicker.period.month')}`]: [
                moment().startOf('month'),
                moment().endOf('month'),
              ],
            }}
            onChange={onChange}
            defaultValue={[
              moment(shippingDate.start),
              moment(shippingDate.end),
            ]}
          />
          <Select
            style={{ minWidth: 140 }}
            allowClear
            showSearch
            placeholder="Select a store"
            optionFilterProp="children"
            filterOption={(input, option: any) =>
              option.children
                .toLocaleLowerCase()
                .includes(input.toLocaleLowerCase())
            }
            onSelect={onSelect}
            onClear={() => onSelect(undefined)}
            defaultValue={storeId}
          >
            {storesData &&
              storesData.stores &&
              storesData.stores.length > 0 &&
              storesData.stores.map((store: any) => (
                <Select.Option key={store.id} value={Number(store.id)}>
                  {store.store_name}
                </Select.Option>
              ))}
          </Select>
        </Space>
        <Button
          icon={<PlusCircleOutlined />}
          size="large"
          onClick={() => setIsModalVisible(true)}
        >
          {t('statistics.selectDrivers')}
        </Button>
      </div>
      {loading ? (
        <Loading />
      ) : selectedDrivers.length > 0 ? (
        <DriverTableSection
          selectedDrivers={selectedDrivers}
          shippingDate={shippingDate}
          driverData={driverData}
        />
      ) : (
        <div style={styles.noDriverContainer}>
          <Button
            type="primary"
            shape="round"
            icon={<PlusCircleOutlined />}
            size="large"
            onClick={() => setIsModalVisible(true)}
            style={{ marginBottom: 10 }}
          >
            {t('statistics.selectDrivers')}
          </Button>
          <Text style={{ fontSize: 18 }}>{t('statistics.pickAtLeastOne')}</Text>
        </div>
      )}

      <DriversPicker
        visible={isModalVisible}
        setVisible={setIsModalVisible}
        setSelectedDrivers={setSelectedDrivers}
      />
    </ViewBasicLayout>
  );
};

const styles: StyleSheet = {
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  mainSection: {
    paddingTop: 10,
  },
  noDriverContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 10,
    minHeight: 300,
    justifyContent: 'center',
  },
};

export default OrderStatistics;
