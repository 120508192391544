import { Form, Modal, Button, Input, Select, Collapse } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { graphqlErrorMessageAlert, showMessage } from '../../../utils/tools';
import { useMutation, useQuery } from '@apollo/client';
import { Order } from '../../../types';
import { UPDATE_ORDER } from '../../../GraphQL/queries/order';
import { GET_ROUTE } from '../../../GraphQL/queries/route';
import AddressAutoComplete from '../routes/AddressAutoComplete';
import { GET_STORES } from '../../../GraphQL/queries/store';

const { Panel } = Collapse;

type EditOrderFormProps = {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  editingOrder: Order;
  setSelectedOrder?: Function;
  setLoadingMap?: Function;
  route_id: string;
};

const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 14,
  },
};

const EditOrderForm = ({
  showModal,
  setShowModal,
  editingOrder,
  setSelectedOrder,
  route_id,
  setLoadingMap,
}: EditOrderFormProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [updateOrder] = useMutation(UPDATE_ORDER, {
    onError: graphqlErrorMessageAlert,
    onCompleted: () => {
      showMessage('success', 'Update Order Information successfully.');
    },
    awaitRefetchQueries: true,
    refetchQueries: [{ query: GET_ROUTE, variables: { id: route_id } }],
  });

  const { data: storesData } = useQuery(GET_STORES);

  const handleCancel = () => {
    setShowModal(false);
    form.resetFields();
  };

  const onFinish = async (values: any) => {
    handleCancel();

    // If the form has address input, re-organize the data and use it as input.
    const hasAddressInput = values.address ? true : false;
    let organizedValue = {};
    if (hasAddressInput) {
      const { name, lat, lng } = values.address;
      organizedValue = {
        ...values,
        address: name,
        latitude: lat,
        longitude: lng,
      };
    }

    setLoadingMap && setLoadingMap(true);
    const response = await updateOrder({
      variables: {
        id: editingOrder.id,
        input: hasAddressInput ? organizedValue : values,
      },
    });
    try {
      setSelectedOrder && setSelectedOrder(response.data.updateOrder);
      setLoadingMap && setLoadingMap(false);
    } catch (err) {
      console.log(err);
      setLoadingMap && setLoadingMap(false);
    }
  };

  return (
    <Modal
      title={t('route.routeDetail.routeMapSection.editOrder')}
      visible={showModal}
      onCancel={handleCancel}
      afterClose={() => {
        form.resetFields();
      }}
      destroyOnClose={true}
      maskClosable={false}
      footer={
        <>
          <Form.Item>
            <Button onClick={handleCancel}>{t('common.cancel')}</Button>
            <Button
              type="primary"
              onClick={() => {
                form.submit();
              }}
            >
              {t('common.save')}
            </Button>
          </Form.Item>
        </>
      }
    >
      <Form
        form={form}
        name={'edit-order'}
        onFinish={onFinish}
        scrollToFirstError
        {...formItemLayout}
      >
        <Form.Item
          name="sequence_label"
          label={t('route.routeDetail.routeOrderTable.label')}
          initialValue={editingOrder.sequence_label}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="order_sn"
          label={t('route.routeDetail.routeOrderTable.orderNumber')}
          initialValue={editingOrder.order_sn}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="receiver"
          label={t('route.routeDetail.routeOrderTable.receiver')}
          initialValue={editingOrder.receiver}
          rules={[
            {
              required: true,
              whitespace: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="phone"
          label={t('route.routeDetail.routeOrderTable.phone')}
          initialValue={editingOrder.phone}
          rules={[
            {
              required: true,
              whitespace: false,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="email"
          label={t('route.routeDetail.routeOrderTable.email')}
          initialValue={editingOrder.email}
          rules={[
            {
              type: 'email',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="status"
          label={t('route.routeDetail.routeOrderTable.status')}
          initialValue={editingOrder.status}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select>
            <Select.Option value={0}>
              {t('route.routeDetail.routeOrderTable.statusTypes.preparing')}
            </Select.Option>
            <Select.Option value={1}>
              {t(
                'route.routeDetail.routeOrderTable.statusTypes.outForDelivery'
              )}
            </Select.Option>
            <Select.Option value={2}>
              {t('route.routeDetail.routeOrderTable.statusTypes.arrivingSoon')}
            </Select.Option>
            <Select.Option value={3}>
              {t('route.routeDetail.routeOrderTable.statusTypes.failed')}
            </Select.Option>
            <Select.Option value={4}>
              {t('route.routeDetail.routeOrderTable.statusTypes.delivered')}
            </Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="type"
          label={t('route.routeDetail.routeOrderTable.type')}
          initialValue={editingOrder.type}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select>
            <Select.Option value={0}>
              {t('route.routeDetail.routeOrderTable.orderTypes.delivery')}
            </Select.Option>
            <Select.Option value={1}>
              {t('route.routeDetail.routeOrderTable.orderTypes.pickup')}
            </Select.Option>
            <Select.Option value={2}>
              {t('route.routeDetail.routeOrderTable.orderTypes.exchange')}
            </Select.Option>
            <Select.Option value={3}>
              {t('route.routeDetail.routeOrderTable.orderTypes.replenish')}
            </Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="store_id"
          label={t('route.routeDetail.routeOrderTable.store')}
          initialValue={editingOrder.store && Number(editingOrder.store.id)}
          rules={[{ required: true }]}
        >
          <Select
            showSearch
            placeholder="Select a store"
            optionFilterProp="children"
            filterOption={(input, option: any) =>
              option.children
                .toLocaleLowerCase()
                .includes(input.toLocaleLowerCase())
            }
          >
            {storesData &&
              storesData.stores &&
              storesData.stores.length > 0 &&
              storesData.stores.map((store: any) => (
                <Select.Option key={store.id} value={Number(store.id)}>
                  {store.store_name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>

        <Collapse defaultActiveKey={['1']} ghost>
          <Panel header={t('order.otherFields')} key="1">
            <Form.Item
              name="building_type"
              label={t('route.routeDetail.routeOrderTable.buildingType.title')}
              initialValue={editingOrder.building_type}
            >
              <Select>
                <Select.Option value={0}>
                  {t('route.routeDetail.routeOrderTable.buildingType.house')}
                </Select.Option>
                <Select.Option value={1}>
                  {t(
                    'route.routeDetail.routeOrderTable.buildingType.townhouse'
                  )}
                </Select.Option>
                <Select.Option value={2}>
                  {t(
                    'route.routeDetail.routeOrderTable.buildingType.apartment'
                  )}
                </Select.Option>
                <Select.Option value={3}>
                  {t('route.routeDetail.routeOrderTable.buildingType.basement')}
                </Select.Option>
                <Select.Option value={4}>
                  {t('route.routeDetail.routeOrderTable.buildingType.store')}
                </Select.Option>
                <Select.Option value={5}>
                  {t('route.routeDetail.routeOrderTable.buildingType.other')}
                </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="address"
              label={t('route.routeDetail.routeOrderTable.address')}
            >
              <AddressAutoComplete />
            </Form.Item>
            <Form.Item
              name="unit"
              label={'Unit'}
              initialValue={editingOrder.unit}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="buzz"
              label={'Buzz'}
              initialValue={editingOrder.buzz}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="remark"
              label={t('route.routeDetail.routeOrderTable.remark')}
              initialValue={editingOrder.remark}
            >
              <Input.TextArea />
            </Form.Item>
          </Panel>
        </Collapse>
      </Form>
    </Modal>
  );
};

export default EditOrderForm;
