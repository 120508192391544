import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import Login from '../views/Auth/Login';

const routes = [
  {
    path: '/login',
    component: Login,
  },
];

const Auth = () => {
  return (
    <Router>
      <Switch>
        {routes.map((route, i) => (
          <Route key={i} {...route} />
        ))}
        <Redirect to="/login" />
      </Switch>
    </Router>
  );
};

export default Auth;
