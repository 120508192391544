import { Typography } from 'antd';
import React from 'react';
import { Layer, Source, Marker } from 'react-mapbox-gl';
import { Order } from '../../../../../types';
import { getRandomColor } from '../../../../../utils/tools';

const { Text } = Typography;
const routeColorSet = [
  '#b0a3d4',
  '#ffa9e7',
  '#e85f5c',
  '#f5b700',
  '#d90368',
  '#3A5BDF',
  '#8A931C',
  '#67187C',
  '#B680C5',
];

type CompareRoutesProps = {
  routeCompareData: Array<{
    id: string;
    name: string;
    geoJson: object[];
    orders: Order[];
  }>;
  setMapLegend: Function;
  mapRef: any;
  clickedOrder: string | null;
  handleMarkerClick: Function;
  setClickedOrder: Function;
};

const CompareRoutes = React.memo(
  ({
    routeCompareData,
    setMapLegend,
    mapRef,
    clickedOrder,
    handleMarkerClick,
    setClickedOrder,
    ...props
  }: CompareRoutesProps) => {
    if (routeCompareData.length > 0) {
      setMapLegend([]);
      const otherRoutes = routeCompareData.map((route, index: number) => {
        // line color depends on index, if outside of color scope, generate random color
        const lineColor =
          routeColorSet[index] !== undefined
            ? routeColorSet[index]
            : getRandomColor();

        setMapLegend((prev: any) => [
          ...prev,
          { name: route.name, color: lineColor },
        ]);

        return (
          <React.Fragment key={index} {...props}>
            {route.geoJson &&
              route.geoJson.map((routePart, partIndex) => {
                return (
                  <React.Fragment key={partIndex}>
                    <Source
                      id={`route_source@${index}-${partIndex}`}
                      geoJsonSource={{
                        type: 'geojson',
                        data: routePart,
                      }}
                    />
                    <Layer
                      id={`route@${index}-${partIndex}`}
                      type="line"
                      layout={{ 'line-join': 'round', 'line-cap': 'round' }}
                      paint={{ 'line-color': lineColor, 'line-width': 5 }}
                      sourceId={`route_source@${index}-${partIndex}`}
                    ></Layer>
                    {route.orders.map((order, orderIndex) => {
                      const isActiveDot =
                        clickedOrder === `route@${index}-${orderIndex}`;

                      return (
                        <Marker
                          key={`route@${index}-${orderIndex}`}
                          coordinates={[order.longitude, order.latitude]}
                          onClick={() => {
                            if (mapRef !== null) {
                              setTimeout(() => {
                                /** @ts-ignore */
                                mapRef.flyTo({
                                  center: [order.longitude, order.latitude],
                                  zoom: 13,
                                });
                              }, 1);
                            }
                            setClickedOrder(`route@${index}-${orderIndex}`);
                            handleMarkerClick(order);
                          }}
                        >
                          <div
                            style={{
                              position: 'relative',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              cursor: 'pointer',
                              backgroundColor: isActiveDot
                                ? 'yellow'
                                : lineColor,
                              width: 32,
                              height: 32,
                              borderRadius: '50%',
                              opacity: 0.8,
                            }}
                          >
                            <Text
                              style={{
                                color: isActiveDot ? '#F57A4C' : 'white',
                                zIndex: 99,
                              }}
                            >
                              {order.sequence_label}
                            </Text>
                          </div>
                        </Marker>
                      );
                    })}
                  </React.Fragment>
                );
              })}
          </React.Fragment>
        );
      });

      return <>{otherRoutes}</>;
    }

    return <></>;
  }
);

export default CompareRoutes;
