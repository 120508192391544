import { gql } from '@apollo/client';

export const GET_VEHICLES = gql`
  query GetVehicles {
    vehicles {
        id
        vehicle_name
        vehicle_plate
        vehicle_type
        fuel_base
        fuel_subsidy
        created_at
        update_at
    }
  }
`;

export const ADD_VEHICLE = gql`
  mutation AddVehicle(
    $vehicle_name: String!
    $vehicle_plate: String!
    $vehicle_type: Int!
    $fuel_base: Float!
    $fuel_subsidy: Float
  ) {
    addVehicle(
        vehicle_name: $vehicle_name
        vehicle_plate: $vehicle_plate
        vehicle_type: $vehicle_type
        fuel_base: $fuel_base
        fuel_subsidy: $fuel_subsidy
    ) {
        id
        vehicle_name
        vehicle_plate
        vehicle_type
        fuel_base
        fuel_subsidy
        created_at
        update_at
    }
  }
`;

export const UPDATE_VEHICLE = gql`
  mutation UpdateVehicle($id: ID!, $input: updateVehicleInput!) {
    updateVehicle(id: $id, input: $input) {
        id
        vehicle_name
        vehicle_plate
        vehicle_type
        fuel_base
        fuel_subsidy
        created_at
        update_at
    }
  }
`;

export const DELETE_VEHICLE = gql`
  mutation DeleteVehicle($id: ID!) {
    deleteVehicle(id: $id) {
        id
        vehicle_name
        vehicle_plate
        vehicle_type
        fuel_base
        fuel_subsidy
        created_at
        update_at
    }
  }
`;
