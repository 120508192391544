import { useMutation, useQuery } from '@apollo/client';
import {
  Button,
  Input,
  Modal,
  Select,
  Space,
  Spin,
  Table,
  Typography,
} from 'antd';
import Column from 'antd/lib/table/Column';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ASSIGNED_DRIVER_LIST } from '../../../../GraphQL/queries/driver';
import { graphqlErrorMessageAlert, showMessage } from '../../../../utils/tools';
import Loading from '../../../common/Loading';
import DriverStatus from '../../drivers/DriverStatus';
import { SearchOutlined } from '@ant-design/icons';
import {
  GET_ROUTE,
  UPDATE_ROUTE_INFO,
} from '../../../../GraphQL/queries/route';

const { Title } = Typography;
const { Option } = Select;

type AssignDriverModelProps = {
  setShowDriverModal: Function;
  routeId: string;
};

const AssignDriverModal = ({
  setShowDriverModal,
  routeId,
}: AssignDriverModelProps) => {
  const [isModalVisible, setIsModalVisible] = useState(true);

  const { data, loading } = useQuery(ASSIGNED_DRIVER_LIST, {
    onError: graphqlErrorMessageAlert,
  });

  const handleClose = () => {
    setShowDriverModal(false);
    setIsModalVisible(false);
  };

  return (
    <Modal
      width={'50vw'}
      visible={isModalVisible}
      onCancel={handleClose}
      footer={<></>}
    >
      {loading ? (
        <Loading />
      ) : data && data.drivers ? (
        <DriverList
          drivers={data.drivers}
          routeId={routeId}
          handleClose={handleClose}
        />
      ) : (
        <></>
      )}
    </Modal>
  );
};

type DriverListProps = {
  drivers: Array<{
    driver_id: string;
    driver_name: string;
    phone: string;
    status: number;
  }>;
  routeId: string;
  handleClose: Function;
};

const DriverList = ({ drivers, routeId, handleClose }: DriverListProps) => {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState('');
  const [displayDrivers, setDisplayDrivers] = useState(drivers);
  const [searchStatus, setSearchStatus] = useState(-1);
  const [showSubmitLoading, setShowSubmitLoading] = useState(false);

  const [assignDriver] = useMutation(UPDATE_ROUTE_INFO, {
    onError: (error) => {
      graphqlErrorMessageAlert(error);
      setShowSubmitLoading(false);
    },
    onCompleted: () => handleAssignCompleted(),
    awaitRefetchQueries: true,
    refetchQueries: [{ query: GET_ROUTE, variables: { id: routeId } }],
  });

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  useEffect(() => {
    const filteredDrivers = drivers.filter((driver) => {
      const textCheck =
        driver.driver_name.toLowerCase().includes(searchText.toLowerCase()) ||
        driver.phone.includes(searchText);

      const statusCheck = searchStatus === -1 || driver.status === searchStatus;
      return textCheck && statusCheck;
    });

    setDisplayDrivers(filteredDrivers);
  }, [searchText, searchStatus, drivers]);

  const handleAssignDriver = (driverId: string) => {
    setShowSubmitLoading(true);
    assignDriver({
      variables: {
        id: routeId,
        input: {
          driver_id: driverId,
        },
      },
    });
  };

  const handleAssignCompleted = () => {
    showMessage(
      'success',
      t('route.routeDetail.header.driverInfo.messages.assignSuccess')
    );
    handleClose();
    setShowSubmitLoading(false);
  };

  const resetList = () => {
    setSearchText('');
    setSearchStatus(-1);
    setDisplayDrivers(drivers);
  };

  return (
    <Spin spinning={showSubmitLoading} size="large">
      <Title level={3}>
        {t('route.routeDetail.header.driverInfo.assignDriver')}
      </Title>
      <Space style={{ marginBottom: '1rem' }}>
        <Input
          onChange={onSearch}
          prefix={<SearchOutlined />}
          value={searchText}
          placeholder={t('driver.searchPlaceholder')}
          allowClear
        />
        <Select
          defaultValue={-1}
          style={{ width: 120 }}
          onChange={(value) => {
            setSearchStatus(value);
          }}
          value={searchStatus}
        >
          <Option value={-1}>All</Option>
          <Option value={1}>
            <DriverStatus status={1} />
          </Option>
          <Option value={0}>
            <DriverStatus status={0} />
          </Option>
          <Option value={2}>
            <DriverStatus status={2} />
          </Option>
        </Select>
        <Button onClick={resetList}>{t('common.reset')}</Button>
      </Space>
      <Table
        dataSource={displayDrivers}
        pagination={false}
        sticky
        rowKey={(record) => record.driver_id}
      >
        <Column
          title={t('driver.table.name')}
          dataIndex={'driver_name'}
          sorter={(a: any, b: any) => {
            var nameA = a.driver_name.toUpperCase(); // ignore upper and lowercase
            var nameB = b.driver_name.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            // names must be equal
            return 0;
          }}
          defaultSortOrder="ascend"
        />
        <Column title={t('driver.table.phone')} dataIndex={'phone'} />
        <Column
          title={t('driver.table.status')}
          dataIndex={'status'}
          render={(status) => <DriverStatus status={status} />}
        />
        <Column
          title={t('driver.table.actions.actions')}
          render={(record: any, index: number) => {
            return (
              <Button onClick={() => handleAssignDriver(record.driver_id)}>
                {t('route.routeDetail.header.driverInfo.assign')}
              </Button>
            );
          }}
        />
      </Table>
    </Spin>
  );
};

export default AssignDriverModal;
