import { useQuery } from '@apollo/client';
import { Button, Input, Space } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ErrorResult from '../../../components/common/ErrorResult';
import Loading from '../../../components/common/Loading';
import AddNewAddress from '../../../components/dashboard/addressBook/AddNewAddress';
import AddressList from '../../../components/dashboard/addressBook/AddressList';
import { GET_ADDRESSES } from '../../../GraphQL/queries/address';
import ViewBasicLayout from '../../../layout/ViewBasicLayout';
import { graphqlErrorHandler } from '../../../utils/tools';
import { SearchOutlined } from '@ant-design/icons';
import { Address } from '../../../types';
import AddressBin from '../../../components/dashboard/addressBook/AddressBin';

const AddressBook = () => {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState('');
  const { loading, error, data, refetch } = useQuery(GET_ADDRESSES, {
    variables: { isDeleted: false },
  });

  const handleFetchAddressList = () => {
    refetch({ isDeleted: false });
  };

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const resetList = () => {
    setSearchText('');
  };

  return (
    <ViewBasicLayout title={t('addressBook.title')}>
      <div
        style={{
          marginBottom: '.5rem',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Space>
          <AddNewAddress />
          <AddressBin />
        </Space>
        <Space>
          <Input
            onChange={onSearch}
            prefix={<SearchOutlined />}
            value={searchText}
            placeholder={t('addressBook.searchPlaceholder')}
          />
          <Button onClick={resetList}>{t('common.reset')}</Button>
        </Space>
      </div>
      {loading && <Loading />}
      {error && (
        <ErrorResult
          title={graphqlErrorHandler(error)}
          extra={
            <Button type="primary" onClick={handleFetchAddressList}>
              {t('common.retry')}
            </Button>
          }
        />
      )}
      {data && data.addresses && (
        <AddressList
          list={
            data && data.addresses
              ? data.addresses.filter(
                  (address: Address) =>
                    address.name
                      .toLowerCase()
                      .includes(searchText.toLowerCase()) ||
                    (address.label !== null &&
                      address.label
                        .toLowerCase()
                        .includes(searchText.toLowerCase()))
                )
              : []
          }
          // refetch={refetch}
        />
      )}
    </ViewBasicLayout>
  );
};

export default AddressBook;
