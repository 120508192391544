import { Tabs } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import DefaultRouteAddress from '../../../components/dashboard/settings/preference/DefaultRouteAddress';
import ViewBasicLayout from '../../../layout/ViewBasicLayout';

const { TabPane } = Tabs;

const Preference = () => {
  const { t } = useTranslation();

  return (
    <ViewBasicLayout title={t('settings.preference.title')}>
      <Tabs defaultActiveKey="1">
        <TabPane tab={t('settings.preference.route.title')} key="1">
          <DefaultRouteAddress />
        </TabPane>
      </Tabs>
    </ViewBasicLayout>
  );
};

export default Preference;
