import { Button, Form, Input, Modal, Select, Tooltip } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { graphqlErrorMessageAlert, showMessage } from '../../../utils/tools';
import { UPDATE_USER } from '../../../GraphQL/queries/user';

type EditUserFormProps = {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  editingUser: { id: string; username: string; nickname: string; role: string };
};

const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 14,
  },
};

const EditUserForm = ({
  showModal,
  setShowModal,
  editingUser,
}: EditUserFormProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [updateUser] = useMutation(UPDATE_USER, {
    onError: graphqlErrorMessageAlert,
    onCompleted: () => {
      handleCancel();
      showMessage('success', 'Update user information successfully.');
    },
  });

  const handleCancel = () => {
    setShowModal(false);
    form.resetFields();
  };

  const onFinish = (values: any) => {
    console.log('Received values of form: ', values);
    updateUser({
      variables: {
        id: editingUser.id,
        input: {
          role: values.role,
          name: values.nickname,
          password: values.newPassword,
        },
      },
    });
  };

  return (
    <Modal
      title={t('userList.editUserInfo')}
      visible={showModal}
      onCancel={handleCancel}
      afterClose={() => {
        form.resetFields();
      }}
      destroyOnClose={true}
      maskClosable={false}
      footer={
        <>
          <Form.Item>
            <Button onClick={handleCancel}>{t('common.cancel')}</Button>
            <Button
              type="primary"
              onClick={() => {
                form.submit();
              }}
            >
              {t('common.save')}
            </Button>
          </Form.Item>
        </>
      }
    >
      <Form
        form={form}
        name="edit-user"
        onFinish={onFinish}
        scrollToFirstError
        {...formItemLayout}
      >
        <Form.Item
          name="username"
          label={t('userList.table.username')}
          initialValue={editingUser.username}
        >
          <span>{editingUser.username}</span>
        </Form.Item>
        <Form.Item
          name="nickname"
          label={
            <span>
              {t('userList.table.nickname')}&nbsp;
              <Tooltip title="What do you want others to call you?">
                <QuestionCircleOutlined />
              </Tooltip>
            </span>
          }
          initialValue={editingUser.nickname}
          rules={[
            {
              required: true,
              message: 'Please input your nickname!',
              whitespace: true,
            },
            { min: 3 },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="role"
          initialValue={editingUser.role}
          label={t('userList.table.role')}
          rules={[{ required: true }]}
        >
          <Select>
            <Select.Option value="admin">Admin</Select.Option>
            <Select.Option value="route-planner">Route Planner</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label={t('profile.pwdModal.newPwd')}
          name="newPassword"
          rules={[{ min: 6 }]}
        >
          <Input.Password />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditUserForm;
