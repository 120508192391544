import React from 'react';
import { Divider, Typography } from 'antd';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

type OrderFieldCompareTableProps = {
  fields: Array<{
    field: string;
    old: number | string;
    new: number | string;
  }>;
};

const OrderFieldCompareTable = ({ fields }: OrderFieldCompareTableProps) => {
  const { t } = useTranslation();

  return (
    <>
      <div style={{ display: 'flex' }}>
        <div style={{ flex: 0.5 }}>
          <Text strong>{t('order.productModal.field')}</Text>
        </div>
        <div style={{ flex: 1 }}>
          <Text strong>{t('order.productModal.currentValue')}</Text>
        </div>
        <div style={{ flex: 1 }}>
          <Text strong>{t('order.productModal.databaseValue')}</Text>
        </div>
      </div>
      <Divider style={{ marginTop: 10, marginBottom: 10 }} />
      {fields.map((field, index) => {
        return (
          <div style={{ display: 'flex' }} key={index}>
            <div style={{ flex: 0.5 }}>
              <Text strong>{orderFieldToString(field.field)}</Text>
            </div>
            <div style={{ flex: 1 }}>
              <Text>{field.old ? field.old : 'N/A'}</Text>
            </div>
            <div style={{ flex: 1 }}>
              <Text>{field.new ? field.new : 'N/A'}</Text>
            </div>
          </div>
        );
      })}
    </>
  );
};

const orderFieldToString = (field: string) => {
  switch (field) {
    case 'receiver':
      return i18next.t('route.routeDetail.routeOrderTable.receiver');

    case 'phone':
      return i18next.t('route.routeDetail.routeOrderTable.phone');

    case 'latitude':
      return i18next.t('addressBook.table.lat');

    case 'longitude':
      return i18next.t('addressBook.table.lng');

    case 'buzz':
      return 'Buzz';

    case 'unit':
      return 'Unit';

    case 'address':
      return i18next.t('route.routeDetail.routeOrderTable.address');

    default:
      return field;
  }
};

export default OrderFieldCompareTable;
