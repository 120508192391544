import React from 'react';
import DashboardRouter from '../components/dashboard/DashboardRouter';
import DashboardLayout from '../components/dashboard/DashboardLayout';
import { useQuery } from '@apollo/client';
import { graphqlErrorMessageAlert } from '../utils/tools';
import jwtDecode from 'jwt-decode';
import { Spin } from 'antd';
import { useDispatch } from 'react-redux';
import { GET_USER_INFO } from '../GraphQL/queries/user';
import { UserActionTypes } from '../store/userReducer';

const Dashboard = () => {
  const dispatch = useDispatch();

  //Decode Auth Token JWT
  const token: any = jwtDecode(localStorage.getItem('REACT_TOKEN_AUTH') || '');
  //Get user id from token
  const id = token.id;

  //Use user info by passing user id
  const { data, loading } = useQuery(GET_USER_INFO, {
    variables: { id },
    onError: graphqlErrorMessageAlert,
  });

  if (data && data.user) {
    //Successfully get user info, store in redux
    const { id, username, name, role } = data.user;
    dispatch({
      type: UserActionTypes.UPDATE_USER_INFO,
      payload: {
        id,
        username,
        name,
        role,
      },
    });
  }

  if (loading) {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Spin />
      </div>
    );
  }

  return (
    <DashboardRouter>
      {(routes) => <DashboardLayout routes={routes} />}
    </DashboardRouter>
  );
};

export default Dashboard;
