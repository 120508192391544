import React, { useState } from 'react';
import { Button, Input, Modal, Popconfirm, Space, Table } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { DRIVER_LIST, RESTORE_DRIVER } from '../../../GraphQL/queries/driver';
import { graphqlErrorMessageAlert, showMessage } from '../../../utils/tools';
import Loading from '../../common/Loading';
import { useTranslation } from 'react-i18next';
import { Driver } from '../../../types';
import { SearchOutlined, QuestionCircleOutlined } from '@ant-design/icons';

const { Column } = Table;

const DriverBin = () => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [getDrivers, { loading, data }] = useLazyQuery(DRIVER_LIST, {
    variables: { isDeleted: true },
    onError: graphqlErrorMessageAlert,
  });

  const [restoreDriver] = useMutation(RESTORE_DRIVER, {
    onCompleted: () => handleRestoreCompleted(),
    onError: graphqlErrorMessageAlert,
  });

  const handleRestoreCompleted = () => {
    showMessage('success', t('driver.restoreSuccess'));
  };

  const handleRestoreConfirm = (driver_id: string) => {
    restoreDriver({
      variables: { driver_id },
      update: (cache, restoreDriver) => {
        const isDeletedData: any = cache.readQuery({
          query: DRIVER_LIST,
          variables: { isDeleted: true },
        });
        cache.writeQuery({
          query: DRIVER_LIST,
          variables: { isDeleted: true },
          data: {
            ...isDeletedData,
            drivers: isDeletedData.drivers.filter(
              (driver: Driver) => driver.driver_id !== driver_id
            ),
          },
        });
        const data: any = cache.readQuery({
          query: DRIVER_LIST,
          variables: { isDeleted: false },
        });
        cache.writeQuery({
          query: DRIVER_LIST,
          variables: { isDeleted: false },
          data: {
            ...data,
            drivers: [...data.drivers, restoreDriver],
          },
        });
      },
    });
  };

  const onOpen = () => {
    getDrivers({ variables: { isDeleted: true } });
    setVisible(true);
  };

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  return (
    <>
      <Button icon={<DeleteOutlined />} onClick={onOpen}>
        {t('driver.driverBin')}
      </Button>
      <Modal
        title={t('driver.driverBin')}
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
        bodyStyle={{ padding: '24px 24px 0 24px' }}
      >
        {loading ? (
          <Loading />
        ) : (
          <Space direction="vertical">
            <Input
              onChange={onSearch}
              prefix={<SearchOutlined />}
              value={searchText}
              placeholder={t('driver.searchPlaceholder')}
            />

            <Table<Driver>
              dataSource={
                data && data.drivers
                  ? data.drivers.filter(
                      (driver: Driver) =>
                        driver.driver_name
                          .toLowerCase()
                          .includes(searchText.toLowerCase()) ||
                        driver.phone.includes(searchText) ||
                        driver.active_code
                          .toLowerCase()
                          .includes(searchText.toLowerCase())
                    )
                  : []
              }
              rowKey={(record) => record.driver_id}
              loading={loading}
              sticky
              pagination={{ defaultPageSize: 10 }}
            >
              <Column title="ID" dataIndex="driver_id" width={70} />
              <Column title={t('driver.table.name')} dataIndex="driver_name" />
              <Column title={t('driver.table.phone')} dataIndex="phone" />
              <Column
                title={t('driver.table.activeCode')}
                dataIndex="active_code"
              />
              <Column
                render={(record: Driver) => (
                  <Popconfirm
                    title={t('driver.restorePrompt')}
                    icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                    okText={t('common.yes')}
                    cancelText={t('common.no')}
                    onConfirm={() => handleRestoreConfirm(record.driver_id)}
                  >
                    <Button size="small">{t('driver.restore')}</Button>
                  </Popconfirm>
                )}
              />
            </Table>
          </Space>
        )}
      </Modal>
    </>
  );
};

export default DriverBin;
