/**
 * #####################
 * Orders
 * #####################
 */

// Order type
export enum OrderType {
  Delivery = 0,
  Pickup = 1,
  Exchange = 2,
  Replenish = 3,
}

// Order Status
export enum OrderStatusType {
  Preparing = 0,
  OutForDelivery = 1,
  ArrivingSoon = 2,
  Failed = 3,
  Delivered = 4,
}

// Order address building type
export enum BuildingType {
  House = 0,
  Townhouse = 1,
  Apartment = 2,
  Basement = 3,
  Store = 4,
  Other = 5,
}

/**
 * #####################
 * Optimize Route Provide
 * #####################
 */
export enum OptimizeProvide {
  ORS = 0,
  GEO = 1,
}

/**
 * #####################
 * Driver
 * #####################
 */
export enum DriverStatusType {
  OffDuty = 0,
  OnDuty = 1,
  Forbidden = 2,
}

/**
 * #####################
 * Vehicle 车辆类型
 * #####################
 */
export enum VehicleType {
  CompanyVehicle = 0,
  OwnVehicle = 1,
}

/**
 * #####################
 * JWT User TYPE
 * #####################
 */
export enum JwtUserType {
  Admin = 0,
  Driver = 1,
}
