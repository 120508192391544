import { ReloadOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Form, Input, Modal, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { UPDATE_STORE } from '../../../GraphQL/queries/store';
import {
  generateKey,
  graphqlErrorMessageAlert,
  showMessage,
} from '../../../utils/tools';

type EditStoreFormProps = {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  editingStore: {
    id: string;
    store_name: string;
    store_code: string;
    credential_code: string;
    api_path: string;
  };
};

const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 14,
  },
};

const EditStoreForm = ({
  showModal,
  setShowModal,
  editingStore,
}: EditStoreFormProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [updateStore] = useMutation(UPDATE_STORE, {
    onError: graphqlErrorMessageAlert,
    onCompleted: () => {
      handleCancel();
      showMessage('success', 'Update store information successfully.');
    },
  });

  const handleCancel = () => {
    form.resetFields();
    setShowModal(false);
  };

  const onFinish = (values: any) => {
    updateStore({
      variables: {
        id: editingStore.id,
        input: {
          store_name: values.store_name,
          store_code: values.store_code.toUpperCase(),
          credential_code: values.credential_code.toUpperCase(),
          api_path: values.api_path,
        },
      },
    });
  };

  return (
    <Modal
      title={t('stores.editStore')}
      visible={showModal}
      onCancel={handleCancel}
      afterClose={() => {
        form.resetFields();
      }}
      destroyOnClose={true}
      maskClosable={false}
      footer={
        <Form.Item>
          <Button onClick={handleCancel}>{t('common.cancel')}</Button>
          <Button
            type="primary"
            onClick={() => {
              form.submit();
            }}
          >
            {t('common.save')}
          </Button>
        </Form.Item>
      }
    >
      <Form
        form={form}
        name="edit-store"
        onFinish={onFinish}
        scrollToFirstError
        {...formItemLayout}
      >
        <Form.Item
          name="store_name"
          label={t('stores.table.storeName')}
          initialValue={editingStore.store_name}
          rules={[
            {
              required: true,
              message: 'Please input store name!',
              whitespace: true,
            },
            { min: 3 },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="store_code"
          label={t('stores.table.storeCode')}
          initialValue={editingStore.store_code}
          rules={[{ required: true }]}
        >
          <Input
            suffix={
              <Tooltip title={t('stores.generateKey')}>
                <Button
                  shape="circle"
                  size="small"
                  icon={<ReloadOutlined />}
                  onClick={() => {
                    form.setFieldsValue({
                      store_code: generateKey(6),
                    });
                  }}
                />
              </Tooltip>
            }
          />
        </Form.Item>
        <Form.Item
          name="credential_code"
          label={t('stores.table.credentialCode')}
          initialValue={editingStore.credential_code}
          rules={[{ required: true, min: 10, max: 50 }]}
        >
          <Input
            suffix={
              <Tooltip title={t('stores.generateKey')}>
                <Button
                  shape="circle"
                  size="small"
                  icon={<ReloadOutlined />}
                  onClick={() => {
                    form.setFieldsValue({
                      credential_code: generateKey(32),
                    });
                  }}
                />
              </Tooltip>
            }
          />
        </Form.Item>
        <Form.Item
          name="api_path"
          label={t('stores.table.apiPath')}
          initialValue={editingStore.api_path}
          rules={[
            {
              required: true,
              message: 'Please input api path!',
              whitespace: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditStoreForm;
