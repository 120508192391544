import React from 'react';
import { gql, useMutation } from '@apollo/client';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { Form, Input, Button, Checkbox, Layout, Spin } from 'antd';
import { graphqlErrorMessageAlert } from '../../utils/tools';
import { StyleSheet } from '../../types';
import { Typography } from 'antd';

import logo from '../../assets/images/appicon.png';
import { login } from '../../utils/createAuthProvider';
import Footer from '../../components/dashboard/Footer';
import LanguagePicker from '../../components/auth/LanguagePicker';
import { useTranslation } from 'react-i18next';

const ADMIN_LOGIN = gql`
  mutation Login($name: String!, $password: String!, $remember: Boolean!) {
    loginUser(username: $name, password: $password, remember: $remember) {
      access_token
      token_type
      expires_in
    }
  }
`;

const { Content } = Layout;
const { Title } = Typography;

const Login = () => {
  const { t } = useTranslation();
  const [adminLogin, { data, loading }] = useMutation(ADMIN_LOGIN, {
    onError: graphqlErrorMessageAlert,
  });

  const onFinish = (values: any) => {
    console.log('Received values of form: ', values);
    adminLogin({
      variables: {
        name: values.username,
        password: values.password,
        remember: values.remember,
      },
    });
  };

  if (data) {
    const accessToken = data?.loginUser?.access_token;
    if (accessToken) {
      login(accessToken);
    }
  }

  return (
    <Layout style={{ height: '100%' }}>
      <Content style={styles.container}>
        <div className="container">
          <div className="header" style={styles.header}>
            <img src={logo} style={styles.logo} alt="Luniu Mall logo" />
            <Title style={{ textAlign: 'center' }}>
              {t('general.projectName')}
            </Title>
          </div>
          <Form
            name="normal_login"
            className="login-form"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            style={styles.main}
          >
            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  message: t('auth.login.usernameRequiredError'),
                },
              ]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder={t('auth.login.username')}
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: t('auth.login.passwordRequiredError'),
                },
              ]}
            >
              <Input
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder={t('auth.login.password')}
              />
            </Form.Item>
            <Form.Item>
              <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox>{t('auth.login.rememberMe')}</Checkbox>
              </Form.Item>

              <a className="login-form-forgot" href="http://google.com">
                {t('auth.login.forgotPassword')}
              </a>
            </Form.Item>
            <Form.Item>
              <Spin spinning={loading}>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  block
                >
                  {t('auth.login.logIn')}
                </Button>
              </Spin>
            </Form.Item>
          </Form>
        </div>
      </Content>
      <LanguagePicker />
      <Footer />
    </Layout>
  );
};

const styles: StyleSheet = {
  container: {
    backgroundImage:
      'url(https://gw.alipayobjects.com/zos/rmsportal/TVYTbAXWheQpRcWDaDMu.svg)',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center 110px',
    backgroundSize: '100%',
  },
  logo: {
    width: 100,
    height: 100,
    borderRadius: 15,
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '10%',
  },
  main: {
    margin: '0 auto',
    width: 328,
  },
};

export default Login;
