import { useQuery } from '@apollo/client';
import { Card, Statistic, Row, Col, Button, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DAILY_REPORT } from '../../../GraphQL/queries/home';
import { graphqlErrorHandler, orderStatusToString } from '../../../utils/tools';
import ErrorResult from '../../common/ErrorResult';
import Loading from '../../common/Loading';
import { ResponsivePie } from '@nivo/pie';

const { Text } = Typography;

type DayReportProps = {
  date: string;
};

const DayReport = ({ date }: DayReportProps) => {
  const { t } = useTranslation();

  const { data, loading, error, refetch } = useQuery(DAILY_REPORT, {
    variables: { date },
    fetchPolicy: 'network-only',
  });

  if (loading) return <Loading />;

  if (error) {
    return (
      <ErrorResult
        title={graphqlErrorHandler(error)}
        extra={
          <Button type="primary" onClick={() => refetch()}>
            {t('common.retry')}
          </Button>
        }
      />
    );
  }

  if (data && data.todayData) {
    const {
      totalOrders = 0,
      totalRoutes = 0,
      totalOrderStatus = [0, 0, 0, 0, 0],
    } = data.todayData;

    const statusAmount: JSX.Element[] = [];

    const statusPieData = totalOrderStatus.map(
      (amount: number, index: number) => {
        statusAmount.push(
          <Text key={index}>{`${orderStatusToString(index)}:${amount}`}</Text>
        );

        return {
          id: orderStatusToString(index),
          label: orderStatusToString(index),
          value: amount,
          color: Math.floor(Math.random() * 16777215).toString(16),
        };
      }
    );

    return (
      <>
        <Row gutter={16}>
          <Col span={12}>
            <Card>
              <Statistic
                title={t('home.report.dayRouteTotal')}
                value={totalRoutes}
                valueStyle={{ textAlign: 'center' }}
              />
            </Card>
          </Col>
          <Col span={12}>
            <Card>
              <Statistic
                title={t('home.report.dayOrderTotal')}
                value={totalOrders}
                valueStyle={{ textAlign: 'center' }}
              />
            </Card>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <div style={{ height: 400 }}>
              <ResponsivePie
                data={statusPieData}
                margin={{ top: 60, right: 60, bottom: 60, left: 60 }}
                innerRadius={0.5}
                padAngle={0.7}
                cornerRadius={3}
                borderWidth={1}
                borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                radialLabelsSkipAngle={10}
                radialLabelsTextColor="#333333"
                radialLabelsLinkColor={{ from: 'color' }}
                // sliceLabelsSkipAngle={10}
                // sliceLabelsTextColor="#333333"
                legends={[
                  {
                    anchor: 'top',
                    direction: 'row',
                    justify: false,
                    translateY: -35,
                    itemsSpacing: 7,
                    itemWidth: 80,
                    itemHeight: 18,
                    itemTextColor: '#999',
                    itemDirection: 'left-to-right',
                    itemOpacity: 1,
                    symbolSize: 18,
                    symbolShape: 'circle',
                    effects: [
                      {
                        on: 'hover',
                        style: {
                          itemTextColor: '#000',
                        },
                      },
                    ],
                  },
                ]}
              />
            </div>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
                padding: 24,
                paddingTop: 0,
              }}
            >
              {statusAmount}
            </div>
          </Col>
        </Row>
      </>
    );
  }

  return <></>;
};

export default DayReport;
