import { Result, Spin } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

const Loading = () => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <Result icon={<Spin size="large" />} title={t('common.loading')} />
    </div>
  );
};

export default Loading;
