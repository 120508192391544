import { Button, Popconfirm, Space, Table } from 'antd';
import React, { useState } from 'react';
import dayjs from 'dayjs';
import { graphqlErrorMessageAlert, showMessage } from '../../../utils/tools';
import { useMutation } from '@apollo/client';
import { DELETE_USER, USER_LIST } from '../../../GraphQL/queries/user';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState, UserState } from '../../../types';
import { NavLink } from 'react-router-dom';
import EditUserForm from './EditUserForm';

const { Column } = Table;

type UserListProps = {
  users: Array<{
    id: string;
    name: string;
    role: string;
    username: string;
    created_at: string;
    last_login: string;
  }>;
};

const UserList = ({ users }: UserListProps) => {
  const { t } = useTranslation();
  const userInfo = useSelector<RootState, UserState['userInfo']>(
    (state) => state.user.userInfo
  );

  const [showModal, setShowModal] = useState(false);
  const [editingUser, setEditingUser] = useState({
    id: '',
    username: '',
    nickname: '',
    role: '',
  });

  const [deleteUser] = useMutation(DELETE_USER, {
    onCompleted: () => handleDeleteCompleted(),
    onError: graphqlErrorMessageAlert,
  });

  const handleDeleteConfirm = (id: string) => {
    deleteUser({
      variables: { id: id },
      update: (proxy, deleteUser) => {
        const data: any = proxy.readQuery({ query: USER_LIST });
        proxy.writeQuery({
          query: USER_LIST,
          data: { ...data, users: [...data.users, deleteUser] },
        });
      },
    });
  };

  const handleDeleteCompleted = () => {
    showMessage('success', 'Delete successfully');
  };

  const handleTriggerEditForm = (currentRowInfo: any) => {
    const { username, name, role, id } = currentRowInfo;
    setEditingUser({ id: id, username: username, nickname: name, role: role });
    setShowModal(true);
  };

  return (
    <>
      <Table sticky dataSource={users} rowKey={(record) => record.id}>
        <Column title="ID" dataIndex="id" />
        <Column title={t('userList.table.username')} dataIndex="username" />
        <Column title={t('userList.table.nickname')} dataIndex="name" />
        <Column title={t('userList.table.role')} dataIndex="role" />
        <Column
          title={t('userList.table.lastLogin')}
          dataIndex="last_login"
          render={(date) => {
            return (
              <>
                {dayjs(date).valueOf() > 0
                  ? dayjs(date).format('YYYY/MM/DD HH:mm')
                  : 'N/A'}
              </>
            );
          }}
        />
        <Column
          title={t('userList.table.createdAt')}
          dataIndex="created_at"
          render={(date) => {
            return <>{dayjs(date).format('YYYY/MM/DD HH:mm')} </>;
          }}
        />
        <Column
          title={t('userList.table.actions.title')}
          width={140}
          render={(record: any, index: number) => {
            return (
              <Space key={index}>
                {userInfo.id !== record.id ? (
                  userInfo.role === 'admin' ? (
                    <>
                      <Button
                        onClick={() => handleTriggerEditForm(record)}
                        size="small"
                      >
                        {t('userList.table.actions.edit')}
                      </Button>
                      <Popconfirm
                        title={t('userList.table.deletePromptMessage')}
                        onConfirm={() => handleDeleteConfirm(record.id)}
                        onCancel={() => {}}
                        okText={t('common.yes')}
                        cancelText={t('common.no')}
                      >
                        <Button danger size="small">
                          {t('userList.table.actions.delete')}
                        </Button>
                      </Popconfirm>
                    </>
                  ) : (
                    <></>
                  )
                ) : (
                  <NavLink to="/profile">
                    {t('userList.table.actions.goToProfile')}
                  </NavLink>
                )}
              </Space>
            );
          }}
        />
      </Table>
      <EditUserForm
        showModal={showModal}
        setShowModal={setShowModal}
        editingUser={editingUser}
      />
    </>
  );
};

export default UserList;
