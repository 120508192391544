import { gql } from '@apollo/client';

export const GET_ORDERS = gql`
  query GetOrders(
    $page: Int!
    $pageSize: Int!
    $orderSN: String
    $filterConditions: OrderSearchConditions
  ) {
    orders(
      page: $page
      pageSize: $pageSize
      orderSN: $orderSN
      filterConditions: $filterConditions
    ) {
      total
      page
      pageSize
      orders {
        id
        route_id
        route {
          id
          name
          shipping_date
        }
        address
        phone
        receiver
        email
        order_sn
        latitude
        longitude
        remark
        status
        type
        sequence_index
        sequence_label
        created_at
        update_at
        arrival
        distance
        unit
        buzz
        building_type
        driver_remark
        order_images {
          id
          img_url
        }
        store {
          id
          store_name
        }
      }
    }
  }
`;

export const ADD_ORDER = gql`
  mutation AddOrder(
    $route_id: ID!
    $address: String!
    $phone: String!
    $receiver: String!
    $email: String
    $order_sn: String
    $latitude: Float
    $longitude: Float
    $remark: String
    $type: Int
    $building_type: Int
    $unit: String
    $buzz: String
  ) {
    addOrder(
      route_id: $route_id
      address: $address
      phone: $phone
      receiver: $receiver
      email: $email
      order_sn: $order_sn
      latitude: $latitude
      longitude: $longitude
      remark: $remark
      type: $type
      building_type: $building_type
      unit: $unit
      buzz: $buzz
    ) {
      id
      route_id
      address
      phone
      receiver
      email
      order_sn
      latitude
      longitude
      remark
      status
      type
      sequence_index
      sequence_label
      created_at
      update_at
      arrival
      distance
      building_type
      unit
      buzz
    }
  }
`;

export const ADD_ORDERS = gql`
  mutation AddOrders($orders: [addOrdersInput], $route_id: ID!) {
    addOrders(orders: $orders, route_id: $route_id) {
      id
      route_id
      address
      phone
      receiver
      email
      order_sn
      latitude
      longitude
      remark
      status
      type
      sequence_index
      sequence_label
      created_at
      update_at
      arrival
      distance
      building_type
      unit
      buzz
    }
  }
`;

export const UPDATE_ORDER = gql`
  mutation UpdateOrder($id: ID!, $input: updateOrderInput!) {
    updateOrder(id: $id, input: $input) {
      id
      route_id
      address
      phone
      receiver
      email
      order_sn
      latitude
      longitude
      remark
      status
      type
      sequence_index
      sequence_label
      created_at
      update_at
      arrival
      distance
      building_type
      unit
      buzz
    }
  }
`;

export const DELETE_ORDER = gql`
  mutation DeleteOrder($id: ID!) {
    deleteOrder(id: $id)
  }
`;

export const DELETE_ORDERS = gql`
  mutation DeleteOrders($orders: [ID!]!) {
    deleteOrders(orders: $orders)
  }
`;

export const UPDATE_ORDERS = gql`
  mutation UpdateOrders($orders: [updateOrdersInput]) {
    updateOrders(orders: $orders) {
      id
      route_id
      address
      phone
      receiver
      email
      order_sn
      latitude
      longitude
      remark
      status
      type
      sequence_index
      sequence_label
      created_at
      update_at
      arrival
      distance
      building_type
      unit
      buzz
    }
  }
`;

export const GET_ORDER_LOG = gql`
  query OrderLog($id: ID!, $onlyCustomerLog: Boolean) {
    orderLogs(id: $id, onlyCustomerLog: $onlyCustomerLog) {
      id
      order_id
      remark
      show_customer
      created_at
      user {
        name
      }
      driver {
        driver_name
      }
    }
  }
`;

export const GET_ORDER_PRODUCTS_INFO = gql`
  query OrderProducts($id: ID!) {
    order(id: $id) {
      id
      partner_order_detail
    }
  }
`;

export const GET_ORDERS_BY_GOODS_KEYWORD = gql`
  query ordersByGoodsKeyword($orderSNs: [String]!, $keyword: String!) {
    ordersByGoodsKeyword(order_sns: $orderSNs, keyword: $keyword) {
      order_sns
    }
  }
`;
