import React, { useState } from 'react';
import { Order } from '../../../../../types';
import {
  Badge,
  Card,
  Popconfirm,
  Space,
  Tooltip,
  Typography,
  Image,
} from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  CloseCircleFilled,
  UserOutlined,
  PhoneOutlined,
  MailOutlined,
  ContainerOutlined,
  BookOutlined,
  HomeOutlined,
  ClockCircleOutlined,
  AppstoreOutlined,
  CarOutlined,
  ShopOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import EditOrderForm from '../../../orders/EditOrderForm';
import { useMutation } from '@apollo/client';
import {
  graphqlErrorMessageAlert,
  orderStatusColor,
  showMessage,
} from '../../../../../utils/tools';
import { GET_ROUTE } from '../../../../../GraphQL/queries/route';
import { DELETE_ORDER } from '../../../../../GraphQL/queries/order';
import OrderStatus from '../../../orders/OrderStatus';
import OrderType from '../../../orders/OrderType';
import { BuildingType } from '../../../../../enumType';
import OrderLogModal from '../../../orders/OrderLogModal';
import OrderProductModal from '../../../orders/OrderProductModal';

const { Title, Text } = Typography;

type OrderDetailCardProps = {
  route_id: string;
  selectedOrder: Order | undefined;
  setSelectedOrder: Function;
  setLoadingMap: Function;
};

const OrderDetailCard = ({
  route_id,
  selectedOrder,
  setSelectedOrder,
  setLoadingMap,
}: OrderDetailCardProps) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  //Log Modal State
  const [showLogModal, setShowLogModal] = useState(false);

  //Product Modal State
  const [showProductModal, setShowProductModal] = useState(false);
  const [orderBasicInfo, setOrderBasicInfo] = useState({});

  const [deleteOrder] = useMutation(DELETE_ORDER, {
    onCompleted: () => handleDeleteCompleted(),
    onError: graphqlErrorMessageAlert,
    awaitRefetchQueries: true,
    refetchQueries: [{ query: GET_ROUTE, variables: { id: route_id } }],
  });

  const handleDeleteCompleted = () => {
    showMessage('success', 'Delete successfully');
    setSelectedOrder();
  };

  const handleDeleteConfirm = async (id: string) => {
    setLoadingMap(true);
    await deleteOrder({
      variables: { id },
    });
    setLoadingMap(false);
  };

  const handleViewProducts = () => {
    setOrderBasicInfo({
      receiver: selectedOrder?.receiver,
      phone: selectedOrder?.phone,
      latitude: selectedOrder?.latitude,
      longitude: selectedOrder?.longitude,
      buzz: selectedOrder?.buzz,
      unit: selectedOrder?.unit,
      address: selectedOrder?.address,
      store: selectedOrder?.store?.store_name,
    });
    setShowProductModal(true);
  };

  return selectedOrder ? (
    <>
      <Card
        style={{
          width: 300,
          margin: 15,
          borderRadius: 10,
          overflow: 'hidden',
          position: 'relative',
          zIndex: 1000,
        }}
        actions={[
          <Popconfirm
            title={t('order.actions.deleteOrderPromptMsg')}
            onConfirm={() => handleDeleteConfirm(selectedOrder.id)}
            onCancel={() => {}}
            okText={t('common.yes')}
            cancelText={t('common.no')}
          >
            <DeleteOutlined />
          </Popconfirm>,
          <Tooltip title={t('order.log.title')}>
            <ClockCircleOutlined onClick={() => setShowLogModal(true)} />
          </Tooltip>,
          <Tooltip title={t('order.productModal.title')}>
            <AppstoreOutlined onClick={handleViewProducts} />
          </Tooltip>,
          <EditOutlined onClick={() => setShowModal(true)} />,
        ]}
      >
        <Card.Meta
          avatar={
            <Badge
              count={selectedOrder?.sequence_label}
              style={{
                backgroundColor: orderStatusColor(selectedOrder.status),
              }}
              showZero
            />
          }
          description={
            <Space direction={'vertical'}>
              <Title level={5}>{selectedOrder?.address}</Title>
              <OrderType type={selectedOrder.type} />
              <OrderStatus status={selectedOrder.status} />
              <Space>
                <Tooltip
                  title={t('route.routeDetail.routeOrderTable.orderNumber')}
                >
                  <ContainerOutlined />
                </Tooltip>
                <Text>{selectedOrder?.order_sn || ' N/A'}</Text>
              </Space>
              <Space>
                <ShopOutlined />
                <Text>{selectedOrder?.store?.store_name || ' N/A'}</Text>
              </Space>
              <Space>
                <UserOutlined />
                <Text>{selectedOrder?.receiver || ' N/A'}</Text>
              </Space>
              <Space>
                <PhoneOutlined />
                <Text>{selectedOrder?.phone || 'N/A'}</Text>
              </Space>
              <Space>
                <MailOutlined />
                <Text>{selectedOrder?.email || 'N/A'}</Text>
              </Space>
              <AddressInfo
                buildingType={selectedOrder?.building_type}
                buzz={selectedOrder?.buzz}
                unit={selectedOrder?.unit}
              />
              {selectedOrder?.remark && (
                <Space>
                  <Tooltip title={t('driver.table.remark')}>
                    <BookOutlined />
                  </Tooltip>
                  <Text>{selectedOrder?.remark || 'N/A'}</Text>
                </Space>
              )}
              {selectedOrder?.driver_remark && (
                <Space>
                  <Tooltip title={t('order.driverRemark')}>
                    <CarOutlined />
                  </Tooltip>
                  <Text>{selectedOrder?.driver_remark || 'N/A'}</Text>
                </Space>
              )}
              {selectedOrder.order_images && (
                <Image.PreviewGroup>
                  <Space wrap>
                    {selectedOrder.order_images.map((img) => (
                      <Image
                        width={50}
                        key={img.id}
                        src={img.img_url}
                        preview={{ maskClassName: 'image-preview-mask' }}
                      />
                    ))}
                  </Space>
                </Image.PreviewGroup>
              )}
            </Space>
          }
        />
        <CloseCircleFilled
          style={{
            color: 'rgba(0, 0, 0, 0.20)',
            position: 'absolute',
            top: 7,
            right: 7,
            fontSize: 18,
          }}
          onClick={() => setSelectedOrder()}
        />
      </Card>
      <EditOrderForm
        showModal={showModal}
        setShowModal={setShowModal}
        editingOrder={selectedOrder}
        setSelectedOrder={setSelectedOrder}
        route_id={route_id}
        setLoadingMap={setLoadingMap}
      />
      {showLogModal && (
        <OrderLogModal
          showLogModal={showLogModal}
          setShowLogModal={setShowLogModal}
          chosenOrderId={selectedOrder.id}
        />
      )}
      {selectedOrder.id && (
        <OrderProductModal
          showProductModal={showProductModal}
          setShowProductModal={setShowProductModal}
          chosenOrderId={selectedOrder.id}
          orderBasicInfo={orderBasicInfo}
        />
      )}
    </>
  ) : (
    <></>
  );
};

type AddressInfoProps = {
  buildingType: number;
  buzz: string | null;
  unit: string | null;
};

const AddressInfo = ({
  buildingType = 0,
  buzz = '',
  unit = '',
}: AddressInfoProps) => {
  const { t } = useTranslation();

  let typeName = t('route.routeDetail.routeOrderTable.buildingType.house');

  switch (buildingType) {
    default:
    case BuildingType.House:
      break;
    case BuildingType.Townhouse:
      typeName = t('route.routeDetail.routeOrderTable.buildingType.townhouse');
      break;
    case BuildingType.Apartment:
      typeName = t('route.routeDetail.routeOrderTable.buildingType.apartment');
      break;
    case BuildingType.Basement:
      typeName = t('route.routeDetail.routeOrderTable.buildingType.basement');
      break;
    case BuildingType.Store:
      typeName = t('route.routeDetail.routeOrderTable.buildingType.store');
      break;
    case BuildingType.Other:
      typeName = t('route.routeDetail.routeOrderTable.buildingType.other');
      break;
  }

  return (
    <Space>
      <Tooltip
        title={t('route.routeDetail.routeOrderTable.buildingType.title')}
      >
        <HomeOutlined />
      </Tooltip>
      <div style={{ flexDirection: 'column', display: 'flex' }}>
        <Text>{typeName}</Text>
        {unit !== null && unit !== '' && <Text>Unit: {unit}</Text>}
        {buzz !== null && buzz !== '' && <Text>Buzz: {buzz}</Text>}
      </div>
    </Space>
  );
};

export default OrderDetailCard;
