import { Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import RouteList from '../../../components/dashboard/routes/RouteList';
import ViewBasicLayout from '../../../layout/ViewBasicLayout';
import { RouteActionTypes } from '../../../store/routeReducer';
import { RootState, RouteState } from '../../../types';
import RouteDetail from './RouteDetail';

const { TabPane } = Tabs;

const initialPanes: Array<{
  title: string;
  content: React.ReactNode;
  key: string;
  closable: boolean;
}> = [];
const Route = () => {
  const dispatch = useDispatch();
  const location = useLocation<any>();
  const { t } = useTranslation();
  const [panes, setPanes] = useState(initialPanes);

  const routePanes = useSelector<RootState, RouteState['routePanes']>(
    (state) => state.route.routePanes
  );

  const activePaneKey = useSelector<RootState, string>(
    (state) => state.route.activePaneKey
  );

  useEffect(() => {
    if (location.state && location.state.routeId && location.state.title) {
      //Find the route tab if exist based on key
      const targetPane = routePanes.find((pane) => {
        return pane.key === `RouteView@${location.state.routeId}`;
      });
      //Exist tab, activate the tab
      if (targetPane) {
        dispatch({
          type: RouteActionTypes.UPDATE_ACTIVE_PANE_KEY,
          payload: targetPane.key,
        });
      } else {
        addTab(location.state.title, location.state.routeId);
      }

      //Empty location state after activate the correct tab
      location.state = {};
    }
    /* eslint-disable */
  }, [location]);

  useEffect(() => {
    setPanes(routePanes);
  }, [routePanes]);

  //Switch to active pane
  const onTabClick = (activeKey: string) => {
    dispatch({
      type: RouteActionTypes.UPDATE_ACTIVE_PANE_KEY,
      payload: activeKey,
    });
  };

  const onEdit = (targetKey: any, action: 'add' | 'remove') => {
    if (action === 'remove') {
      let newActiveKey = activePaneKey;
      let lastIndex = -1;
      panes.forEach((pane, i) => {
        if (pane.key === targetKey) {
          lastIndex = i - 1;
        }
      });

      const newPanes = panes.filter((pane) => pane.key !== targetKey);
      if (newPanes.length && newActiveKey === targetKey) {
        if (lastIndex >= 0) {
          newActiveKey = newPanes[lastIndex].key;
        } else {
          newActiveKey = newPanes[0].key;
        }
      } else {
        newActiveKey = 'RouteListView';
      }

      dispatch({
        type: RouteActionTypes.UPDATE_ACTIVE_PANE_KEY,
        payload: newActiveKey,
      });
      dispatch({
        type: RouteActionTypes.UPDATE_ROUTE_PANES,
        payload: newPanes,
      });
    }
  };

  //Create new tab & push it into existing panes
  const addTab = (title: string, routeId: string) => {
    const oldPanes = [...panes];
    const newActiveKey = `RouteView@${routeId}`;

    if (oldPanes.findIndex((pane) => pane.key === newActiveKey) === -1) {
      oldPanes.push({
        title: title,
        content: <RouteDetail routeId={routeId} />,
        key: newActiveKey,
        closable: true,
      });
      dispatch({
        type: RouteActionTypes.UPDATE_ROUTE_PANES,
        payload: oldPanes,
      });
    }
    dispatch({
      type: RouteActionTypes.UPDATE_ACTIVE_PANE_KEY,
      payload: newActiveKey,
    });
  };

  return (
    <ViewBasicLayout>
      <Tabs
        activeKey={activePaneKey}
        type="editable-card"
        hideAdd
        onTabClick={onTabClick}
        onEdit={onEdit}
        defaultActiveKey="RouteListView"
      >
        <TabPane tab={t('route.title')} key="RouteListView" closable={false}>
          <RouteList addTab={addTab} />
        </TabPane>
        {panes.map((pane) => (
          <TabPane tab={pane.title} key={pane.key} closable={pane.closable}>
            {pane.content}
          </TabPane>
        ))}
      </Tabs>
    </ViewBasicLayout>
  );
};

export default Route;
