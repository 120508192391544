import { Button, Divider, message, Table, Upload } from 'antd';
import { InboxOutlined, PlusOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import Modal from 'antd/lib/modal/Modal';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { graphqlErrorMessageAlert, showMessage } from '../../../utils/tools';
import { ADD_ORDERS } from '../../../GraphQL/queries/order';
import OrderBuildingType from './OrderBuildingType';

type AddOrderWithCSVProps = {
  route_id: string;
  setLoadingMap: Function;
  refetch: Function;
};

const { Column } = Table;

const AddOrderWithCSV = ({
  route_id,
  setLoadingMap,
  refetch,
}: AddOrderWithCSVProps) => {
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);
  const [orders, setOrders] = useState([]);

  const [addOrders, { loading }] = useMutation(ADD_ORDERS, {
    onCompleted: () => handleAddCompleted(),
    onError: graphqlErrorMessageAlert,
  });

  const handleAddOrders = async () => {
    setLoadingMap(true);
    await addOrders({
      variables: {
        route_id: route_id,
        orders: orders,
      },
    });
    setLoadingMap(false);
  };

  const handleAddCompleted = () => {
    handleCancel();
    showMessage('success', 'Successfully add orders.');
    refetch();
  };

  const handleCancel = () => {
    setShowModal(false);
    setOrders([]);
  };

  return (
    <>
      <Button icon={<PlusOutlined />} onClick={() => setShowModal(true)}>
        {t('route.routeDetail.routeMapSection.importFromCSV')}
      </Button>
      <Modal
        title={t('route.routeDetail.routeMapSection.importFromCSV')}
        visible={showModal}
        onCancel={handleCancel}
        maskClosable={false}
        width={'80vw'}
        footer={
          <>
            <Button disabled={loading} onClick={handleCancel}>
              {t('common.cancel')}
            </Button>
            <Button disabled={loading} type="primary" onClick={handleAddOrders}>
              {t('route.routeDetail.routeMapSection.addOrders')}
            </Button>
          </>
        }
      >
        <Upload.Dragger
          multiple={false}
          accept=".csv"
          action={
            process.env.REACT_APP_BACKEND_URL
              ? process.env.REACT_APP_BACKEND_URL + '/utils/parse_csv'
              : 'http://localhost:3388/api/v1/utils/parse_csv'
          }
          method={'POST'}
          name={'data'}
          showUploadList={false}
          onChange={(info) => {
            if (info.file.status === 'done') {
              message.success(`${info.file.name} file uploaded successfully`);
              //Add decoded orders to state
              setOrders(info.file.response);
            } else if (info.file.status === 'error') {
              message.error(`${info.file.name} file upload failed.`);
            }
          }}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            {t('route.routeDetail.routeMapSection.dragToUpload')}
          </p>
        </Upload.Dragger>
        <Divider />
        <Table
          dataSource={orders}
          rowKey={(record: any) => record['order_sn']}
          sticky
        >
          <Column
            title={t('route.routeDetail.routeOrderTable.orderId')}
            dataIndex={'order_sn'}
          />
          <Column
            title={t('route.routeDetail.routeOrderTable.receiver')}
            dataIndex={'receiver'}
          />
          <Column
            title={t('route.routeDetail.routeOrderTable.address')}
            dataIndex={['address', 'latitude', 'longitude']}
            render={(text, record: any, index) => (
              <Button
                type="link"
                target="blank"
                href={`https://www.google.com/maps/search/?api=1&query=${record.latitude},${record.longitude}`}
              >
                {record.address}
              </Button>
            )}
          />
          <Column
            title={t('route.routeDetail.routeOrderTable.phone')}
            dataIndex={'phone'}
          />
          <Column
            title={t('route.routeDetail.routeOrderTable.email')}
            dataIndex={'email'}
          />
          <Column
            title={t('route.routeDetail.routeOrderTable.remark')}
            dataIndex={'remark'}
          />
          <Column
            title={t('route.routeDetail.routeOrderTable.buildingType.title')}
            dataIndex={'building_type'}
            render={(buildingType) => <OrderBuildingType type={buildingType} />}
            width={120}
          />
          <Column title="Unit" dataIndex={'unit'} width={80} />
          <Column title="Buzz" dataIndex={'buzz'} width={80} />
        </Table>
      </Modal>
    </>
  );
};

export default AddOrderWithCSV;
