import { useMutation } from '@apollo/client';
import { Button, Popconfirm, Space, Table, Tag, Input, Typography } from 'antd';
import Column from 'antd/lib/table/Column';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DELETE_DRIVER, DRIVER_LIST } from '../../../GraphQL/queries/driver';
import { graphqlErrorMessageAlert, showMessage } from '../../../utils/tools';
import EditDriverForm from './EditDriverForm';
import { SearchOutlined } from '@ant-design/icons';
import AddNewDriver from './AddNewDriver';
import DriverStatus from './DriverStatus';
import { Driver } from '../../../types';
import DriverBin from './DriverBin';
import { QuestionCircleOutlined } from '@ant-design/icons';

const { Paragraph } = Typography;

type DriverListProps = {
  drivers: Array<Driver>;
};

const DriverList = ({ drivers }: DriverListProps) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [editingDriver, setEditingDriver] = useState({
    driver_id: '',
    driver_name: '',
    phone: '',
    email: '',
    active_code: '',
    status: '',
    remark: '',
  });

  const [searchText, setSearchText] = useState('');

  const handleTriggerEditForm = (currentRowInfo: any) => {
    const {
      driver_id,
      driver_name,
      phone,
      email,
      active_code,
      status,
      remark,
    } = currentRowInfo;
    setEditingDriver({
      driver_id,
      driver_name,
      phone,
      email,
      active_code,
      status,
      remark,
    });
    setShowModal(true);
  };

  const [deleteDriver] = useMutation(DELETE_DRIVER, {
    onCompleted: () => handleDeleteCompleted(),
    onError: graphqlErrorMessageAlert,
  });

  const handleDeleteCompleted = () => {
    showMessage('success', t('driver.deleteSuccess'));
  };

  const handleDeleteConfirm = (driver_id: string) => {
    deleteDriver({
      variables: { driver_id },
      update: (proxy, deleteDriver) => {
        const data: any = proxy.readQuery({
          query: DRIVER_LIST,
          variables: { isDeleted: false },
        });
        proxy.writeQuery({
          query: DRIVER_LIST,
          variables: { isDeleted: false },
          data: {
            ...data,
            drivers: data.drivers.filter(
              (driver: Driver) => driver.driver_id !== driver_id
            ),
          },
        });
        const isDeletedData: any = proxy.readQuery({
          query: DRIVER_LIST,
          variables: { isDeleted: true },
        });
        isDeletedData &&
          proxy.writeQuery({
            query: DRIVER_LIST,
            variables: { isDeleted: true },
            data: {
              ...isDeletedData,
              drivers: [...isDeletedData.drivers, deleteDriver],
            },
          });
      },
    });
  };

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const resetList = () => {
    setSearchText('');
  };

  return (
    <>
      <div
        style={{
          marginBottom: '.5rem',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Space>
          <AddNewDriver />
          <DriverBin />
        </Space>
        <Space>
          <Input
            onChange={onSearch}
            prefix={<SearchOutlined />}
            value={searchText}
            placeholder={t('driver.searchPlaceholder')}
          />
          <Button onClick={resetList}>{t('common.reset')}</Button>
        </Space>
      </div>

      <Table
        dataSource={
          drivers
            ? drivers.filter(
                (driver) =>
                  driver.driver_name
                    .toLowerCase()
                    .includes(searchText.toLowerCase()) ||
                  driver.phone.includes(searchText) ||
                  driver.active_code
                    .toLowerCase()
                    .includes(searchText.toLowerCase())
              )
            : []
        }
        rowKey={(record) => record.driver_id}
        scroll={{ x: 1300 }}
        pagination={{ defaultPageSize: 10 }}
        sticky
      >
        <Column
          title={t('driver.table.id')}
          dataIndex={'driver_id'}
          width={60}
          fixed="left"
        />
        <Column
          title={t('driver.table.name')}
          dataIndex={'driver_name'}
          width={140}
          fixed="left"
          sorter={(a: any, b: any) => {
            var nameA = a.driver_name.toUpperCase(); // ignore upper and lowercase
            var nameB = b.driver_name.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            //names must be equal
            return 0;
          }}
          defaultSortOrder="ascend"
        />
        <Column
          title={t('driver.table.phone')}
          dataIndex={'phone'}
          width={120}
        />
        <Column title={t('driver.table.email')} dataIndex={'email'} />
        <Column
          title={t('driver.table.activeCode')}
          dataIndex={'active_code'}
          render={(active_code) => <Tag>{active_code}</Tag>}
          width={100}
        />
        <Column
          title={t('driver.table.status')}
          dataIndex={'status'}
          render={(status) => <DriverStatus status={status} />}
          width={120}
        />
        <Column
          title={t('driver.table.remark')}
          dataIndex={'remark'}
          render={(remark) => {
            return remark ? <Paragraph>{remark}</Paragraph> : <>{'N/A'}</>;
          }}
        />
        <Column
          title={t('driver.table.notificationToken')}
          dataIndex={'expo_token'}
        />
        <Column
          title={t('driver.table.lastUpdate')}
          dataIndex="update_at"
          render={(date) => {
            return (
              <>
                {dayjs(date).valueOf() > 0
                  ? dayjs(date).format('YYYY/MM/DD HH:mm')
                  : 'N/A'}
              </>
            );
          }}
          width={120}
        />
        <Column
          title={t('driver.table.createdAt')}
          dataIndex={'created_at'}
          render={(date) => {
            return <>{dayjs(date).format('YYYY/MM/DD HH:mm')}</>;
          }}
          width={120}
        />
        <Column
          title={t('driver.table.actions.actions')}
          fixed="right"
          width={140}
          render={(record: any, index: number) => {
            return (
              <Space key={index}>
                <>
                  <Button
                    onClick={() => handleTriggerEditForm(record)}
                    size="small"
                  >
                    {t('userList.table.actions.edit')}
                  </Button>
                  <Popconfirm
                    title={t('userList.table.deletePromptMessage')}
                    icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                    onConfirm={() => handleDeleteConfirm(record.driver_id)}
                    onCancel={() => {}}
                    okText={t('common.yes')}
                    cancelText={t('common.no')}
                  >
                    <Button danger size="small">
                      {t('userList.table.actions.delete')}
                    </Button>
                  </Popconfirm>
                </>
              </Space>
            );
          }}
        />
      </Table>
      <EditDriverForm
        showModal={showModal}
        setShowModal={setShowModal}
        editingDriver={editingDriver}
      />
    </>
  );
};

export default DriverList;
