import React from 'react';
import { Menu, Dropdown, Space, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { CALCULATE_ROUTE } from '../../../../../GraphQL/queries/route';
import { graphqlErrorMessageAlert } from '../../../../../utils/tools';
import { OptimizeProvide } from '../../../../../enumType';

type CalculateRouteBtnProps = {
  route_id: string;
  setLoadingMap: Function;
  refetch: Function;
};

const CalculateRouteBtn = ({
  route_id,
  setLoadingMap,
  refetch,
}: CalculateRouteBtnProps) => {
  const { t } = useTranslation();

  const handleMenuClick = async (element: any) => {
    const type = element.key !== undefined ? parseInt(element.key) : 0;
    await handleConfirmPress(type);
  };

  const [calculateRoute] = useMutation(CALCULATE_ROUTE, {
    onCompleted: () => refetch(),
    onError: graphqlErrorMessageAlert,
  });

  const handleConfirmPress = async (type: number) => {
    setLoadingMap(true);
    await calculateRoute({ variables: { id: route_id, type: type } });
    setLoadingMap(false);
  };

  const handlePress = (element: any) => {
    Modal.confirm({
      title: t('route.routeDetail.routeMapSection.calculateRouteWarning'),
      onOk() {
        handleMenuClick(element);
      },
      okText: t('common.yes'),
      cancelText: t('common.no'),
    });
  };

  const menu = (
    <Menu onClick={handlePress}>
      <Menu.Item key={OptimizeProvide.ORS}>
        {t('route.routeDetail.routeMapSection.calculateRouteProvider.ors')}
      </Menu.Item>
      <Menu.Item key={OptimizeProvide.GEO}>
        {t('route.routeDetail.routeMapSection.calculateRouteProvider.geo')}
      </Menu.Item>
    </Menu>
  );

  return (
    <Space wrap>
      <Dropdown.Button onClick={handlePress} overlay={menu}>
        {t('route.routeDetail.routeMapSection.calculateRoute')}
      </Dropdown.Button>
    </Space>
  );
};

export default CalculateRouteBtn;
