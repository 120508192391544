import { Button } from 'antd';
import React from 'react';
import { ExportOutlined } from '@ant-design/icons';
import { CSVLink } from 'react-csv';
import { parse } from 'json2csv';
import { useTranslation } from 'react-i18next';

type ExportCSVProps = {
  data: any[];
  filename: string;
};

/**
 * Export CSV with provided data
 */
const ExportCSV = ({ data = [], filename }: ExportCSVProps) => {
  const { t } = useTranslation();

  if (data.length > 0)
    return (
      <CSVLink data={parse(data)} target="blank" filename={filename}>
        <Button icon={<ExportOutlined />}>
          {t('route.routeDetail.routeMapSection.exportCSV')}
        </Button>
      </CSVLink>
    );
  else return <></>;
};

export default ExportCSV;
