import { Modal, Form, Button, Input, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Loading from '../../common/Loading';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  GET_DEFAULT_ADDRESSES,
  UPDATE_DEFAULT_ADDRESS,
} from '../../../GraphQL/queries/address';
import { graphqlErrorMessageAlert, showMessage } from '../../../utils/tools';
import { GET_ADDRESSES } from '../../../GraphQL/queries/address';
import { Address, DefaultAddress } from '../../../types';

type EditDefaultAddressProps = {
  routeInfo: DefaultAddress;
};

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 14,
  },
};

const EditDefaultAddress = ({ routeInfo }: EditDefaultAddressProps) => {
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);

  const [form] = Form.useForm();

  //Address Selector
  const [loadAddress, { data: addressList }] = useLazyQuery(GET_ADDRESSES, {
    variables: { isDeleted: false },
  });

  const [updateDefaultAddress, { loading }] = useMutation(
    UPDATE_DEFAULT_ADDRESS,
    {
      onCompleted: () => handleEditCompleted(),
      onError: graphqlErrorMessageAlert,
    }
  );

  // Get address list only when modal is open
  useEffect(() => {
    if (showModal) {
      loadAddress();
    }
  }, [showModal, loadAddress]);

  const handleCancel = () => {
    form.resetFields();
    setShowModal(false);
  };

  const handleEditCompleted = () => {
    handleCancel();
    showMessage('success', 'Successfully update route.');
  };

  const onFinish = (values: any) => {
    console.log('Received values of form: ', values);
    updateDefaultAddress({
      variables: {
        id: routeInfo.id,
        input: { ...values, sort_order: parseInt(values.sort_order) },
      },
      refetchQueries: [{ query: GET_DEFAULT_ADDRESSES }],
    });
  };

  const handleEditRoute = () => {
    console.log(form.getFieldsValue(true));
    form.submit();
  };

  return (
    <>
      <Button size="small" onClick={() => setShowModal(true)}>
        {t('common.edit')}
      </Button>
      <Modal
        title={t('route.createRoute')}
        visible={showModal}
        onCancel={handleCancel}
        maskClosable={false}
        footer={
          <>
            <Form.Item>
              <Button disabled={loading} onClick={handleCancel}>
                {t('common.cancel')}
              </Button>
              <Button
                disabled={loading}
                type="primary"
                onClick={handleEditRoute}
              >
                {t('common.confirm')}
              </Button>
            </Form.Item>
          </>
        }
      >
        {loading ? (
          <Loading />
        ) : (
          <>
            <Form
              form={form}
              name="edit-default-address"
              onFinish={onFinish}
              scrollToFirstError
              {...formItemLayout}
              initialValues={{
                ...routeInfo,
                sort_order: routeInfo.sort_order.toString(),
              }}
            >
              <Form.Item
                name="route_name"
                label={t('route.table.name')}
                rules={[
                  {
                    required: true,
                    message: 'Please input route name',
                  },
                  { min: 3 },
                ]}
              >
                <Input placeholder="Input route name by typing" />
              </Form.Item>
              <Form.Item
                name="short_name"
                label={t('route.table.shortName')}
                rules={[
                  {
                    required: true,
                    message: 'Please input route short name',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="start_point_id"
                label={t('route.table.startPoint')}
                rules={[{ required: true }]}
              >
                <Select
                  showSearch
                  placeholder="Select an address"
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    option.children
                      .toLocaleLowerCase()
                      .includes(input.toLocaleLowerCase())
                  }
                  onFocus={() => loadAddress()}
                >
                  {addressList &&
                    addressList.addresses.map((item: Address) => {
                      return (
                        <Option key={item.id} value={item.id}>
                          {item.label
                            ? `${item.label} - ${item.name}`
                            : item.name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
              <Form.Item
                name="end_point_id"
                label={t('route.table.endPoint')}
                rules={[{ required: true }]}
              >
                <Select
                  showSearch
                  placeholder="Select an address"
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    option.children
                      .toLocaleLowerCase()
                      .includes(input.toLocaleLowerCase())
                  }
                  onFocus={() => loadAddress()}
                >
                  {addressList &&
                    addressList.addresses.map((item: Address) => {
                      return (
                        <Option key={item.id} value={item.id}>
                          {item.label
                            ? `${item.label} - ${item.name}`
                            : item.name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
              <Form.Item
                name="sort_order"
                label={t('route.table.sortOrder')}
                rules={[{ min: 0, max: 50000 }]}
              >
                <Input placeholder="Input sort order" />
              </Form.Item>
            </Form>
          </>
        )}
      </Modal>
    </>
  );
};

export default EditDefaultAddress;
