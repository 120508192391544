import { Button, DatePicker, Form, Input, Popover, Select, Space } from 'antd';
import { FilterOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useForm } from 'antd/lib/form/Form';
import moment from 'moment';
import { useLazyQuery } from '@apollo/client';
import { SIMPLE_DRIVER_LIST } from '../../../GraphQL/queries/statistics';
import { graphqlErrorMessageAlert } from '../../../utils/tools';

const { RangePicker } = DatePicker;

type SearchFilterProps = {
  refetch: Function;
  pageSize: number;
};

const SearchFilter = ({ refetch, pageSize }: SearchFilterProps) => {
  const { t } = useTranslation();
  const [form] = useForm();

  //Driver list get from backend
  const [loadDriverList, { data: driverData }] = useLazyQuery(
    SIMPLE_DRIVER_LIST,
    {
      onError: (err) => graphqlErrorMessageAlert(err),
      fetchPolicy: 'cache-and-network',
    }
  );

  const onFinish = (values: any) => {
    console.log('Received values of form: ', values);
    const filterConditions: { [key: string]: any } = {
      phone: values.phone,
      receiver: values.receiver,
    };

    if (typeof values.status === 'number')
      filterConditions.status = values.status;
    if (typeof values.type === 'number') filterConditions.type = values.type;

    if (
      values.shipping_date !== undefined &&
      Array.isArray(values.shipping_date)
    ) {
      filterConditions.shipping_date =
        values.shipping_date[0].format('YYYY-MM-DD');
      filterConditions.shipping_date_end =
        values.shipping_date[1].format('YYYY-MM-DD');
    }

    if (values.driver !== '') {
      filterConditions.driver_id = parseInt(values.driver);
    }

    refetch({
      page: 1,
      pageSize,
      filterConditions,
    });
  };

  const onReset = () => {
    form.resetFields();
    refetch({
      page: 1,
      pageSize,
      orderSN: '',
      filterConditions: {},
    });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Popover
      content={
        <>
          <Form
            name="order-filter-search"
            form={form}
            initialValues={{ receiver: '', phone: '' }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item name="shipping_date" label={t('route.table.date')}>
              <RangePicker
                ranges={{
                  [`${t('datePicker.period.week')}`]: [
                    moment().startOf('isoWeek'),
                    moment().endOf('isoWeek'),
                  ],
                  [`${t('datePicker.period.firstHalfMonth')}`]: [
                    moment().startOf('month'),
                    moment().startOf('month').add(14, 'd'),
                  ],
                  [`${t('datePicker.period.secondHalfMonth')}`]: [
                    moment().startOf('month').add(15, 'd'),
                    moment().endOf('month'),
                  ],
                  [`${t('datePicker.period.month')}`]: [
                    moment().startOf('month'),
                    moment().endOf('month'),
                  ],
                }}
              />
            </Form.Item>

            <Form.Item
              label={t('route.routeDetail.routeOrderTable.receiver')}
              name="receiver"
            >
              <Input allowClear />
            </Form.Item>

            <Form.Item
              label={t('route.routeDetail.routeOrderTable.phone')}
              name="phone"
            >
              <Input allowClear />
            </Form.Item>
            <Form.Item
              name="status"
              label={t('route.routeDetail.routeOrderTable.status')}
              initialValue={''}
            >
              <Select>
                <Select.Option value={0}>
                  {t('route.routeDetail.routeOrderTable.statusTypes.preparing')}
                </Select.Option>
                <Select.Option value={1}>
                  {t(
                    'route.routeDetail.routeOrderTable.statusTypes.outForDelivery'
                  )}
                </Select.Option>
                <Select.Option value={2}>
                  {t(
                    'route.routeDetail.routeOrderTable.statusTypes.arrivingSoon'
                  )}
                </Select.Option>
                <Select.Option value={3}>
                  {t('route.routeDetail.routeOrderTable.statusTypes.failed')}
                </Select.Option>
                <Select.Option value={4}>
                  {t('route.routeDetail.routeOrderTable.statusTypes.delivered')}
                </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="type"
              label={t('route.routeDetail.routeOrderTable.type')}
              initialValue={''}
            >
              <Select>
                <Select.Option value={0}>
                  {t('route.routeDetail.routeOrderTable.orderTypes.delivery')}
                </Select.Option>
                <Select.Option value={1}>
                  {t('route.routeDetail.routeOrderTable.orderTypes.pickup')}
                </Select.Option>
                <Select.Option value={2}>
                  {t('route.routeDetail.routeOrderTable.orderTypes.exchange')}
                </Select.Option>
                <Select.Option value={3}>
                  {t('route.routeDetail.routeOrderTable.orderTypes.replenish')}
                </Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              name="driver"
              label={t('route.routeDetail.routeOrderTable.driver')}
              initialValue={''}
            >
              <Select
                showSearch
                placeholder="Select a driver"
                optionFilterProp="children"
                filterOption={(input, option: any) =>
                  option.children
                    .toLocaleLowerCase()
                    .includes(input.toLocaleLowerCase())
                }
                onFocus={() => {
                  loadDriverList();
                }}
              >
                {driverData &&
                  driverData.drivers.length > 0 &&
                  driverData.drivers.map((driver: any) => {
                    return (
                      <Select.Option
                        value={driver.driver_id}
                        key={driver.driver_id}
                      >
                        {driver.driver_name}
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>

            <Form.Item>
              <Space>
                <Button type="primary" htmlType="submit">
                  {t('order.conditionSearch.submitBtn')}
                </Button>
                <Button htmlType="button" onClick={onReset}>
                  {t('common.reset')}
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </>
      }
      title={t('order.conditionSearch.title')}
      trigger="click"
      placement="bottomLeft"
      getPopupContainer={(triggerNode) => triggerNode.parentNode as HTMLElement}
    >
      <Button icon={<FilterOutlined />}>
        {t('order.conditionSearch.title')}
      </Button>
    </Popover>
  );
};

export default SearchFilter;
