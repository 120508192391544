import {
  BarChartOutlined,
  BookOutlined,
  CarOutlined,
  ContactsOutlined,
  HomeOutlined,
  NodeIndexOutlined,
  SettingOutlined,
  ShopOutlined,
  ShoppingOutlined,
  TeamOutlined,
  UserOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { DashboardRoute, RootState, UserState } from '../../types';
import AddressBook from '../../views/Dashboard/AddressBook/AddressBook';
import DefaultAddressBook from '../../views/Dashboard/AddressBook/DefaultAddressBook';
import Drivers from '../../views/Dashboard/Drivers';
import Home from '../../views/Dashboard/Home';
import Orders from '../../views/Dashboard/Orders';
import Profile from '../../views/Dashboard/Profile';
import Route from '../../views/Dashboard/Route/Route';
import Preference from '../../views/Dashboard/Settings/Preference';
import Stores from '../../views/Dashboard/Stores';
import Users from '../../views/Dashboard/Users';
import Vehicles from '../../views/Dashboard/Vehicles';
import OrderStatistics from '../../views/Dashboard/Statistics/OrderStatistics';
import FeeStatistics from '../../views/Dashboard/Statistics/FeeStatistics';

const DashboardRouter = ({
  children,
}: {
  children: (routes: any) => JSX.Element;
}) => {
  const { t } = useTranslation();
  const userInfo = useSelector<RootState, UserState['userInfo']>(
    (state) => state.user.userInfo
  );

  const routes: DashboardRoute[] = [
    {
      path: '/home',
      component: Home,
      title: t('dashboardRoutes.home'),
      icon: <HomeOutlined />,
      roles: [],
    },
    {
      path: '/routes',
      component: Route,
      title: t('dashboardRoutes.routes'),
      icon: <NodeIndexOutlined />,
      roles: [],
    },
    {
      path: '/orders',
      component: Orders,
      title: t('dashboardRoutes.orders'),
      icon: <ShoppingOutlined />,
      roles: [],
    },
    {
      path: '/drivers',
      component: Drivers,
      title: t('dashboardRoutes.drivers'),
      icon: <ContactsOutlined />,
      roles: [],
    },
    {
      path: '/vehicles',
      component: Vehicles,
      title: t('dashboardRoutes.vehicles'),
      icon: <CarOutlined />,
      roles: [],
    },
    {
      path: '/stores',
      component: Stores,
      title: t('dashboardRoutes.stores'),
      icon: <ShopOutlined />,
      roles: [],
    },
    {
      path: '/statistics',
      title: t('dashboardRoutes.statistics'),
      icon: <BarChartOutlined />,
      roles: [],
      subMenu: [
        {
          title: t('statistics.title'),
          path: '/order',
          component: OrderStatistics,
        },
        {
          title: t('feeStatistics.title'),
          path: '/fee',
          component: FeeStatistics,
        },
      ],
    },
    {
      path: '/address',
      title: t('addressBook.address'),
      icon: <BookOutlined />,
      roles: [],
      subMenu: [
        {
          title: t('dashboardRoutes.addressBook'),
          path: '/address-book',
          component: AddressBook,
        },
        {
          title: t('addressBook.defaultAddress.title'),
          path: '/default-address',
          component: DefaultAddressBook,
        },
      ],
    },
    {
      path: '/users',
      component: Users,
      title: t('dashboardRoutes.users'),
      icon: <TeamOutlined />,
      roles: [],
    },
    {
      path: '/profile',
      component: Profile,
      title: t('dashboardRoutes.profile'),
      icon: <UserOutlined />,
      roles: [],
    },
    {
      path: '/settings',
      title: t('dashboardRoutes.settings'),
      icon: <SettingOutlined />,
      roles: [],
      subMenu: [
        {
          title: t('settings.preference.title'),
          path: '/preference',
          component: Preference,
        },
      ],
    },
  ];

  const availableRoutes = routes.filter((route) => {
    if (route.roles.length === 0) return true;
    const authorizedRoutes = route.roles.find((role) => role === userInfo.role);
    if (authorizedRoutes) return true;
    return false;
  });

  return children(availableRoutes);
};

export default DashboardRouter;
