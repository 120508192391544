import moment from 'moment';
import { ReducerActionType, StatisticsState } from '../types';

const initState: StatisticsState = {
  shippingDate: {
    start: moment().startOf('isoWeek').format('YYYY-MM-DD'),
    end: moment().endOf('isoWeek').format('YYYY-MM-DD'),
  },
  selectedDrivers: [],
  driverReportData: [],
  storeId: undefined,
};

export enum StatisticsActionTypes {
  UPDATE_SHIPPING_DATE = 'UPDATE_SHIPPING_DATE',
  UPDATE_DRIVERS_ID_ARRAY = 'UPDATE_DRIVERS_ID_ARRAY',
  UPDATE_REPORT_DATA = 'UPDATE_REPORT_DATA',
  UPDATE_STORE_ID = 'UPDATE_STORE_ID',
}

const statisticsReducer = (state = initState, action: ReducerActionType) => {
  switch (action.type) {
    case StatisticsActionTypes.UPDATE_SHIPPING_DATE:
      return {
        ...state,
        shippingDate: action.payload,
      };

    case StatisticsActionTypes.UPDATE_DRIVERS_ID_ARRAY:
      return { ...state, selectedDrivers: action.payload };

    case StatisticsActionTypes.UPDATE_REPORT_DATA:
      return { ...state, driverReportData: action.payload };

    case StatisticsActionTypes.UPDATE_STORE_ID:
      return { ...state, storeId: action.payload };

    default:
      return state;
  }
};

export default statisticsReducer;
