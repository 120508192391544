import { Button, Divider, Space, Tabs, Typography } from 'antd';
import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_ROUTE } from '../../../GraphQL/queries/route';
import { graphqlErrorMessageAlert } from '../../../utils/tools';
import Loading from '../../../components/common/Loading';
import EmptyResult from '../../../components/common/EmptyResult';
import { useTranslation } from 'react-i18next';
import RouteMapSection from '../../../components/dashboard/routes/routeDetail/routeMapSection/RouteMapSection';
import { Order } from '../../../types';
import HeaderSection from '../../../components/dashboard/routes/routeDetail/HeaderSection';
import RouteOrderTable from '../../../components/dashboard/routes/routeDetail/routeMapSection/RouteOrderTable';
import AddOrderWithCSV from '../../../components/dashboard/orders/AddOrderWithCSV';
import AddNewOrder from '../../../components/dashboard/orders/AddNewOrder';
import CalculateRouteBtn from '../../../components/dashboard/routes/routeDetail/routeMapSection/CalculateRouteBtn';
import ExportCsvBtn from '../../../components/dashboard/routes/routeDetail/ExportCsvBtn';
import ReverseRouteBtn from '../../../components/dashboard/routes/routeDetail/routeMapSection/ReverseRouteBtn';
import SplitRouteBtn from '../../../components/dashboard/routes/routeDetail/routeMapSection/SplitRouteBtn';

const { Title } = Typography;
const { TabPane } = Tabs;

type RouteDetailProps = {
  routeId: string;
};

const RouteDetail = ({ routeId }: RouteDetailProps) => {
  const { t } = useTranslation();
  const [loadingMap, setLoadingMap] = useState(false);
  //Get route detail include orders belong to it and driver etc.
  const { data, loading, refetch } = useQuery(GET_ROUTE, {
    fetchPolicy: 'cache-and-network',
    onError: graphqlErrorMessageAlert,
    variables: {
      id: routeId,
    },
  });

  //State for routes comparison
  const [routeCompareData, setRouteCompareData] = useState<
    Array<{
      id: string;
      name: string;
      geoJson: object[];
      orders: Order[];
    }>
  >([]);

  if (data && data.route) {
    const { startAddress, endAddress, orders, id, geoJson } = data.route;
    const sortedOrders = [...orders].sort((orderA: Order, orderB: Order) => {
      return orderA.sequence_index - orderB.sequence_index;
    });
    let driverLocationInfo = null;
    if (data.route.driver) {
      driverLocationInfo = {
        latitude: data.route.driver.latitude,
        longitude: data.route.driver.longitude,
        location_update_at: data.route.driver.location_update_at,
      };
    }

    return (
      <div>
        <div className="topSection">
          {data && data.route && (
            <HeaderSection
              routeInfo={data.route}
              refetch={refetch}
              loading={loading}
            />
          )}
        </div>
        <Divider style={{ marginBottom: '.5rem' }} />
        <Space style={{ marginBottom: '.5rem' }}>
          <AddOrderWithCSV
            route_id={routeId}
            setLoadingMap={setLoadingMap}
            refetch={refetch}
          />
          <AddNewOrder
            route_id={routeId}
            setLoadingMap={setLoadingMap}
            refetch={refetch}
          />
          {orders.length > 0 && (
            <>
              <CalculateRouteBtn
                route_id={routeId}
                setLoadingMap={setLoadingMap}
                refetch={refetch}
              />
              <ExportCsvBtn
                orders={orders}
                filename={`${data.route.name}(${data.route.shipping_date}).csv`}
              />
              <ReverseRouteBtn route_id={routeId} refetch={refetch} />
              <SplitRouteBtn
                route_id={routeId}
                setLoadingMap={setLoadingMap}
                orders={orders}
                shippingDate={data.route.shipping_date}
                refetch={refetch}
              />
            </>
          )}
        </Space>
        <RouteMapSection
          route_id={id}
          orders={sortedOrders}
          loadingMap={loadingMap}
          setLoadingMap={setLoadingMap}
          startAddress={startAddress}
          endAddress={endAddress}
          geoJson={geoJson}
          driverLocationInfo={
            driverLocationInfo !== null ? driverLocationInfo : undefined
          }
          routeCompareData={routeCompareData}
          setRouteCompareData={setRouteCompareData}
          shippingDate={data.route.shipping_date}
          refetch={refetch}
        />
        <Divider />
        <Title level={5}>{t('route.routeDetail.routeOrderList')}</Title>
        <Tabs type="card">
          <TabPane tab={data.route.name} key="current-route">
            <RouteOrderTable
              orders={sortedOrders}
              route_id={id}
              setLoadingMap={setLoadingMap}
            />
          </TabPane>
          {routeCompareData.map((route, index) => {
            //Render other route's orders
            return (
              <TabPane tab={route.name} key={`route@${index}`}>
                <RouteOrderTable
                  orders={route.orders}
                  route_id={route.id}
                  setLoadingMap={setLoadingMap}
                />
              </TabPane>
            );
          })}
        </Tabs>
      </div>
    );
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <EmptyResult
      extra={
        <Button type="primary" onClick={() => refetch()}>
          {t('common.retry')}
        </Button>
      }
    />
  );
};

export default RouteDetail;
