import { useTranslation } from "react-i18next";
import { VehicleType as EnumVehicleType } from '../../../enumType';
import { Tag } from "antd";

type VehicleTypeProps = {
    type: number;
  };

const VehicleType = ({ type }: VehicleTypeProps) => {
    const { t } = useTranslation();
  switch (type) {
    case EnumVehicleType.CompanyVehicle:
      return (
        <Tag color={'blue'}>
          {t('vehicles.vehicleType.companyVehicle')}
        </Tag>
      );
    case EnumVehicleType.OwnVehicle:
      return (
        <Tag color={'volcano'}>
          {t('vehicles.vehicleType.ownVehicle')}
        </Tag>
      );
    default:
      return <div>{'N/A'}</div>;
  }
}

export default VehicleType