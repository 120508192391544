import { Button, DatePicker, Input, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

type RouteSearchFilterProps = {
  setSearchText: Function;
  searchText: string;
  shippingDate: string;
  setShippingDate: Function;
};

const RouteSearchFilter = ({
  searchText,
  setSearchText,
  shippingDate,
  setShippingDate,
}: RouteSearchFilterProps) => {
  const { t } = useTranslation();

  const resetList = () => {
    setSearchText('');
  };

  const changeDate = (_: any, dateString: string) => {
    setShippingDate(dateString);
  };

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  return (
    <Space
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: '.5rem',
      }}
    >
      <DatePicker
        onChange={changeDate}
        defaultValue={moment(shippingDate)}
        allowClear={false}
      />
      <Input
        onChange={onSearch}
        prefix={<SearchOutlined />}
        value={searchText}
        placeholder={t('route.searchPlaceholder')}
      />

      <Button onClick={resetList}>{t('common.reset')}</Button>
    </Space>
  );
};

export default RouteSearchFilter;
