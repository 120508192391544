import { Modal, Form, Button, Input, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PlusOutlined } from '@ant-design/icons';
import Loading from '../../common/Loading';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  ADD_DEFAULT_ADDRESS,
  GET_DEFAULT_ADDRESSES,
} from '../../../GraphQL/queries/address';
import { graphqlErrorMessageAlert, showMessage } from '../../../utils/tools';
import { GET_ADDRESSES } from '../../../GraphQL/queries/address';
import { Address } from '../../../types';
import { useMount } from 'ahooks';

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 14,
  },
};

const AddDefaultAddress = () => {
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);
  const [defaultAddressesId, setDefaultAddressesId] = useState({
    start: '-1',
    end: '-1',
  });

  const [form] = Form.useForm();

  //Address Selector
  const [loadAddress, { data: addressList }] = useLazyQuery(GET_ADDRESSES, {
    variables: { isDeleted: false },
  });

  const [addDefaultAddress, { loading }] = useMutation(ADD_DEFAULT_ADDRESS, {
    onCompleted: () => handleAddCompleted(),
    onError: graphqlErrorMessageAlert,
    refetchQueries: [{ query: GET_DEFAULT_ADDRESSES }],
  });

  // Get address list only when modal is open
  useEffect(() => {
    if (showModal) loadAddress();
  }, [showModal, loadAddress]);

  useMount(() => {
    //get local storage info
    const defaultAddressPointsString = localStorage.getItem(
      '@storage_defaultAddressPoints'
    );
    if (defaultAddressPointsString) {
      const defaultAddressPoints = JSON.parse(atob(defaultAddressPointsString));
      if (
        'startPoint' in defaultAddressPoints &&
        'endPoint' in defaultAddressPoints
      ) {
        setDefaultAddressesId({
          start: defaultAddressPoints.startPoint,
          end: defaultAddressPoints.endPoint,
        });
      }
    }
  });

  const handleCancel = () => {
    form.resetFields();
    setShowModal(false);
  };

  const handleAddCompleted = () => {
    handleCancel();
    showMessage('success', 'Successfully add route.');
  };

  const onFinish = (values: any) => {
    console.log('Received values of form: ', values);
    addDefaultAddress({
      variables: {
        ...values,
        sort_order: parseInt(values.sort_order),
      },
      refetchQueries: [{ query: GET_DEFAULT_ADDRESSES }],
    });
  };

  const handleAddRoute = () => {
    console.log(form.getFieldsValue(true));
    form.submit();
  };

  return (
    <>
      <Button icon={<PlusOutlined />} onClick={() => setShowModal(true)}>
        {t('route.createRoute')}
      </Button>
      <Modal
        title={t('route.createRoute')}
        visible={showModal}
        onCancel={handleCancel}
        maskClosable={false}
        footer={
          <>
            <Form.Item>
              <Button disabled={loading} onClick={handleCancel}>
                {t('common.cancel')}
              </Button>
              <Button
                disabled={loading}
                type="primary"
                onClick={handleAddRoute}
              >
                {t('userList.table.actions.add')}
              </Button>
            </Form.Item>
          </>
        }
      >
        {loading ? (
          <Loading />
        ) : (
          <>
            <Form
              form={form}
              name="create-default-address"
              onFinish={onFinish}
              scrollToFirstError
              {...formItemLayout}
            >
              <Form.Item
                name="route_name"
                label={t('route.table.name')}
                rules={[
                  {
                    required: true,
                    message: 'Please input route name',
                  },
                  { min: 3 },
                ]}
              >
                <Input placeholder="Input route name by typing" />
              </Form.Item>
              <Form.Item
                name="short_name"
                label={t('route.table.shortName')}
                rules={[
                  {
                    required: true,
                    message: 'Please input route short name',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="start_point_id"
                label={t('route.table.startPoint')}
                rules={[{ required: true }]}
                initialValue={
                  defaultAddressesId.start !== '-1'
                    ? defaultAddressesId.start
                    : null
                }
              >
                <Select
                  showSearch
                  placeholder="Select an address"
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    option.children
                      .toLocaleLowerCase()
                      .includes(input.toLocaleLowerCase())
                  }
                  onFocus={() => loadAddress()}
                >
                  {addressList &&
                    addressList.addresses.map((item: Address) => {
                      return (
                        <Option key={item.id} value={item.id}>
                          {item.label
                            ? `${item.label} - ${item.name}`
                            : item.name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
              <Form.Item
                name="end_point_id"
                label={t('route.table.endPoint')}
                rules={[{ required: true }]}
                initialValue={
                  defaultAddressesId.end !== '-1'
                    ? defaultAddressesId.end
                    : null
                }
              >
                <Select
                  showSearch
                  placeholder="Select an address"
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    option.children
                      .toLocaleLowerCase()
                      .includes(input.toLocaleLowerCase())
                  }
                  onFocus={() => loadAddress()}
                >
                  {addressList &&
                    addressList.addresses.map((item: Address) => {
                      return (
                        <Option key={item.id} value={item.id}>
                          {item.label
                            ? `${item.label} - ${item.name}`
                            : item.name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
              <Form.Item
                name="sort_order"
                label={t('route.table.sortOrder')}
                rules={[{ min: 0, max: 50000 }]}
                initialValue="100"
              >
                <Input placeholder="Input sort order" />
              </Form.Item>
            </Form>
          </>
        )}
      </Modal>
    </>
  );
};

export default AddDefaultAddress;
