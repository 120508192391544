import { gql } from '@apollo/client';

export const GET_ADDRESSES = gql`
  query GetAddresses($isDeleted: Boolean!) {
    addresses(is_delete: $isDeleted) {
      id
      name
      latitude
      longitude
      label
    }
  }
`;

export const ADD_ADDRESS = gql`
  mutation AddAddress(
    $name: String!
    $label: String
    $longitude: Float!
    $latitude: Float!
  ) {
    addAddress(
      name: $name
      label: $label
      longitude: $longitude
      latitude: $latitude
    ) {
      id
      name
      label
      latitude
      longitude
    }
  }
`;

export const UPDATE_ADDRESS = gql`
  mutation UpdateAddress($id: ID!, $input: updateAddressInput!) {
    updateAddress(id: $id, input: $input) {
      id
      name
      label
      latitude
      longitude
    }
  }
`;

export const RESTORE_ADDRESS = gql`
  mutation RestoreAddress($id: ID!) {
    restoreAddress(id: $id)
  }
`;

export const DELETE_ADDRESS = gql`
  mutation DeleteAddress($id: ID!) {
    deleteAddress(id: $id)
  }
`;

export const GET_DEFAULT_ADDRESSES = gql`
  query {
    defaultAddress {
      id
      route_name
      short_name
      start_point_id
      end_point_id
      sort_order
    }
  }
`;

export const ADD_DEFAULT_ADDRESS = gql`
  mutation AddDefaultAddress(
    $route_name: String!
    $short_name: String!
    $start_point_id: ID!
    $end_point_id: ID!
    $sort_order: Int
  ) {
    addDefaultAddress(
      route_name: $route_name
      short_name: $short_name
      start_point_id: $start_point_id
      end_point_id: $end_point_id
      sort_order: $sort_order
    ) {
      id
      route_name
      short_name
      start_point_id
      end_point_id
      sort_order
    }
  }
`;

export const UPDATE_DEFAULT_ADDRESS = gql`
  mutation UpdateDefaultAddress($id: ID!, $input: updateDefaultAddressInput!) {
    updateDefaultAddress(id: $id, input: $input) {
      id
      route_name
      short_name
      start_point_id
      end_point_id
      sort_order
    }
  }
`;

export const DELETE_DEFAULT_ADDRESS = gql`
  mutation DeleteDefaultAddress($id: ID!) {
    deleteDefaultAddress(id: $id) {
      id
      route_name
      short_name
      start_point_id
      end_point_id
      sort_order
    }
  }
`;
