import React from 'react';
import { List, Avatar, Typography } from 'antd';
import { OrderProductItemType } from '../../../types';

const { Text } = Typography;

type OrderProductListProps = {
  data: OrderProductItemType[];
};

const OrderProductList = ({ data }: OrderProductListProps) => {
  return (
    <List
      dataSource={data}
      bordered
      renderItem={(item) => (
        <List.Item key={item.goods_id + Math.random()}>
          <List.Item.Meta
            avatar={<Avatar src={item.goods_thumb} />}
            title={item.goods_name}
            description={item.goods_attr}
          />
          <Text>x {item.goods_number}</Text>
        </List.Item>
      )}
    />
  );
};

export default OrderProductList;
